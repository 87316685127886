import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { auth } from '../firebase';
import { loginThunk, setPasswordThunk } from '../store/ducks/auth/authThunks';
import { object, string } from '../utils/yup';
import TextField from '../components/v2/TextField';
import useTranslate from '../hooks/useTranslate';
import Typography from '../components/ui/Typography';
import Button from '../components/ui/Button';
import useQuery from '../hooks/useQuery';
import logo from '../assets/logo.png';

function Initialize() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const email = query.get('email');

  const isEmailLink = auth.isSignInWithEmailLink(window.location.href);

  const [isLoading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      password: '',
      confirmedPassword: '',
    },
    validationSchema: object().shape({
      password: string().required(),
      confirmedPassword: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(setPasswordThunk(email, values.password, values.confirmedPassword, window.location.href));
        await dispatch(loginThunk(email, values.password));

        history.push('/');
        toast.success(t('Initialize.success'));
      } catch (error) {
        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  if (!isEmailLink || !email) {
    return <Redirect to="/connexion" />;
  }

  return (
    <Box>
      <form onSubmit={form.handleSubmit}>
        <Box maxWidth="300px" mx="auto" mt="100px">
          <img alt={t('Common.name')} src={logo} width="100%" />
          <Typography variant="h1">
            <FormattedMessage id="Initialize.title" />
          </Typography>
          <Box mt="55px">
            <TextField
              label={t('Login.password.label')}
              placeholder={t('Login.password.placeholder')}
              type="password"
              value={form.values.password}
              onChange={form.handleChange}
              id="password"
              name="password"
              error={form.touched.password && form.errors.password}
              onBlur={form.handleBlur}
              fullWidth
            />
          </Box>
          <Box mt="55px" maxWidth="300px">
            <TextField
              label={t('Initialize.confirmedPassword.label')}
              placeholder={t('Initialize.confirmedPassword.placeholder')}
              type="password"
              value={form.values.confirmedPassword}
              onChange={form.handleChange}
              id="confirmedPassword"
              name="confirmedPassword"
              error={form.touched.confirmedPassword && form.errors.confirmedPassword}
              onBlur={form.handleBlur}
              fullWidth
            />
          </Box>
          <Box mt="55px">
            <Button variant="contained" type="submit" loading={isLoading}>
              <FormattedMessage id="Initialize.registerPassword" />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default Initialize;
