import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import TextField from '../v2/TextField';
import useTranslate from '../../hooks/useTranslate';
import { object, string, boolean } from '../../utils/yup';
import AppLayout from '../AppLayout';

const useStyles = makeStyles({
  image: {
    width: '100%',
    maxWidth: '500px',
    marginTop: '20px',
  },
});

function UserForm({ user, onSubmit }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslate();
  const history = useHistory();

  const isEdit = !!user?.id;

  const [isLoading, setLoading] = useState(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || '',
      isAdmin: user?.isAdmin || false,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      id: user?.id,
    },
    validationSchema: object().shape({
      email: string().email().required(),
      isAdmin: boolean(),
      firstName: string().required(),
      lastName: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(onSubmit(values));

        toast.success(t('UserForm.success', { isEdit: isEdit ? 'updated' : 'created' }));
        history.push('/utilisateurs');
      } catch (error) {
        if ('auth/email-already-exists' === error.message) {
          toast.error(t('Errors.api.emailAlreadyUsed'));
          return;
        }

        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <AppLayout>
      <Box textAlign="center" mt="80px">
        <form onSubmit={form.handleSubmit}>
          <Box maxWidth="282px" mx="auto">
            <Typography variant="h1">
              <FormattedMessage id="UserForm.title" values={{ isEdit: isEdit ? 'edit' : 'add' }} />
            </Typography>
            <Box mt="85px">
              <TextField
                label={t('UserForm.firstName.label')}
                placeholder={t('UserForm.firstName.placeholder')}
                value={form.values.firstName}
                onChange={form.handleChange}
                id="firstName"
                name="firstName"
                error={form.touched.firstName && form.errors.firstName}
                onBlur={form.handleBlur}
                errorClassName={classes.error}
                fullWidth
              />
            </Box>
            <Box mt="55px">
              <TextField
                label={t('UserForm.lastName.label')}
                placeholder={t('UserForm.lastName.placeholder')}
                value={form.values.lastName}
                onChange={form.handleChange}
                id="lastName"
                name="lastName"
                error={form.touched.lastName && form.errors.lastName}
                onBlur={form.handleBlur}
                fullWidth
              />
            </Box>
            <Box maxWidth="300px" mt="55px">
              <TextField
                label={t('UserForm.email.label')}
                placeholder={t('UserForm.email.placeholder')}
                value={form.values.email}
                onChange={form.handleChange}
                id="email"
                name="email"
                error={form.touched.email && form.errors.email}
                onBlur={form.handleBlur}
                type="email"
                fullWidth
              />
            </Box>
          </Box>
          <Box mt="55px">
            <FormControlLabel
              control={<Checkbox checked={form.values.isAdmin} name="isAdmin" onChange={(e) => form.handleChange(e)} color="primary" />}
              label={
                <Typography className={classes.label}>
                  <FormattedMessage id="CreateUser.isAdmin" />{' '}
                </Typography>
              }
            />
          </Box>
          <Box maxWidth="280px" mx="auto">
            <Box mt="45px" mb="100px">
              <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
                <FormattedMessage id="UserForm.edit" />
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </AppLayout>
  );
}

UserForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isAdmin: PropTypes.bool,
    id: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  user: {
    email: '',
    firstName: '',
    lastName: '',
    id: null,
  },
};

export default UserForm;
