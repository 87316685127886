import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function PlusIcon({ color, size }) {
  return (
    <SvgIcon size={size} fill={color} viewBox="0 0 24 24">
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </SvgIcon>
  );
}

PlusIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};
PlusIcon.defaultProps = {
  size: 'default',
  color: 'black',
};

export default PlusIcon;
