import { DIALOG_ACTIONS } from './dialogReducer';
import { clearDialogComponent, setDialogComponent } from './dialogRefStore';

export const openDialogThunk = (Component, props) => {
  return (dispatch) => {
    setDialogComponent(Component, props);

    dispatch({ type: DIALOG_ACTIONS.OPEN });
  };
};

export const closeDialogAction = () => ({
  type: DIALOG_ACTIONS.CLOSE,
});

export const clearDialogThunk = () => {
  return (dispatch) => {
    clearDialogComponent();

    dispatch({ type: DIALOG_ACTIONS.CLEAR });
  };
};
