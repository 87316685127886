import { useIntl } from 'react-intl';
import { useCallback } from 'react';

export default function useTranslate() {
  const { formatMessage, formatDate, formatTime } = useIntl();

  const t = useCallback((id, params) => formatMessage({ id }, params), [formatMessage]);

  return {
    t,
    formatDate,
    formatTime,
  };
}
