import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { getAdherentsThunk, updateAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { adherentByIdSelector } from '../../store/ducks/adherents/adherentsSelectors';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import AdherentForm from '../../components/AdherentForm/AdherentForm';
import CenteredCircularProgress from '../../components/ui/CenteredCircularProgress';
import useTranslate from '../../hooks/useTranslate';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function EditAdherent() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslate();

  const { id } = params;
  const user = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    try {
      await dispatch(getAdherentsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    }
  }, [dispatch]);

  const adherent = useSelector(adherentByIdSelector(id));

  if (adherent && adherent.id !== user.id && !user.isAdmin) {
    return <Redirect to="/" />;
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(updateAdherentsThunk({ id: params.id, data: values }));
      toast.success(t('Adherent.edited'));
      history.push(`/adherents/${adherent.id}`);
    } catch (error) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  return adherent ? <AdherentForm adherent={adherent} onSubmit={handleSubmit} isLoading={isLoading} /> : <CenteredCircularProgress />;
}

export default EditAdherent;
