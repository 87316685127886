import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import { deleteUserThunk, resetPasswordThunk } from '../../store/ducks/adherents/usersThunks';
import useTranslate from '../../hooks/useTranslate';
import DeleteDialog from '../DeleteDialog';
import useDialog from '../../hooks/useDialog';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    margin: '10px',
    borderRadius: '15px',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '10px',
    },
  },
}));

function UserCard({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { open, close } = useDialog();
  const loggedUser = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await dispatch(deleteUserThunk(user.id));

      toast.success(t('UserCard.dialog.deleted'));
      close();
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      await dispatch(resetPasswordThunk(user.email));

      toast.success(t('UserCard.resetPassword.success'));
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const openDelete = () => {
    open(DeleteDialog, { onDelete: handleDelete, isLoading, title: t('UserCard.dialog.title'), onClose: close });
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          <Box ml="5px">
            <Typography color="textSecondary">{loggedUser.id === user.id && <FormattedMessage id="UserCard.you" />}</Typography>
          </Box>
        </Box>
        <Typography color="textSecondary">{user.email}</Typography>
      </Box>
      <Box display="flex">
        <Box margin="5px">
          <Button to={`/utilisateurs/${user.id}/editer`} component={Link} variant="contained" color="green">
            <FormattedMessage id="UserCard.edit.button" />
          </Button>
        </Box>
        <Box margin="5px">
          <Button variant="contained" onClick={handleResetPassword} loading={isLoading}>
            <FormattedMessage id="UserCard.resetPassword.button" />
          </Button>
        </Box>
        {loggedUser.id !== user.id && (
          <Box margin="5px">
            <Button variant="contained" onClick={openDelete} color="orange">
              <FormattedMessage id="UserCard.delete.button" />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

UserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};
export default UserCard;
