/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import createRootReducer from './ducks';
import { auth } from '../firebase';
import { initializeAuthThunk } from './ducks/auth/authThunks';

function configureStore() {
  const middlewares = [applyMiddleware(ReduxThunk)];

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(createRootReducer, compose(...middlewares));

  auth.onAuthStateChanged((user) => {
    store.dispatch(initializeAuthThunk(user));
  });

  return store;
}

export default configureStore();
