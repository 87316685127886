import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { setHours, setMinutes } from 'date-fns';
import Typography from '../../components/ui/Typography';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import Button from '../../components/ui/Button';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  table: {
    minWidth: 650,
    tableLayout: 'fixed',
  },
  sticky: {
    backgroundColor: theme.palette.common.white,
    left: 0,
    position: 'sticky',
    zIndex: 200,
    boxShadow: '1px 0 3px 0 rgba(0,0,0,.1)',
    width: '150px',
  },
  stickyRight: {
    backgroundColor: theme.palette.common.white,
    right: 0,
    position: 'sticky',
    zIndex: 200,
    boxShadow: '-1px 0 3px 0 rgba(0,0,0,.1)',
    width: '150px',
  },
  entry: {
    width: '100px',
  },
}));

function GlobalAttendances({ adherents, meetings }) {
  const classes = useStyles();
  const ref = useRef();
  const { t } = useTranslate();

  const user = useSelector(userSelector);

  const [filter, setFilter] = useState(false);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
    }
  }, [meetings.length, ref]);

  let sorted = [...meetings]
    .filter((meeting) => {
      const date = new Date(meeting.date);
      const fromFormatted = from ? setHours(setMinutes(from, 0), 0) : undefined;
      const toFormatted = to ? setHours(setMinutes(to, 59), 23) : undefined;

      if ((fromFormatted && fromFormatted > date) || (toFormatted && toFormatted < date)) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      const key1 = a.date;
      const key2 = b.date;

      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });

  if (!user.isAdmin) {
    sorted = sorted.slice(Math.max(sorted.length - 5, 0));
  }

  return (
    <>
      <Box display="flex" justifyContent="center" mb="30px">
        {filter ? (
          <Box display="flex" alignItems="center">
            <KeyboardDatePicker
              label={t('GlobalAttendances.from')}
              format="dd/MM/yyyy"
              ampm={false}
              value={from || null}
              cancelLabel={t('Common.cancel')}
              onChange={(value) => setFrom(value)}
            />
            <Box mx="15px">
              <Typography> - </Typography>
            </Box>
            <KeyboardDatePicker
              label={t('GlobalAttendances.to')}
              format="dd/MM/yyyy"
              ampm={false}
              value={to || null}
              cancelLabel={t('Common.cancel')}
              onChange={(value) => setTo(value)}
            />
          </Box>
        ) : (
          <Button variant="contained" color="orange" onClick={() => setFilter(true)}>
            Filtrer
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} className={classes.root} ref={ref}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.sticky}>
                <FormattedMessage id="GlobalAttendances.member" />
              </TableCell>
              {sorted.map((event) => (
                <TableCell key={event.id} align="center" className={classes.entry}>
                  {format(new Date(event.date), 'dd/MM/yyyy')}
                </TableCell>
              ))}
              <TableCell align="center" className={clsx(classes.percentages, classes.stickyRight)}>
                <FormattedMessage id="GlobalAttendances.percentageTitle" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adherents.map((adherent) => {
              let participation = 0;

              return (
                <TableRow key={adherent.id}>
                  <TableCell component="th" scope="row" className={classes.sticky}>
                    {adherent.firstName} {adherent.lastName}
                  </TableCell>
                  {sorted.map((event) => {
                    const hasParticipation = event.participants.some((participant) => participant === adherent.id);
                    if (hasParticipation) {
                      participation += 1;
                    }

                    return (
                      <TableCell key={event.id} align="center" className={classes.entry}>
                        {true === hasParticipation && 'x'}
                      </TableCell>
                    );
                  })}
                  <TableCell component="th" scope="row" className={classes.stickyRight} align="center">
                    <FormattedMessage id="GlobalAttendances.percentage" values={{ percentage: participation ? ((participation * 100) / sorted.length).toFixed(2) : '-' }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

GlobalAttendances.propTypes = {
  adherents: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, firstName: PropTypes.string })).isRequired,
  meetings: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, participants: PropTypes.arrayOf(PropTypes.string) })).isRequired,
};

export default GlobalAttendances;
