import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import { galleriesSelector } from '../../store/ducks/news/newsSelector';
import CreateGalleryDialog from './CreateGalleryDialog';
import useDialog from '../../hooks/useDialog';
import GalleryPreview from './GalleryPreview';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
}));

function Galleries() {
  const classes = useStyles();
  const { open, close } = useDialog();

  const galleries = useSelector(galleriesSelector);

  const handleCreateGallery = (e) => {
    e.preventDefault();
    e.stopPropagation();

    open(CreateGalleryDialog, { onClose: close });
  };

  return (
    <Box>
      <Box position="relative">
        <Typography variant="h2">
          <FormattedMessage id="Galleries.title" />
        </Typography>
        <Button variant="contained" className={classes.button} onClick={handleCreateGallery}>
          <FormattedMessage id="Galleries.add" />
        </Button>
      </Box>
      <Box mt="40px" display="flex" justifyContent="center" flexWrap="wrap">
        <Grid container spacing={2}>
          {galleries?.map((gallery) => (
            <GalleryPreview gallery={gallery} key={gallery.id} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Galleries;
