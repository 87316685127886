import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';
import ScheduleRow from './ScheduleRow';
import DAYS from '../../constants/Days';

const useStyles = makeStyles((theme) => ({
  scheduleContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '660px',
    },
  },
}));

function Schedule({ adherent }) {
  const classes = useStyles();

  return (
    <Grid item lg={6} xs={12}>
      <Box mt="25px" className={classes.scheduleContainer}>
        <Typography variant="h2">
          <FormattedMessage id="Adherent.schedule" />
        </Typography>
        {Object.keys(DAYS).map((day) => (
          <ScheduleRow key={day} day={day} dayValue={adherent?.schedule?.[day]} />
        ))}
      </Box>
    </Grid>
  );
}

Schedule.propTypes = {
  adherent: PropTypes.shape({
    schedule: PropTypes.shape({
      monday: PropTypes.string,
      tuesday: PropTypes.string,
      wednesday: PropTypes.string,
      thursday: PropTypes.string,
      friday: PropTypes.string,
      saturday: PropTypes.string,
      sunday: PropTypes.string,
    }),
  }),
};

Schedule.defaultProps = {
  adherent: null,
};

export default Schedule;
