import PropTypes from 'prop-types';
import createChainablePropType from './createChainablePropType';

const CustomPropTypes = {
  classes: createChainablePropType((props, propName, component) => {
    const prop = props[propName];
    let error = '';
    const isValid = Object.values(prop).every((propValue) =>
      Object.entries(propValue).every((entry) => {
        const validated = 'string' === typeof entry[0] && ('string' === typeof entry[1] || 'number' === typeof entry[1]);
        if (!validated) {
          error = `The key "${entry[0]}" with value "${entry[1]}" provided to the classes prop in ${component} is not valid: "${entry[1]}" is not one of types [string, number]`;
        }
        return validated;
      })
    );

    return !isValid && new Error(error);
  }),
  formTouched: PropTypes.objectOf(PropTypes.any),
  formErrors: PropTypes.objectOf(PropTypes.any),
};

export default CustomPropTypes;
