import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '../ui/Typography';
import TextField from '../v2/TextField';

const useStyles = makeStyles((theme) => ({
  fileImage: {
    objectFit: 'cover',
    borderRadius: '15px',
    width: '50%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.4rem',
    display: 'flex',
    alignItems: 'center',
    width: '150px',
  },
}));

function SocialNetworkFormRow({ label, value, placeholder, onChange }) {
  const classes = useStyles();

  return (
    <Box my="10px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.label}>{label}</Typography>
        <TextField value={value} placeholder={placeholder} onChange={onChange} fullWidth />
      </Box>
    </Box>
  );
}

SocialNetworkFormRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default SocialNetworkFormRow;
