export const DIALOG_ACTIONS = {
  OPEN: 'DIALOG/OPEN',
  CLOSE: 'DIALOG/CLOSE',
  CLEAR: 'DIALOG/CLEAR',
};

const initialState = {
  open: false,

  // This random value is used to force re-render the depending components
  // Read more explications in dialogRefStore.js
  random: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DIALOG_ACTIONS.OPEN:
      return {
        ...state,
        open: true,
        random: Math.random(),
      };
    case DIALOG_ACTIONS.CLOSE:
      return {
        ...state,
        open: false,
        random: Math.random(),
      };
    case DIALOG_ACTIONS.CLEAR:
      return {
        ...state,
        open: false,
        random: Math.random(),
      };
    default:
      return state;
  }
};
