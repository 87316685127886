export const NEWS_ACTIONS = {
  getDocuments: 'NEWS/GET_DOCUMENTS',
  setDocument: 'NEWS/SET_DOCUMENT',
  deleteDocument: 'NEWS/DELETE_DOCUMENT',
  getGalleries: 'NEWS/GET_GALLERIES',
  setGallery: 'NEWS/SET_GALLERY',
  setGalleryImage: 'NEWS/SET_GALLERY_IMAGE',
  deleteGallery: 'NEWS/DELETE_GALLERY',
  deleteGalleryImage: 'NEWS/DELETE_GALLERY_IMAGE',
};

const initialState = {
  documents: [],
  galleries: [],
};

function newsReducer(state = initialState, action) {
  switch (action.type) {
    case NEWS_ACTIONS.getDocuments:
      return {
        ...state,
        documents: action.payload.documents,
      };
    case NEWS_ACTIONS.setDocument:
      return {
        ...state,
        documents: [...state.documents, action.payload.document],
      };
    case NEWS_ACTIONS.deleteDocument:
      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== action.payload.id),
      };

    case NEWS_ACTIONS.getGalleries:
      return {
        ...state,
        galleries: action.payload.galleries,
      };
    case NEWS_ACTIONS.setGallery:
      return {
        ...state,
        galleries: [...state.galleries, action.payload.gallery],
      };
    case NEWS_ACTIONS.setGalleryImage:
      return {
        ...state,
        galleries: [...state.galleries.filter((gallery) => gallery.id !== action.payload.gallery.id), action.payload.gallery],
      };
    case NEWS_ACTIONS.deleteGallery:
      return {
        ...state,
        galleries: state.galleries.filter((document) => document.id !== action.payload.id),
      };
    case NEWS_ACTIONS.deleteGalleryImage:
      return {
        ...state,
        galleries: [
          ...state.galleries.filter((document) => document.id !== action.payload.galleryId),
          {
            ...state.galleries.find((document) => document.id === action.payload.galleryId),
            images: [...state.galleries.find((document) => document.id === action.payload.galleryId).images.filter((image) => image.fileName !== action.payload.fileName)],
          },
        ],
      };
    default: {
      return state;
    }
  }
}

export default newsReducer;
