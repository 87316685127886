import { Box, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fade } from '@material-ui/core/styles';
import useTranslate from '../../hooks/useTranslate';
import Typography from '../ui/Typography';
import UploadDropzone from './UploadDropZone';
import TrashIcon from '../icons/TrashIcon';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    marginBottom: '20px',
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: '15px',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      backgroundColor: fade(theme.palette.common.red, 0.6),
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: '15px',
      opacity: 0,
      transition: 'all 200ms ease',
    },
    '&:hover': {
      boxShadow: `0px 0px 6px ${fade(theme.palette.common.red, 0.6)}`,
      '& $fileDelete': {
        opacity: 1,
      },
      '&::after': {
        opacity: 1,
      },
    },
  },
  fileImage: {
    objectFit: 'cover',
    borderRadius: '15px',
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      boxShadow: `0px 0px 6px ${fade(theme.palette.common.red, 0.6)}`,
      '& $fileDelete': {
        opacity: 1,
      },
      '&::after': {
        opacity: 1,
      },
    },
  },
  fileDelete: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    opacity: 0,
    transition: 'all 200ms ease',
  },
}));

function PhotosForm({ form }) {
  const classes = useStyles();
  const { t } = useTranslate();
  const theme = useTheme();

  const onDropMemberPhoto = (acceptedFile) => {
    form.setFieldValue(
      'memberPhoto',
      acceptedFile?.find((x) => x)
    );
  };
  const onDropCompanyLogo = (acceptedFile) => {
    form.setFieldValue(
      'companyLogo',
      acceptedFile?.find((x) => x)
    );
  };

  const handleRemove = async (field) => {
    form.setFieldValue(field, '');
  };

  return (
    <>
      <Box mt="85px">
        <Typography className={classes.label}>
          <FormattedMessage id="AdherentForm.memberPhoto.label" />
        </Typography>
        <UploadDropzone onDrop={onDropMemberPhoto} />
        <Box display="flex" justifyContent="center" alignItems="center" mt="20px" position="relative" onClick={() => handleRemove('memberPhoto')}>
          {form.values.memberPhoto && (
            <Box className={classes.fileContainer}>
              <TrashIcon size="40px" color={theme.palette.common.white} className={classes.fileDelete} />
              <img
                alt={t('Common.name')}
                src={'object' === typeof form.values.memberPhoto ? URL.createObjectURL(form.values.memberPhoto) : form.values.memberPhoto}
                className={classes.fileImage}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box mt="85px">
        <Typography className={classes.label}>
          <FormattedMessage id="AdherentForm.companyLogo.label" />
        </Typography>
        <UploadDropzone onDrop={onDropCompanyLogo} />
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" mt="20px" onClick={() => handleRemove('companyLogo')}>
          {form.values.companyLogo && (
            <Box className={classes.fileContainer}>
              <TrashIcon size="40px" color={theme.palette.common.white} className={classes.fileDelete} />
              <img
                alt={t('Common.name')}
                src={'object' === typeof form.values.companyLogo ? URL.createObjectURL(form.values.companyLogo) : form.values.companyLogo}
                className={classes.fileImage}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

PhotosForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      memberPhoto: oneOfType([PropTypes.string, PropTypes.object]),
      companyLogo: oneOfType([PropTypes.string, PropTypes.object]),
    }),
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
  }).isRequired,
};

export default PhotosForm;
