import { v4 as uuidv4 } from 'uuid';
import { storage } from '../firebase';

export default async function uploadFile(file, folder = '') {
  const storageRef = storage.ref();
  const fileExtension = file.name.split('.').pop();
  const fileName = `${folder}/${uuidv4()}.${fileExtension}`;

  const fileSnap = await storageRef.child(`${fileName}`).put(file);

  return new Promise((res) =>
    res({
      name: fileName,
      snap: fileSnap,
    })
  );
}
