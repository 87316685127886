import { firestore } from '../../../firebase';
import snapshotToEntity from '../../../utils/snapshotToEntity';
import { deleteMeetingAction, pushMeetingsAction } from './meetingsActions';

// eslint-disable-next-line import/prefer-default-export
export function getMeetingsThunk() {
  return async (dispatch) => {
    const booksRef = await firestore.collection('meetings');
    booksRef.get().then((snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return snapshotToEntity(doc);
      });

      dispatch(pushMeetingsAction(data));
    });
  };
}

export const createMeetingThunk = ({ data, userId }) => async () => {
  await firestore
    .collection('meetings')
    .add({ ...data, userId })
    .then((docRef) => {
      return docRef.id;
    });
};

export const updateMeetingThunk = ({ id, data }) => async () => {
  await firestore.collection('meetings').doc(id).set(data);
};

export function deleteMeetingThunk(id) {
  return async (dispatch) => {
    await firestore.collection('meetings').doc(id).delete();
    dispatch(deleteMeetingAction(id));
  };
}
