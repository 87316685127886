import { useDispatch } from 'react-redux';
import { clearDialogThunk, closeDialogAction, openDialogThunk } from '../store/ducks/dialog/dialogActions';

const useDialog = () => {
  const dispatch = useDispatch();
  const open = (Component, props) => dispatch(openDialogThunk(Component, props));
  const close = () => dispatch(closeDialogAction());
  const clear = () => dispatch(clearDialogThunk());

  return { open, close, clear };
};

export default useDialog;
