import keyBy from 'lodash/keyBy';

export const EVENTS_ACTIONS = {
  push: 'EVENTS/PUSH',
};

const initialState = {
  byId: {},
};

function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case EVENTS_ACTIONS.push:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(action.payload.events, 'id'),
        },
      };
    default: {
      return state;
    }
  }
}

export default eventsReducer;
