import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function ProfilIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 29.2 35">
      <path
        d="M95.462,16.86a8.157,8.157,0,0,0,5.961-2.47,8.156,8.156,0,0,0,2.469-5.96,8.158,8.158,0,0,0-2.47-5.96,8.428,8.428,0,0,0-11.921,0,8.157,8.157,0,0,0-2.47,5.96A8.156,8.156,0,0,0,89.5,14.39,8.159,8.159,0,0,0,95.462,16.86Zm0,0"
        transform="translate(-81.082 0)"
        fill={color}
        width={size}
        height={size}
      />
      <path
        d="M29.13,257.323a20.822,20.822,0,0,0-.284-2.212,17.424,17.424,0,0,0-.544-2.224,10.982,10.982,0,0,0-.914-2.074,7.826,7.826,0,0,0-1.378-1.8,6.077,6.077,0,0,0-1.98-1.244,6.843,6.843,0,0,0-2.528-.457,2.566,2.566,0,0,0-1.37.581c-.411.268-.891.578-1.427.92a8.178,8.178,0,0,1-1.847.814,7.171,7.171,0,0,1-4.518,0,8.159,8.159,0,0,1-1.845-.813c-.531-.339-1.012-.649-1.429-.921a2.562,2.562,0,0,0-1.37-.581,6.834,6.834,0,0,0-2.527.458,6.073,6.073,0,0,0-1.98,1.244,7.827,7.827,0,0,0-1.378,1.8A11,11,0,0,0,.9,252.888a17.466,17.466,0,0,0-.544,2.223,20.747,20.747,0,0,0-.284,2.212c-.046.67-.07,1.365-.07,2.067a5.812,5.812,0,0,0,1.726,4.4,6.214,6.214,0,0,0,4.448,1.622H23.026a6.212,6.212,0,0,0,4.448-1.622,5.809,5.809,0,0,0,1.726-4.4c0-.705-.024-1.4-.071-2.067Zm0,0"
        transform="translate(0 -230.41)"
        fill={color}
        width={size}
        height={size}
      />
    </SvgIcon>
  );
}

ProfilIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ProfilIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default ProfilIcon;
