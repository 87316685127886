import React, { useRef, useState, useEffect } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import NewsDocument from '../../components/News/NewsDocument';
import Button from '../../components/ui/Button';
import { getDocumentsThunk, getGalleriesThunk, uploadDocumentThunk } from '../../store/ducks/news/newsThunks';
import useTranslate from '../../hooks/useTranslate';
import { documentsSelector } from '../../store/ducks/news/newsSelector';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import Galleries from '../../components/News/Galleries';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  inputFile: {
    display: 'none',
  },
}));

function News() {
  const classes = useStyles();
  const inputFile = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const [file, setFile] = useState(null);

  const handleClick = () => {
    inputFile.current.click();
  };

  const user = useSelector(userSelector);
  const documents = useSelector(documentsSelector);

  useEffect(() => {
    if (file) {
      if (!documents.find((document) => document?.title === file?.name)) {
        try {
          dispatch(uploadDocumentThunk({ file, userId: user.id }, 'documents'));
          setFile(null);
          toast.success(t('News.fileUploaded'));
        } catch (err) {
          toast.error(t('Errors.api.default'));
          setFile(null);
        }
      } else {
        toast.error(t('News.fileAlreadyUploaded'));
        setFile(null);
      }
    }
  }, [dispatch, file, documents, t, user.id]);

  useEffect(() => {
    dispatch(getDocumentsThunk());
    dispatch(getGalleriesThunk());
  }, [dispatch]);

  return (
    <AppLayout>
      <Container>
        <input
          type="file"
          ref={inputFile}
          className={classes.inputFile}
          onChange={(e) => {
            setFile(e.target.files[0]);
            e.target.value = null;
          }}
        />
        <Box textAlign="center" mb="50px" mt="50px">
          <Typography variant="h1">
            <FormattedMessage id="News.title" />
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item md={4}>
            <Box position="relative">
              <Typography variant="h2">
                <FormattedMessage id="News.documents" />
              </Typography>
              <Button variant="contained" className={classes.button} onClick={handleClick}>
                <FormattedMessage id="News.addDocument" />
              </Button>
            </Box>
            <Box mt="40px" display="flex" justifyContent="center" flexWrap="wrap">
              {documents?.map((document) => (
                <NewsDocument document={document} key={document.id} />
              ))}
            </Box>
          </Grid>
          <Grid item md={8}>
            <Galleries />
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  );
}

export default News;
