import { Box, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import SocialNetworks from './SocialNetworks';
import Typography from '../ui/Typography';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  largeSocialNetworks: {
    marginTop: '20px',
  },
  logoPlaceholder: {
    boxShadow: '0 3px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
    width: '242px',
    height: '242px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    filter: 'drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3))',
    borderRadius: '15px',
    width: '242px',
    height: 'auto',
  },
  memberPhotoContainer: {
    margin: '15px',
    [theme.breakpoints.up('lg')]: {
      marginRight: '0',
    },
  },
}));

function PhotosAndNetworks({ adherent }) {
  const { t } = useTranslate();
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid item lg={6} xs={12}>
      <Box mb="25px" display="flex" justifyContent="center" alignItems="center">
        <Box display={!isSmall ? 'flex' : undefined} position="relative">
          {isLarge && <SocialNetworks className={classes.largeSocialNetworks} />}
          <Box margin="15px">
            {adherent?.companyLogo ? (
              <img src={adherent?.companyLogo} className={classes.logo} alt={t('Adherent.companyLogo')} />
            ) : (
              <Box className={classes.logoPlaceholder}>
                <Typography>
                  <FormattedMessage id="Adherent.noCompanyLogo" />
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.memberPhotoContainer}>
            {adherent?.memberPhoto ? (
              <img src={adherent?.memberPhoto} alt={t('Adherent.memberPhoto')} className={classes.logo} />
            ) : (
              <Box className={classes.logoPlaceholder}>
                <Typography>
                  <FormattedMessage id="Adherent.noMemberPhoto" />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

PhotosAndNetworks.propTypes = {
  adherent: PropTypes.shape({
    companyLogo: PropTypes.string,
    memberPhoto: PropTypes.string,
  }),
};

PhotosAndNetworks.defaultProps = {
  adherent: null,
};

export default PhotosAndNetworks;
