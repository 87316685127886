import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import Typography from '../ui/Typography';
import PdfIcon from '../icons/PdfIcon';
import FileIcon from '../icons/FileIcon';
import { deleteDocumentsThunk } from '../../store/ducks/news/newsThunks';
import NewsPopover from './NewsPopover';
import TripleDotsIcon from '../icons/TripleDotsIcon';
import useTranslate from '../../hooks/useTranslate';
import useDialog from '../../hooks/useDialog';
import DeleteDialog from '../DeleteDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '120px',
    margin: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    paddingTop: '40px',
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px',
  },
  toolsButton: {
    border: '1px solid',
    borderRadius: '5px',
    width: '25px',
    height: '25px',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    backgroundColor: theme.palette.common.white,
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
  image: {
    maxWidth: '70px',
    borderRadius: '5px',
  },
  fileTitle: {
    maxWidth: '100px',
    marginTop: '10px',
    textAlign: 'center',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
}));

function NewsDocument({ document }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { open, close } = useDialog();
  const fileType = document.type;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleClickDownload = () => {
    window.open(document.url);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await dispatch(deleteDocumentsThunk(document.id));

      close();
      toast.success(t('News.dialog.deleted'));
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelete = (fileName) => {
    open(DeleteDialog, { onDelete: () => handleDelete(fileName), isLoading, title: t('News.dialog.title'), onClose: close });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.toolsButton} onClick={handleClick}>
        <TripleDotsIcon size="15px" />
      </Box>
      {fileType?.includes('pdf') && <PdfIcon size="70px" />}
      {fileType?.includes('image') && (
        <Box className={classes.imageContainer}>
          <img src={document.url} alt="title to replace" className={classes.image} />
        </Box>
      )}
      {!fileType?.includes('image') && !fileType?.includes('pdf') && <FileIcon size="70px" />}
      <Typography className={classes.fileTitle}>{document.title}</Typography>
      <NewsPopover
        onClose={handleClose}
        onDelete={openDelete}
        onClickDownload={handleClickDownload}
        elementId={document.userId}
        anchorEl={anchorEl}
        firstOptionText={t('News.download')}
        deleteText={t('News.delete')}
      />
    </Box>
  );
}

NewsDocument.propTypes = {
  document: PropTypes.shape({
    url: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
};

export default NewsDocument;
