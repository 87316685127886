import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import useTranslate from '../../hooks/useTranslate';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import Typography from '../../components/ui/Typography';

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: '30px',
    padding: '24px',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
  },
  card: {
    marginTop: '12px',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
    cursor: 'pointer',
    padding: '24px',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      flexDirection: 'column',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  address: {
    marginLeft: '5%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  lastName: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));

function AdherentsResult({ word }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const adherents = useSelector(visibleAdherentsSelector);
  const classes = useStyle();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);

  const options = {
    keys: ['address', 'companyName', 'firstName', 'lastName', 'products'],
  };

  const fuse = new Fuse(adherents, options);
  const filteredAdherents = fuse.search(word);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await dispatch(getAdherentsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  }, [word]);

  if (isLoading) {
    return (
      <Box textAlign="center" mt="24px">
        <CircularProgress />
      </Box>
    );
  }
  if (0 < filteredAdherents.length) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h1">
          <FormattedMessage id="Research.adherents.title" />
        </Typography>
        {filteredAdherents.map((adherent) => {
          const { firstName, lastName, companyName, products, address, id } = adherent.item;

          return (
            <Box className={classes.card} onClick={() => history.push(`/adherents/${id}`)}>
              <Box className={classes.content}>
                <Typography>
                  <span className={classes.lastName}>{lastName}</span> {firstName}
                </Typography>
                <Typography>{companyName}</Typography>
              </Box>
              <Box className={[classes.content, classes.address]}>
                <Typography>{address}</Typography>
                <Typography>{products}</Typography>
              </Box>
            </Box>
          );
        })}
      </Paper>
    );
  }
  return null;
}

AdherentsResult.propTypes = {
  word: PropTypes.string.isRequired,
};

export default AdherentsResult;
