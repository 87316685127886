export const ADHERENTS_ACTIONS = {
  set: 'ADHERENTS/SET_ADHERENTS',
  update: 'ADHERENTS/UPDATE_ADHERENTS',
  create: 'ADHERENTS/CREATE_ADHERENT',
  delete: 'ADHERENTS/DELETE_ADHERENT',
};

// Initial State
const initialState = {
  items: [],
};

// Reducer@
export default (state = initialState, action) => {
  switch (action.type) {
    case ADHERENTS_ACTIONS.set: {
      return {
        ...state,
        items: action.payload.adherents,
      };
    }
    case ADHERENTS_ACTIONS.update: {
      return {
        ...state,
        items: state.items.map((adherent) => {
          if (adherent.id === action.payload.user.id) {
            return { ...adherent, ...action.payload.user };
          }
          return adherent;
        }),
      };
    }
    case ADHERENTS_ACTIONS.delete: {
      return {
        ...state,
        items: state.items.filter((adherent) => adherent.id !== action.payload.id),
      };
    }
    case ADHERENTS_ACTIONS.create: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};
