import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function SearchIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 25 26" fill={color}>
      <path
        d="M25.466,23.454,19.338,17.08a10.392,10.392,0,1,0-7.957,3.713,10.285,10.285,0,0,0,5.956-1.881l6.175,6.422a1.356,1.356,0,1,0,1.955-1.88ZM11.38,2.712A7.684,7.684,0,1,1,3.7,10.4,7.693,7.693,0,0,1,11.38,2.712Z"
        transform="translate(-0.984)"
        fill="#fff"
      />
    </SvgIcon>
  );
}

SearchIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

SearchIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default SearchIcon;
