import React from 'react';
import { useSelector } from 'react-redux';
import BusinessStatistics from '../../components/Business/BusinessStatistics';
import AppLayout from '../../components/AppLayout';
import BusinessOverview from '../../components/Business/BusinessOverview';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function Business() {
  const user = useSelector(userSelector);

  return (
    <AppLayout>
      <BusinessStatistics />
      {user.isAdmin && <BusinessOverview />}
    </AppLayout>
  );
}

export default Business;
