import { useEffect, useState } from 'react';

/**
 * useEffect pour une fonction asynchrone
 *
 * @param {function} fn
 * @param {array} deps
 * @param {boolean} throwOnError Make the component "crash" if the promise is not resolved
 */
export default function useAsyncEffect(fn, deps, throwOnError = true) {
  const [, setState] = useState(); // This is a fake state used to throw an error catchable by the errorBoundary
  /* eslint-disable */
  useEffect(() => {
    Promise.resolve(fn()).catch((e) => {
      if (throwOnError) {
        setState(() => {
          throw e; // The magic happens here ;)
        });
      }
    });
  }, deps);
  /* eslint-enable */
}
