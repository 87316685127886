import { Box, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import InstagramIcon from '../icons/InstagramIcon';
import FacebookIcon from '../icons/FacebookIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import ChainIcon from '../icons/ChainIcon';
import CustomPropTypes from '../../utils/CustomPropTypes';

const useStyles = makeStyles({
  socialNetwork: {
    cursor: 'pointer',
  },
});
function SocialNetworks({ adherent, className }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={className}>
      {adherent?.socialNetworks?.instagram && (
        <Box margin="3px" onClick={() => window.open(adherent?.socialNetworks?.instagram)} className={classes.socialNetwork}>
          <InstagramIcon color={theme.palette.primary.main} />
        </Box>
      )}
      {adherent?.socialNetworks?.facebook && (
        <Box margin="3px" onClick={() => window.open(adherent?.socialNetworks?.facebook)} className={classes.socialNetwork}>
          <FacebookIcon color={theme.palette.primary.main} />
        </Box>
      )}
      {adherent?.socialNetworks?.linkedIn && (
        <Box margin="3px" onClick={() => window.open(adherent?.socialNetworks?.linkedIn)} className={classes.socialNetwork}>
          <LinkedInIcon color={theme.palette.primary.main} />
        </Box>
      )}
      {adherent?.socialNetworks?.website && (
        <Box margin="3px" onClick={() => window.open(adherent?.socialNetworks?.website)} className={classes.socialNetwork}>
          <ChainIcon color={theme.palette.primary.main} />
        </Box>
      )}
    </Box>
  );
}

SocialNetworks.propTypes = {
  className: CustomPropTypes.classes,
  adherent: PropTypes.shape({
    socialNetworks: PropTypes.shape({
      instagram: PropTypes.string,
      facebook: PropTypes.string,
      linkedIn: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
};

SocialNetworks.defaultProps = {
  className: '',
  adherent: null,
};
export default SocialNetworks;
