import Login from './Login.json';
import Adherents from './Adherents.json';
import Adherent from './Adherent.json';
import AdherentForm from './AdherentForm.json';
import NotFound from './NotFound.json';
import CreateUser from './CreateUser.json';
import Initialize from './Initialize.json';
import Users from './Users.json';
import UserForm from './UserForm.json';
import SendEmail from './SendEmail.json';
import CreateEvent from './CreateEvent.json';
import EditEvent from './EditEvent.json';
import Events from './Events.json';
import CreateBusiness from './CreateBusiness.json';
import BusinessStatistics from './BusinessStatistics.json';
import News from './News.json';
import Attendances from './Attendances.json';
import Gallery from './Gallery.json';
import MyProfile from './MyProfile.json';
import Research from './Research.json';
import EditBusiness from './EditBusiness.json';

export default {
  Login,
  Adherents,
  Adherent,
  AdherentForm,
  CreateUser,
  NotFound,
  Initialize,
  Users,
  UserForm,
  SendEmail,
  CreateEvent,
  EditEvent,
  Attendances,
  Events,
  CreateBusiness,
  BusinessStatistics,
  News,
  Gallery,
  MyProfile,
  Research,
  EditBusiness,
};
