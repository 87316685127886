// eslint-disable-next-line no-restricted-imports
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import withError from './withError';

// TODO Remove V2 Folder

const DateTimePicker = withError(MuiDateTimePicker);

DateTimePicker.propTypes = MuiDateTimePicker.propTypes;

export default DateTimePicker;
