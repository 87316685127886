/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router';
import Typography from '../../components/ui/Typography';
import useTranslate from '../../hooks/useTranslate';
import AppLayout from '../../components/AppLayout';
import { createEventThunk } from '../../store/ducks/events/eventsThunks';
import EventForm from '../../components/Events/EventForm';

function CreateEvent() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(createEventThunk(values));

      toast.success(t('CreateEvent.success'));
      history.push('/evenements');
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <Box mt="60px">
        <Typography variant="h1" align="center" color="primary.main">
          <FormattedMessage id="CreateEvent.title" />
        </Typography>
        <Box mt="40px" mb="20px">
          <Typography variant="h2">
            <FormattedMessage id="CreateEvent.subtitle" />
          </Typography>
        </Box>
        <EventForm onSubmit={handleSubmit} isLoading={isLoading} />
      </Box>
    </AppLayout>
  );
}

export default CreateEvent;
