import React, { useState } from 'react';
import { Box, Hidden, ListItem, ListItemText, makeStyles, Drawer, useTheme, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Button from './ui/Button';
import TextField from './v2/TextField';
import BurgerMenuIcon from './icons/BurgerMenuIcon';
import ProfilIcon from './icons/ProfilIcons';
import useTranslate from '../hooks/useTranslate';
import SearchIcon from './icons/SearchIcon';
import { logoutThunk } from '../store/ducks/auth/authThunks';
import Typography from './ui/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 999,
    backgroundColor: theme.palette.common.white,
  },

  logo: {
    width: '140px',
    height: '80px',
  },
  burgerMenu: {
    cursor: 'pointer',
  },
  listItemText: {
    ...theme.typography.h2,
    textAlign: 'right',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
  selectedItem: {
    color: theme.palette.common.orange,
  },
  profilIcon: {
    cursor: 'pointer',
  },
  searchText: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '25px !important',
    },
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    width: '50px',
    height: '55px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 1,
    top: 0,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accordionRoot: {
    boxShadow: 'none',
    marginBottom: '0 !important',
  },
  accordionSummary: { padding: 0, height: '0px' },
  accordionDetail: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '0' },
  accordionNavLinks: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
  menu: {
    width: '220px',
  },
}));

function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profilAnchorEl, setProfilAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const items = [
    {
      id: 'adherents',
      label: t('Header.adherents'),
      path: '/',
    },
    {
      id: 'stats',
      label: t('Header.stats'),
    },
    {
      id: 'agenda',
      label: t('Header.agenda'),
      path: '/evenements',
    },
    {
      id: 'news',
      label: t('Header.news'),
      path: '/actualites',
    },
  ];

  const handleClick = (item) => {
    if (item?.onClick) {
      return item.onClick();
    }
    return history.push(item.path);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfilMenu = (event) => {
    setProfilAnchorEl(event.currentTarget);
  };

  const handleProfilClose = () => {
    setProfilAnchorEl(null);
  };

  const handleClickMenuItem = (path) => history.push(path);

  const handleSearch = (e) => {
    if ('Enter' === e.key && '' !== search.trim()) {
      history.push(`/recherche/${search}`);
    }
  };

  const logout = () => {
    try {
      dispatch(logoutThunk());
      toast.success(t('Header.disconnected'));
    } catch {
      toast.error(t('Errors.api.default'));
    }
  };

  return (
    <Box display="flex" className={classes.root} justifyContent="space-between">
      <img alt={t('Common.name')} src={logo} className={classes.logo} />
      <Hidden mdDown>
        <Box display="flex">
          {items.map((item) => {
            return (
              <ListItem key={item.id} onClick={() => handleClick(item)}>
                <Box anchorEl={anchorEl} onClick={'stats' === item.id ? handleClickMenu : undefined}>
                  <ListItemText primary={item.label} classes={{ primary: clsx(classes.listItemText, item?.path === location.pathname && classes.selectedItem) }} />
                </Box>
              </ListItem>
            );
          })}
        </Box>
        <Box display="flex" alignItems="center">
          <Box mx="13px">
            <Button size="large" variant="contained" color="orange" component={Link} to="/affaires/ajouter">
              <FormattedMessage id="Header.business" />
            </Button>
          </Box>
          <Box />
          <Box mx="13px">
            <Button size="large" variant="contained" color="green" component={Link} to="/envoyer-un-email">
              <FormattedMessage id="Header.mailing" />
            </Button>
          </Box>
          <Box ml="13px" mr="26px" position="relative">
            <TextField onChange={(e) => setSearch(e.target.value)} onKeyPress={handleSearch} placeholder={t('Header.search.placeholder')} className={classes.searchText} />
            <Box className={classes.searchIcon}>
              <SearchIcon size="24px" color={theme.palette.common.white} />
            </Box>
          </Box>
          <Box mt="4px" mr="26px" className={classes.profilIcon} anchorEl={profilAnchorEl} onClick={handleClickProfilMenu}>
            <ProfilIcon color={theme.palette.primary.dark} />
          </Box>
        </Box>
      </Hidden>

      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" marginBottom="auto" height="100%" mt="40px" mb="40px">
          <Box display="flex" flexDirection="column" mx="20px" alignItems="flex-end">
            <Box mb="11px" width="100%" ml="10px" position="relative">
              <TextField
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={handleSearch}
                placeholder={t('Header.search.placeholder')}
                className={classes.searchText}
              />
              <Box className={classes.searchIcon}>
                <SearchIcon size="24px" color={theme.palette.common.white} />
              </Box>
            </Box>
            <Box mb="10px" ml="10px" width="100%">
              <Button size="large" variant="contained" color="orange" fullWidth component={Link} to="/affaires/ajouter">
                <FormattedMessage id="Header.business" />
              </Button>
            </Box>
            <Box />
            <Box mb="10px" ml="10px" width="100%">
              <Button size="large" variant="contained" color="green" fullWidth component={Link} to="/envoyer-un-email">
                <FormattedMessage id="Header.mailing" />
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            {items.map((item) => {
              return (
                <ListItem key={item.id} onClick={() => handleClick(item)} className={classes.listItem}>
                  {'stats' === item.id ? (
                    <Accordion className={classes.accordionRoot}>
                      <AccordionSummary className={classes.accordionSummary}>
                        <ListItemText primary={item.label} classes={{ primary: clsx(classes.listItemText, item.path === location.pathname && classes.selectedItem) }} />
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetail}>
                        <Box className={classes.accordionNavLinks} onClick={() => history.push('/affaires/statistiques')}>
                          <Typography>
                            <FormattedMessage id="Header.businessStats" />
                          </Typography>
                        </Box>
                        <Box mt="5px" className={classes.accordionNavLinks} onClick={() => history.push('/presences/statistiques')}>
                          <Typography>
                            <FormattedMessage id="Header.attendancesStats" />
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <ListItemText primary={item.label} classes={{ primary: clsx(classes.listItemText, item.path === location.pathname && classes.selectedItem) }} />
                  )}
                </ListItem>
              );
            })}
            <ListItem className={classes.listItem}>
              <Accordion className={classes.accordionRoot}>
                <AccordionSummary className={classes.accordionSummary}>
                  <ListItemText
                    primary={t('Header.account')}
                    classes={{
                      primary: clsx(
                        classes.listItemText,
                        ('/mon-profil' === location.pathname && classes.selectedItem) || ('/utilisateurs' === location.pathname && classes.selectedItem)
                      ),
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  <Box className={classes.accordionNavLinks} onClick={() => history.push('/mon-profil')}>
                    <Typography className={clsx('/mon-profil' === location.pathname && classes.selectedItem)}>
                      <FormattedMessage id="Header.myProfile" />
                    </Typography>
                  </Box>
                  <Box mt="5px" className={classes.accordionNavLinks} onClick={logout}>
                    <Typography>
                      <FormattedMessage id="Header.disconnect" />
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </Box>
        </Box>
      </Drawer>

      <Hidden lgUp>
        <Box mr="40px" display="flex" alignSelf="center" className={classes.burgerMenu} onClick={() => setDrawerOpen(true)}>
          <BurgerMenuIcon size="40px" />
        </Box>
      </Hidden>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleClickMenuItem('/affaires/statistiques')}>
          <FormattedMessage id="Header.businessStats" />
        </MenuItem>
        <MenuItem onClick={() => handleClickMenuItem('/presences/statistiques')}>
          <FormattedMessage id="Header.attendancesStats" />
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={profilAnchorEl}
        keepMounted
        open={!!profilAnchorEl}
        onClose={handleProfilClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleClickMenuItem('/mon-profil')}>
          <FormattedMessage id="Header.myProfile" />
        </MenuItem>
        <MenuItem onClick={() => logout()}>
          <FormattedMessage id="Header.disconnect" />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Header;
