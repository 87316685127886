/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Dialog, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import useTranslate from '../../hooks/useTranslate';
import { eventByIdSelector } from '../../store/ducks/events/eventsSelectors';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import { updateParticipationThunk } from '../../store/ducks/events/eventsThunks';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { adherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';

function EventDialog({ open, onClose, eventId, ...props }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const history = useHistory();

  const event = useSelector(eventByIdSelector(eventId));
  const user = useSelector(userSelector);
  const adherents = useSelector(adherentsSelector);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAdherentsThunk());
  }, [dispatch]);

  const handleAnswer = async (participate) => {
    try {
      setLoading(true);
      await dispatch(updateParticipationThunk(eventId, user.id, participate));
      toast.success(t('EventDialog.success'));
      onClose();
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    history.push(`/evenements/${event.id}/editer`);
    onClose();
  };

  const canEdit = event.userId === user.id || user.isAdmin;

  const didAnswer = event.participations?.find((participation) => participation.userId === user.id)?.participate !== undefined;

  const participate = event.participations?.find((participation) => participation.userId === user.id)?.participate;

  const buttonVariant = (participateButton) => {
    if (didAnswer) {
      if (participateButton ? participate : !participate) {
        return 'contained';
      }
      return 'outlined';
    }
    return 'outlined';
  };

  const confirmedParticipations = event.participations?.filter((participation) => participation.participate) ?? [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" {...props}>
      <Box px="40px" py="40px" width="800px">
        <Box mb="40px">
          <Typography variant="h1">{event.title}</Typography>
          {canEdit && (
            <Box mt="12px">
              <Button color="orange" size="small" variant="contained" onClick={handleEdit}>
                <FormattedMessage id="EventDialog.edit" />
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          <Typography component="span" variant="bodyBold">
            <FormattedMessage id="EventDialog.start" />
          </Typography>
          <Typography component="span">{t('Common.datetime', { date: event.start })}</Typography>
        </Box>
        <Box mt="12px">
          <Typography component="span" variant="bodyBold">
            <FormattedMessage id="EventDialog.end" />
          </Typography>
          <Typography component="span">{t('Common.datetime', { date: event.end })}</Typography>
        </Box>
        <Box mt="12px">
          <Typography component="span" variant="bodyBold">
            <FormattedMessage id="EventDialog.type" />
          </Typography>
          <Typography component="span">
            <FormattedMessage id="Constants.EventType" values={{ value: event.type }} />
          </Typography>
        </Box>
        <Box mt="12px">
          <Typography variant="bodyBold">
            <FormattedMessage id="EventDialog.description" />
          </Typography>
          <Typography component="span" multiline>
            {event.description ?? '--'}
          </Typography>
        </Box>
        <Box mt="12px">
          <Typography variant="bodyBold">
            <FormattedMessage id="EventDialog.participantsList" />
          </Typography>
          {0 === confirmedParticipations.length
            ? '--'
            : confirmedParticipations.map((participation) => {
                const participationAdherent = adherents.find((adherent) => adherent.id === participation.userId);
                return <Typography>{participationAdherent?.fullName}</Typography>;
              })}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt="40px">
          <Box mr="10px">
            <Button variant={buttonVariant()} onClick={() => handleAnswer(false)} loading={loading} color="grey">
              <FormattedMessage id="EventDialog.noParticipate" />
            </Button>
          </Box>
          <Button variant={buttonVariant(true)} onClick={() => handleAnswer(true)} loading={loading} color="green">
            <FormattedMessage id="EventDialog.participate" />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

EventDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default EventDialog;
