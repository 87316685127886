import { mixed, string, number, bool, boolean, date, object, ref, array, addMethod, setLocale, ValidationError } from 'yup';

export const REGEX = {
  password: /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  phoneNumber: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
};

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: { key: 'Errors.validation.required' },
    oneOf: { key: 'Errors.validation.invalid' },
  },
  number: {
    positive: { key: 'Errors.validation.number.positive' },
  },
  array: {
    min: ({ min }) => ({ key: 'Errors.validation.array.min', values: { min } }),
  },
  // use functions to generate an error object that includes the value from the schema
  string: {
    min: ({ min }) => ({ key: 'Errors.validation.string.min', values: { min } }),
    max: ({ max }) => ({ key: 'Errors.validation.string.min', values: { max } }),
    email: { key: 'Errors.validation.string.email' },
  },
});

export const phone = () => {
  return string().matches(new RegExp(REGEX.phoneNumber), { message: { key: 'Errors.validation.string.phoneNumber' } });
};

export const password = () => {
  return string().matches(new RegExp(REGEX.password), { message: { key: 'Errors.validation.string.password' } });
};

export const confirmedPassword = (field) => {
  return string()
    .required({ key: 'Errors.validation.confirmedPassword.required' })
    .oneOf([ref(field), null], { key: 'Errors.validation.confirmedPassword.equal' });
  // eslint-disable-next-line func-names
};

export const differentPassword = (field) => {
  return (
    string()
      .required({ key: 'Errors.validation.confirmedPassword.required' })
      .notOneOf([ref(field), null], { key: 'Errors.validation.confirmedPassword.notEqual' })
      // eslint-disable-next-line func-names
      .test('notEqual', 'Password must be different!', function (v) {
        // Don't use arrow functions

        return v !== this.resolve(ref);
      })
  );
};

export { mixed, string, number, bool, boolean, date, object, ref, array, addMethod, setLocale, ValidationError };
