import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function InstagramIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 35 35">
      <g transform="translate(-326 -313)">
        <path d="M213.7,210.35a3.35,3.35,0,1,1-3.35-3.35A3.35,3.35,0,0,1,213.7,210.35Zm0,0" transform="translate(133.15 120.15)" fill={color} />
        <path
          d="M151.557,137.8a3.328,3.328,0,0,0-1.907-1.907,5.56,5.56,0,0,0-1.866-.346c-1.06-.048-1.378-.059-4.061-.059s-3,.01-4.061.058a5.563,5.563,0,0,0-1.866.346,3.329,3.329,0,0,0-1.907,1.907,5.562,5.562,0,0,0-.346,1.866c-.048,1.06-.059,1.377-.059,4.061s.01,3,.059,4.061a5.56,5.56,0,0,0,.346,1.866,3.328,3.328,0,0,0,1.907,1.907,5.553,5.553,0,0,0,1.866.346c1.06.048,1.377.059,4.06.059s3-.01,4.061-.059a5.553,5.553,0,0,0,1.866-.346,3.328,3.328,0,0,0,1.907-1.907,5.565,5.565,0,0,0,.346-1.866c.048-1.06.058-1.378.058-4.061s-.01-3-.058-4.061A5.553,5.553,0,0,0,151.557,137.8Zm-7.834,11.087a5.16,5.16,0,1,1,5.16-5.16A5.16,5.16,0,0,1,143.723,148.887Zm5.364-9.318a1.206,1.206,0,1,1,1.206-1.206A1.206,1.206,0,0,1,149.087,139.569Zm0,0"
          transform="translate(199.777 186.773)"
          fill={color}
        />
        <path
          d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Zm9.988,21.643a7.373,7.373,0,0,1-.467,2.439,5.138,5.138,0,0,1-2.939,2.939,7.378,7.378,0,0,1-2.439.467c-1.072.049-1.414.061-4.143.061s-3.071-.012-4.143-.061a7.378,7.378,0,0,1-2.439-.467,5.138,5.138,0,0,1-2.939-2.939,7.371,7.371,0,0,1-.467-2.439c-.049-1.072-.061-1.414-.061-4.143s.011-3.071.061-4.143a7.373,7.373,0,0,1,.467-2.439,5.142,5.142,0,0,1,2.939-2.939,7.379,7.379,0,0,1,2.439-.467c1.072-.049,1.414-.061,4.143-.061s3.071.012,4.143.061a7.381,7.381,0,0,1,2.439.466,5.14,5.14,0,0,1,2.939,2.939,7.372,7.372,0,0,1,.467,2.439c.049,1.072.06,1.414.06,4.143S27.537,20.571,27.488,21.643Zm0,0"
          transform="translate(326 313)"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

InstagramIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

InstagramIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default InstagramIcon;
