/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Dialog, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../v2/TextField';
import { createGalleryThunk } from '../../store/ducks/news/newsThunks';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import { object, string } from '../../utils/yup';

function CreateGalleryDialog({ open, onClose, ...props }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const user = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: object().shape({
      title: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(createGalleryThunk({ title: values.title, userId: user.id }));

        toast.success(t('CreateGalleryDialog.success'));
        onClose();
      } catch (err) {
        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" {...props}>
      <form onSubmit={form.handleSubmit}>
        <Box px="40px" py="40px">
          <Box mb="40px">
            <Typography variant="h1">
              <FormattedMessage id="CreateGalleryDialog.title" />
            </Typography>
          </Box>

          <TextField
            label={t('CreateGalleryDialog.galleryTitle')}
            value={form.values.title}
            onChange={form.handleChange}
            id="title"
            name="title"
            fullWidth
            error={form.touched.password && form.errors.password}
            onBlur={form.handleBlur}
          />

          <Box display="flex" justifyContent="flex-end" mt="40px">
            <Box mr="10px">
              <Button variant="contained" onClick={onClose} color="grey">
                <FormattedMessage id="CreateGalleryDialog.cancel" />
              </Button>
            </Box>
            <Button type="submit" variant="contained" color="green" loading={isLoading}>
              <FormattedMessage id="CreateGalleryDialog.create" />
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
}

CreateGalleryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  adherentId: PropTypes.string.isRequired,
};

export default CreateGalleryDialog;
