import React from 'react';
import { Box, Container } from '@material-ui/core';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';
import { object, string, date, array } from '../../utils/yup';
import AppLayout from '../AppLayout';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../v2/TextField';
import Button from '../ui/Button';
import MeetingSelection from './MeetingSelection';

function MeetingForm({ meeting, onSubmit, isLoading }) {
  const { t } = useTranslate();

  const form = useFormik({
    initialValues: {
      name: meeting?.name || '',
      date: meeting?.date || new Date(),
      participants: meeting?.participants || [],
    },
    validationSchema: object().shape({
      name: string().required(),
      date: date().required(),
      participants: array().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      await onSubmit(values);
    },
  });

  const handleParticipants = (participants) => {
    form.setFieldValue('participants', participants);
  };

  return (
    <AppLayout>
      <Container>
        <Box textAlign="center" my="50px">
          <Typography variant="h1">
            <FormattedMessage id="MeetingForm.title" />
          </Typography>
        </Box>
        <form onSubmit={form.handleSubmit}>
          <TextField
            label={t('MeetingForm.name')}
            value={form.values.name}
            onChange={form.handleChange}
            id="name"
            name="name"
            error={form.touched.name && form.errors.name}
            onBlur={form.handleBlur}
            fullWidth
          />
          <Box mt="24px">
            <KeyboardDatePicker
              label={t('MeetingForm.date')}
              format="dd/MM/yyyy"
              ampm={false}
              value={form.values.date}
              cancelLabel={t('MeetingForm.cancel')}
              error={form.touched.date && form.errors.date}
              onChange={(value) => form.setFieldValue('date', value)}
              onBlur={form.handleChange}
              fullWidth
            />
          </Box>
          <MeetingSelection participants={form.values.participants} onChange={handleParticipants} />
          <Box mt="24px">
            <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
              <FormattedMessage id={meeting ? 'MeetingForm.edit' : 'MeetingForm.submit'} />
            </Button>
          </Box>
        </form>
      </Container>
    </AppLayout>
  );
}

MeetingForm.propTypes = {
  meeting: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.string),
  }),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

MeetingForm.defaultProps = {
  meeting: null,
  isLoading: false,
};

export default MeetingForm;
