import { createSelector } from 'reselect';
import memoizeArguments from '../../../utils/memoizeArguments';

export const adherentsItemsSelector = (state) => state.adherents.items;

export const adherentsSelector = createSelector([adherentsItemsSelector], (items) => {
  return [...items].sort((a, b) => a.fullName.toString().localeCompare(b.fullName.toString()));
});

export const visibleAdherentsSelector = createSelector([adherentsSelector], (items) => {
  return items.filter(({ hidden }) => true !== hidden);
});

export const adherentByIdSelector = memoizeArguments((id) => {
  return (state) => state.adherents.items.find((adherent) => adherent.id === id);
});
