import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useTranslate from '../../hooks/useTranslate';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { eventsSelector } from '../../store/ducks/events/eventsSelectors';
import { fetchEventsThunk } from '../../store/ducks/events/eventsThunks';
import Typography from '../../components/ui/Typography';
import useDialog from '../../hooks/useDialog';
import EventDialog from '../../components/Events/EventDialog';

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: '30px',
    padding: '24px',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
  },
  card: {
    marginTop: '12px',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
    cursor: 'pointer',
    padding: '24px',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      flexDirection: 'column',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  date: {
    marginLeft: '5%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
}));

function EventsResult({ word }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const events = useSelector(eventsSelector);
  const classes = useStyle();
  const { open } = useDialog();

  const [isLoading, setLoading] = useState(true);

  const options = {
    keys: ['title', 'description'],
  };

  const fuse = new Fuse(events, options);
  const filteredEvents = fuse.search(word);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await dispatch(fetchEventsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  }, [word]);

  if (isLoading) {
    return (
      <Box textAlign="center" mt="24px">
        <CircularProgress />
      </Box>
    );
  }
  if (0 < filteredEvents.length) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h1">
          <FormattedMessage id="Research.events.title" />
        </Typography>
        {filteredEvents.map((event) => {
          const { title, description, start, id } = event.item;

          return (
            <Box className={classes.card} onClick={() => open(EventDialog, { eventId: id })}>
              <Box className={classes.content}>
                <Typography>{title}</Typography>
                <Typography>{description}</Typography>
              </Box>
              <Box className={[classes.content, classes.date]}>
                <Typography>
                  <FormattedMessage id="WaitingEvent.date" values={{ start }} />
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Paper>
    );
  }
  return null;
}

EventsResult.propTypes = {
  word: PropTypes.string.isRequired,
};

export default EventsResult;
