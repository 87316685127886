/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Dialog, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from './ui/Typography';
import Button from './ui/Button';

function DeleteDialog({ title, onClose, onDelete, isLoading, ...props }) {
  return (
    <Dialog onClose={onClose} maxWidth="md" {...props}>
      <Box px="40px" py="40px">
        <Box mb="40px">
          <Typography variant="h1">{title}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box mr="10px">
            <Button variant="contained" onClick={onClose} color="grey">
              <FormattedMessage id="DeleteDialog.button.cancel" />
            </Button>
          </Box>
          <Button variant="contained" onClick={onDelete} color="orange" loading={isLoading}>
            <FormattedMessage id="DeleteDialog.button.confirm" />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

DeleteDialog.defaultProps = {
  isLoading: false,
};
export default DeleteDialog;
