import { Box, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';
import ScheduleFormRow from './ScheduleFormRow';
import useTranslate from '../../hooks/useTranslate';
import DAYS from '../../constants/Days';

const useStyles = makeStyles({
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    marginBottom: '20px',
  },
});

function ScheduleForm({ form }) {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <Box mt="85px">
      <Typography className={classes.label}>
        <FormattedMessage id="AdherentForm.schedule.label" />
      </Typography>
      {Object.keys(DAYS).map((day) => (
        <ScheduleFormRow
          key={day}
          label={t('Constants.days', { day })}
          value={form.values.schedule?.[day]}
          onChange={(e) => form.setFieldValue(`schedule.${day}`, e.target.value)}
        />
      ))}
    </Box>
  );
}

ScheduleForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      schedule: PropTypes.shape({
        monday: PropTypes.string,
        tuesday: PropTypes.string,
        wednesday: PropTypes.string,
        thursday: PropTypes.string,
        friday: PropTypes.string,
        saturday: PropTypes.string,
        sunday: PropTypes.string,
      }),
    }),
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default ScheduleForm;
