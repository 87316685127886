import React from 'react';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import FacebookIcon from './icons/FacebookIcon';
import PhoneIcon from './icons/PhoneIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  link: {
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '2.9rem',
    color: theme.palette.grey[400],
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
  },
}));

function Footer() {
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <Box className={classes.container}>
      {/*      <Box margin="5px">
        <Link to="/mentions" className={classes.link}>
          <FormattedMessage id="Footer.cgu" />
        </Link>
      </Box>
      <Box margin="5px">
        <Link to="/privacyPolicy" className={classes.link}>
          <FormattedMessage id="Footer.privacyPolicy" />
        </Link>
      </Box> */}
      <Box margin="5px" className={classes.icons}>
        <Box onClick={() => handleClick('https://chat.whatsapp.com/KrpVp8MCCMe4YREgDq3S8K')} className={classes.button}>
          <PhoneIcon color={theme.palette.grey[400]} />
        </Box>
        <Box ml="15px">
          <Box onClick={() => handleClick('https://www.facebook.com/groups/450998391768844')} className={classes.button}>
            <FacebookIcon color={theme.palette.grey[400]} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
