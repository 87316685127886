/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Dialog, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import { sendContactThunk } from '../../store/ducks/adherents/adherentsThunk';
import { adherentByIdSelector } from '../../store/ducks/adherents/adherentsSelectors';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../v2/TextField';
import isEmail from '../../utils/isEmail';

function SendContactDialog({ open, onClose, adherentId, ...props }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const adherent = useSelector(adherentByIdSelector(adherentId));

  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const validate = (value) => {
    if ('object' === typeof value) {
      // eslint-disable-next-line no-param-reassign
      value = value.target.value;
    }

    setInvalid(!value || !isEmail(value));
  };

  const handleChange = (e) => {
    if ('message' === e.target.name) {
      setMessage(e.target.value);
    } else {
      setEmail(e.target.value);

      if (invalid) {
        validate(e.target.value);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(sendContactThunk(adherentId, message, email));
      toast.success(t('SendContactDialog.success'));
      onClose();
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" {...props}>
      <Box px="40px" py="40px">
        <Box mb="40px">
          <Typography variant="h1">
            <FormattedMessage id="SendContactDialog.title" values={{ name: adherent.fullName }} />
          </Typography>
        </Box>

        <TextField
          label={t('SendContactDialog.email')}
          value={email}
          onChange={handleChange}
          onBlur={validate}
          id="email"
          name="email"
          error={invalid ? t('Errors.validation.email') : undefined}
          fullWidth
        />

        <Box mt="20px" mb="40px">
          <TextField label={t('SendContactDialog.message')} value={message} onChange={handleChange} id="message" name="message" rows={3} multiline fullWidth />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box mr="10px">
            <Button variant="contained" onClick={onClose} color="grey">
              <FormattedMessage id="SendContactDialog.cancel" />
            </Button>
          </Box>
          <Button variant="contained" onClick={handleSubmit} color="green" loading={isLoading}>
            <FormattedMessage id="SendContactDialog.send" />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

SendContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  adherentId: PropTypes.string.isRequired,
};

export default SendContactDialog;
