import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import Typography from '../components/ui/Typography';
import Logo from '../assets/logo.png';
import Button from '../components/ui/Button';

const useStyles = makeStyles({
  image: {
    width: '100%',
    maxWidth: '500px',
    marginTop: '20px',
  },
});

function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.goBack();
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column">
        <img alt="Esprit d'Affaires" src={Logo} className={classes.image} />
        <Typography variant="h1">
          <FormattedMessage id="NotFound.title" />
        </Typography>
        <Box mt="100px">
          <Button variant="contained" onClick={onClick}>
            <FormattedMessage id="NotFound.back" />
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default NotFound;
