/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { Box, fade, Grid, IconButton, makeStyles, Paper, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay, setHours, setMinutes, isFuture } from 'date-fns';
import locale from 'date-fns/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import { fetchEventsThunk } from '../../store/ducks/events/eventsThunks';
import { eventsSelector } from '../../store/ducks/events/eventsSelectors';
import EVENT_COLOR from '../../constants/EventColor';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import WaitingEvent from '../../components/Events/WaitingEvent';
import useDialog from '../../hooks/useDialog';
import EventDialog from '../../components/Events/EventDialog';
import PlusIcon from '../../components/icons/PlusIcon';
import useTranslate from '../../hooks/useTranslate';

const useStyle = makeStyles((theme) => ({
  eventsWaiting: {
    padding: '20px',
    paddingBottom: '80px',
    position: 'relative',
  },
  add: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

const locales = { fr: locale };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const minTime = setHours(setMinutes(new Date(), 0), 6);
const maxTime = setHours(setMinutes(new Date(), 0), 22);

function Events() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslate();
  const { open } = useDialog();

  const events = useSelector(eventsSelector);
  const user = useSelector(userSelector);

  const eventsFormatted = events.map((event) => ({
    ...event,
    participate: event.participations?.find((participation) => participation.userId === user.id)?.participate || null,
  }));

  // TODO Isolate participations in other collection
  const waitingEvents = events.filter((event) => isFuture(event.start) && !event.participations?.some((participation) => participation.userId === user.id));

  useEffect(() => {
    dispatch(fetchEventsThunk());
  }, [dispatch]);

  const handleClick = (event) => {
    open(EventDialog, { eventId: event.id });
  };

  return (
    <AppLayout>
      <Box mt="60px">
        <Typography variant="h1" align="center" color="primary.main">
          <FormattedMessage id="Events.title" />
        </Typography>
        <Box mt="60px">
          <Grid container spacing={2}>
            <Grid item lg={9} xs={12}>
              <Box overflow="scroll">
                <Box height="700px" minWidth="700px">
                  <Calendar
                    events={eventsFormatted}
                    localizer={localizer}
                    culture="fr"
                    min={minTime}
                    max={maxTime}
                    messages={{
                      allDay: t('Events.calendar.allDay'),
                      previous: t('Events.calendar.previous'),
                      next: t('Events.calendar.next'),
                      today: t('Events.calendar.today'),
                      month: t('Events.calendar.month'),
                      week: t('Events.calendar.week'),
                      day: t('Events.calendar.day'),
                      agenda: t('Events.calendar.agenda'),
                      date: t('Events.calendar.date'),
                      time: t('Events.calendar.time'),
                      event: t('Events.calendar.event'),
                      showMore: (count) => t('Events.calendar.showMore', { count }),
                    }}
                    step={30}
                    onSelectEvent={handleClick}
                    eventPropGetter={(event) => {
                      return {
                        style: {
                          backgroundColor: fade(EVENT_COLOR[event.type], event.participate ? 1 : 0.2),
                          color: theme.palette.common.black,
                        },
                      };
                    }}
                    views={[Views.MONTH, Views.WEEK]}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Paper className={classes.eventsWaiting}>
                <Typography variant="h2">
                  <FormattedMessage id="Events.waitingEvents" />
                </Typography>
                <Box mt="15px">
                  {0 === waitingEvents.length && <FormattedMessage id="Events.noWaitingEvents" />}
                  {waitingEvents.map((event) => (
                    <WaitingEvent event={event} onClick={() => handleClick(event)} />
                  ))}
                </Box>
                <IconButton className={classes.add} color="primary" component={Link} to="/evenements/ajouter">
                  <PlusIcon color="white" />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AppLayout>
  );
}

export default Events;
