import React, { useEffect } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import GlobalAttendances from './GlobalAttendances';
import Meetings from './Meetings';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import { meetingsSelector } from '../../store/ducks/meetings/meetingsSelectors';
import { getMeetingsThunk } from '../../store/ducks/meetings/meetingsThunk';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function Attendances() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const adherents = useSelector(visibleAdherentsSelector);
  const meetings = useSelector(meetingsSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(getAdherentsThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMeetingsThunk());
  }, [dispatch]);

  return (
    <AppLayout>
      <Box mt="52px" textAlign="center">
        <Typography variant="h1" color={theme.palette.primary.main}>
          <FormattedMessage id="Attendances.title" />
        </Typography>
      </Box>
      <Box mt="50px">
        <GlobalAttendances adherents={adherents} meetings={meetings} />
      </Box>
      {user.isAdmin && (
        <Box mt="34px">
          <Meetings meetings={meetings} />
        </Box>
      )}
    </AppLayout>
  );
}

export default Attendances;
