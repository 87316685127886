import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { toast } from 'react-hot-toast';
import { meetingByIdSelector } from '../../store/ducks/meetings/meetingsSelectors';
import MeetingForm from '../../components/Attendances/MeetingForm';
import { updateMeetingThunk } from '../../store/ducks/meetings/meetingsThunk';
import CenteredCircularProgress from '../../components/ui/CenteredCircularProgress';
import useTranslate from '../../hooks/useTranslate';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function EditMeeting() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslate();

  const { id } = params;

  const [isLoading, setLoading] = useState(false);

  const meeting = useSelector(meetingByIdSelector(id));
  const user = useSelector(userSelector);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(updateMeetingThunk({ id, data: values }));

      toast.success(t('MeetingForm.updated'));
      history.push(`/presences/statistiques`);
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  if (!user.isAdmin) {
    return <Redirect to="/" />;
  }

  return meeting ? <MeetingForm meeting={meeting} onSubmit={handleSubmit} isLoading={isLoading} /> : <CenteredCircularProgress />;
}

export default EditMeeting;
