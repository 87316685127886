import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import locale from 'date-fns/locale/fr';
import PrivateRoute from './components/PrivateRoute';
import theme from './theme';
import Login from './pages/Login';
import translations from './translations';
import Adherents from './pages/Adherents/Adherents';
import store from './store/store';
import Adherent from './pages/Adherents/Adherent';
import EditAdherent from './pages/Adherents/EditAdherent';
import NotFound from './pages/NotFound';
import Initialize from './pages/Initialize';
import Users from './pages/Users/Users';
import CreateUser from './pages/Users/CreateUser';
import EditUser from './pages/Users/EditUser';
import SendEmail from './pages/SendEmail';
import DialogProvider from './components/DialogProvider';
import Events from './pages/Events/Events';
import CreateEvent from './pages/Events/CreateEvent';
import EditEvent from './pages/Events/EditEvent';
import CreateBusiness from './pages/Business/CreateBusiness';
import Attendances from './pages/Attendances/Attendances';
import EditMeeting from './pages/Attendances/EditMeeting';
import CreateMeeting from './pages/Attendances/CreateMeeting';
import News from './pages/News/News';
import Gallery from './pages/News/Gallery';
import MyProfile from './pages/MyProfil/MyProfile';
import Research from './pages/Research/Research';
import Business from './pages/Business/Business';
import EditBusiness from './pages/Business/EditBusiness';

function App() {
  return (
    <ReduxProvider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IntlProvider locale="fr" defaultLocale="en" messages={flatten(translations.fr)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <Toaster position="top-right" />
              <DialogProvider />
              <Switch>
                <Route path="/initialisation">
                  <Initialize />
                </Route>
                <PrivateRoute exact path="/">
                  <Adherents />
                </PrivateRoute>
                <Route path="/connexion">
                  <Login />
                </Route>
                <PrivateRoute exact path="/adherents/:id/editer">
                  <EditAdherent />
                </PrivateRoute>
                <PrivateRoute path="/adherents/:id">
                  <Adherent />
                </PrivateRoute>
                <PrivateRoute path="/utilisateurs/:id/editer">
                  <EditUser />
                </PrivateRoute>
                <PrivateRoute path="/utilisateurs/ajouter">
                  <CreateUser />
                </PrivateRoute>
                <PrivateRoute path="/utilisateurs">
                  <Users />
                </PrivateRoute>
                <PrivateRoute path="/envoyer-un-email">
                  <SendEmail />
                </PrivateRoute>
                <PrivateRoute path="/evenements/ajouter">
                  <CreateEvent />
                </PrivateRoute>
                <PrivateRoute path="/evenements/:id/editer">
                  <EditEvent />
                </PrivateRoute>
                <PrivateRoute path="/evenements">
                  <Events />
                </PrivateRoute>
                <PrivateRoute path="/affaires/ajouter">
                  <CreateBusiness />
                </PrivateRoute>
                <PrivateRoute path="/affaires/:id/editer">
                  <EditBusiness />
                </PrivateRoute>
                <PrivateRoute path="/affaires/statistiques">
                  <Business />
                </PrivateRoute>
                <PrivateRoute path="/presences/ajouter">
                  <CreateMeeting />
                </PrivateRoute>
                <PrivateRoute path="/presences/statistiques">
                  <Attendances />
                </PrivateRoute>
                <PrivateRoute path="/presences/:id/editer">
                  <EditMeeting />
                </PrivateRoute>
                <PrivateRoute path="/actualites">
                  <News />
                </PrivateRoute>
                <PrivateRoute path="/galerie/:id">
                  <Gallery />
                </PrivateRoute>
                <PrivateRoute path="/mon-profil">
                  <MyProfile />
                </PrivateRoute>
                <PrivateRoute path="/recherche/:word">
                  <Research />
                </PrivateRoute>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </MuiPickersUtilsProvider>
          </IntlProvider>
        </ThemeProvider>
      </Router>
    </ReduxProvider>
  );
}

export default App;
