import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '80px',
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc( 100vh - 247px)',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc( 100vh  - 152px)',
    },
  },
}));

function AppLayout({ children }) {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Box className={classes.mainContainer}>
        <Container>{children}</Container>
      </Box>
      <Footer />
    </>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
