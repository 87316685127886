import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function BurgerMenuIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 24 18" fill={color}>
      <rect y="14" width="24" height="3" />
      <rect y="7" width="24" height="3" />
      <rect width="24" height="3" />
    </SvgIcon>
  );
}

BurgerMenuIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

BurgerMenuIcon.defaultProps = {
  size: 'default',
  color: 'black',
  className: '',
};

export default BurgerMenuIcon;
