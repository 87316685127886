import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Typography from './ui/Typography';
import CurvedArrow from './icons/CurvedArrow';
import HeartIcon from './icons/HeartIcon';
import MailPlaneIcon from './icons/MailPlaneIcon';
import theme from '../theme';
import useDialog from '../hooks/useDialog';
import SendContactDialog from './Adherent/SendContactDialog';
import { userSelector } from '../store/ducks/auth/authSelectors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '272px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
    cursor: 'pointer',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  lastName: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  companyLogoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    maxHeight: '100px',
    maxWidth: '170px',
  },
  button: {
    color: '#B4B4B4',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  actions: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    top: '-12px',
    right: '-8px',
  },
});

function AdherentCard({ adherent }) {
  const classes = useStyles();
  const { open } = useDialog();
  const history = useHistory();
  const user = useSelector(userSelector);

  const { id } = adherent;

  const handleSendContact = (e) => {
    e.preventDefault();
    e.stopPropagation();

    open(SendContactDialog, { adherentId: id });
  };

  const handleClick = (to) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(to);
    };
  };

  return (
    <Box className={classes.root} onClick={() => history.push(`/adherents/${id}`)}>
      <Box padding="20px" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box className={classes.topContainer}>
          <Box>
            <Typography>
              <span className={classes.lastName}>{adherent.lastName}</span> {adherent.firstName}
            </Typography>
            <Typography>{adherent.companyName}</Typography>
          </Box>
          <Box className={classes.actions}>
            <IconButton onClick={handleSendContact} className={classes.button}>
              <CurvedArrow color="currentColor" />
            </IconButton>
            {adherent.id !== user.id && (
              <>
                <Box ml="8px">
                  <IconButton onClick={handleClick(`/affaires/ajouter?adherentId=${adherent.id}`)} className={classes.button}>
                    <HeartIcon color="currentColor" />
                  </IconButton>
                </Box>
                <Box ml="8px">
                  <IconButton onClick={handleClick(`/envoyer-un-email?adherentId=${adherent.id}`)} className={classes.button}>
                    <MailPlaneIcon color="currentColor" />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.companyLogoContainer}>
          <img alt={adherent.companyName} src={adherent?.companyLogo} height="auto" className={classes.logo} />
        </Box>
        <Box>
          <Typography>{adherent.phone}</Typography>
          <Typography>{adherent.secondaryPhone}</Typography>
          <Typography>{adherent.email}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

AdherentCard.propTypes = {
  adherent: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    companyLogo: PropTypes.string,
    companyName: PropTypes.string,
    phone: PropTypes.string,
    secondaryPhone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default AdherentCard;
