export default {
  days: `{day, select, 
    monday {Lundi}
    tuesday {Mardi}
    wednesday {Mercredi}
    thursday {Jeudi}
    friday {Vendredi}
    saturday {Samedi}
    sunday {Dimanche}
    other {Lundi}
  }`,
  EventType: `{value, select, 
    MEETING {Réunion}
    OUTING {Sortie}
    COMPANY_VISIT {Visite entreprise}
    other {Autres}
  }`,
  BusinessType: `{value, select, 
    BUSINESS {Merci Business}
    RECOMMENDATION {Recommandation}
    HEADTOHEAD {Tête à tête}
    other {Recommandation}
  }`,
};
