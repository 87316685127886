import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BusinessForm from '../../components/Business/BusinessForm';
import { editBusinessThunk, getBusinessesThunk } from '../../store/ducks/business/businessThunks';
import useTranslate from '../../hooks/useTranslate';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import { businessesSelector } from '../../store/ducks/business/businessSelectors';

function EditBusiness() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const history = useHistory();
  const params = useParams();

  const [isLoading, setLoading] = useState(false);
  const businesses = useSelector(businessesSelector);

  useEffect(() => dispatch(getBusinessesThunk()), [dispatch]);

  const businessToEdit = businesses.find((business) => business.id === params.id);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(editBusinessThunk(params.id, values));

      toast.success(t('EditBusiness.success'));
      history.push({
        pathname: '/affaires/statistiques',
        state: { refresh: true },
      });
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <Box mt="60px">
        <Typography variant="h1" align="center" color="primary.main">
          <FormattedMessage id="EditBusiness.title" />
        </Typography>
        <Box mt="40px" mb="20px">
          <Typography variant="h2">
            <FormattedMessage id="EditBusiness.subtitle" />
          </Typography>
        </Box>
        <BusinessForm onSubmit={handleSubmit} isLoading={isLoading} business={businessToEdit} />
      </Box>
    </AppLayout>
  );
}

export default EditBusiness;
