export const AUTH_ACTIONS = {
  setLoading: 'AUTH/SET_LOADING',
  logged: 'AUTH/LOGGED',
  clear: 'AUTH/CLEAR',
  setUser: 'AUTH/SET_USER',
};

const initialState = {
  loading: false,
  // When is undefined, auth hasn't been initialized
  isLogged: undefined,
  user: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_ACTIONS.setLoading:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case AUTH_ACTIONS.logged:
      return {
        ...state,
        isLogged: true,
      };
    case AUTH_ACTIONS.setUser:
      return {
        ...state,
        user: action.payload.user,
      };
    case AUTH_ACTIONS.clear:
      return {
        ...initialState,
        isLogged: false,
      };
    default: {
      return state;
    }
  }
}

export default authReducer;
