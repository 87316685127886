import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { updateUserThunk } from '../../store/ducks/adherents/usersThunks';
import UserForm from '../../components/User/UserForm';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { adherentByIdSelector } from '../../store/ducks/adherents/adherentsSelectors';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function EditUser() {
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector(adherentByIdSelector(params.id));
  const currentUser = useSelector(userSelector);

  useEffect(() => {
    dispatch(getAdherentsThunk());
  }, [dispatch, params.id]);

  if (!currentUser.isAdmin) {
    return <Redirect to="/" />;
  }

  return <UserForm user={user} onSubmit={updateUserThunk} />;
}

export default EditUser;
