/**
 * This function is an util to create a chainable propType.
 * Basically, it takes a validation function as a parameter and returns the same function with isRequired as a child function (like JS prototype).
 *
 * @see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Object/prototype
 * @see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Function/bind
 * @param {Function} validate
 * @returns {Function}
 */
const createChainablePropType = (validate) => {
  const checkType = (isRequired, props, propName, componentName) => {
    if ([null, undefined].includes(props[propName]) && isRequired) {
      return new Error(`Required  \`${propName}\`was not specified in \`${componentName || 'ANONYMOUS'}\`.`);
    }

    return validate(props, propName, componentName);
  };

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
};

export default createChainablePropType;
