import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import Logo from '../assets/logo.png';
import Typography from '../components/ui/Typography';
import Button from '../components/ui/Button';
import TextField from '../components/v2/TextField';
import useTranslate from '../hooks/useTranslate';
import { object, string } from '../utils/yup';
import { loginThunk } from '../store/ducks/auth/authThunks';

const useStyles = makeStyles({
  image: {
    width: '100%',
    maxWidth: '500px',
    marginTop: '20px',
  },
});

function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslate();
  const history = useHistory();
  // TODO Delete test Account when dev will be over

  const [isLoading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: object().shape({
      email: string().email().required(),
      password: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(loginThunk(values.email, values.password));

        history.push('/');
      } catch (error) {
        toast.error(t('Login.incorrectId'));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box textAlign="center">
      <form onSubmit={form.handleSubmit}>
        <img alt="Esprit d'Affaires" src={Logo} className={classes.image} />
        <Box maxWidth="282px" mx="auto">
          <Typography variant="h1">
            <FormattedMessage id="Login.title" />
          </Typography>
          <Box maxWidth="300px" mt="85px">
            <TextField
              label={t('Login.user.label')}
              placeholder={t('Login.user.placeholder')}
              value={form.values.email}
              onChange={form.handleChange}
              id="email"
              name="email"
              error={form.touched.email && form.errors.email}
              onBlur={form.handleBlur}
              type="email"
              fullWidth
            />
          </Box>
          <Box mt="55px">
            <TextField
              label={t('Login.password.label')}
              placeholder={t('Login.password.placeholder')}
              type="password"
              value={form.values.password}
              onChange={form.handleChange}
              id="password"
              name="password"
              error={form.touched.password && form.errors.password}
              onBlur={form.handleBlur}
              fullWidth
            />
          </Box>
        </Box>
        <Box maxWidth="280px" mx="auto">
          <Box mt="45px" mb="100px">
            <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
              <FormattedMessage id="Login.connexion" />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
