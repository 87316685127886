import { Box, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';
import SocialNetworkFormRow from './SocialNetworkFormRow';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles({
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    marginBottom: '20px',
  },
});

function SocialNetworksForm({ form }) {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <Box mt="85px">
      <Typography className={classes.label}>
        <FormattedMessage id="AdherentForm.socialNetworks.label" />
      </Typography>
      <SocialNetworkFormRow
        label={t('AdherentForm.socialNetworks.socialNetwork', { socialNetwork: 'instagram' })}
        placeholder={t('AdherentForm.socialNetworks.placeholder', { socialNetwork: 'instagram' })}
        value={form.values.socialNetworks?.instagram}
        onChange={(e) => form.setFieldValue('socialNetworks.instagram', e.target.value)}
      />
      <SocialNetworkFormRow
        label={t('AdherentForm.socialNetworks.socialNetwork', { socialNetwork: 'facebook' })}
        placeholder={t('AdherentForm.socialNetworks.placeholder', { socialNetwork: 'facebook' })}
        value={form.values.socialNetworks?.facebook}
        onChange={(e) => form.setFieldValue('socialNetworks.facebook', e.target.value)}
      />
      <SocialNetworkFormRow
        label={t('AdherentForm.socialNetworks.socialNetwork', { socialNetwork: 'linkedIn' })}
        placeholder={t('AdherentForm.socialNetworks.placeholder', { socialNetwork: 'linkedIn' })}
        value={form.values.socialNetworks?.linkedIn}
        onChange={(e) => form.setFieldValue('socialNetworks.linkedIn', e.target.value)}
      />
      <SocialNetworkFormRow
        label={t('AdherentForm.socialNetworks.socialNetwork', { socialNetwork: 'website' })}
        placeholder={t('AdherentForm.socialNetworks.placeholder', { socialNetwork: 'website' })}
        value={form.values.socialNetworks?.website}
        onChange={(e) => form.setFieldValue('socialNetworks.website', e.target.value)}
      />
    </Box>
  );
}

SocialNetworksForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      socialNetworks: PropTypes.shape({
        instagram: PropTypes.string,
        facebook: PropTypes.string,
        linkedIn: PropTypes.string,
        website: PropTypes.string,
      }),
    }),
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default SocialNetworksForm;
