import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../v2/TextField';

function Discounts({ form }) {
  const { t } = useTranslate();

  return (
    <TextField
      label={t('AdherentForm.discounts.label')}
      placeholder={t('AdherentForm.discounts.placeholder')}
      value={form.values.discounts}
      onChange={form.handleChange}
      id="discounts"
      name="discounts"
      error={form.touched.discounts && form.errors.discounts}
      onBlur={form.handleBlur}
      multiline
      fullWidth
    />
  );
}

Discounts.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      discounts: PropTypes.string,
    }),
    setFieldValue: PropTypes.func,
    touched: PropTypes.shape({
      discounts: PropTypes.bool,
    }),
    errors: PropTypes.shape({
      discounts: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
  }).isRequired,
};

export default Discounts;
