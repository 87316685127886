/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { makeStyles, Typography as MuiTypography, useTheme } from '@material-ui/core';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const MUI_VARIANTS = ['body2', 'body1', 'button', 'h1', 'h2', 'subtitle1', 'subtitle2'];
const MUI_COLORS = ['initial', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error'];

const useStyles = makeStyles(
  ({ typography }) => {
    const styles = {
      multiline: {
        'white-space': 'pre-wrap',
      },
    };

    // Here we loop on typography variants to create those that do not exist in MUI.
    Object.keys(typography).forEach((key) => {
      if ('object' === typeof typography[key] && !MUI_VARIANTS.includes(key)) {
        styles[key] = typography[key];
      }
    });

    return styles;
  },
  { withTheme: true, name: 'EaTypography' }
);

function Typography({ variant, color, className, multiline, component: passedComponent, paragraph, ...props }) {
  const classes = useStyles();
  const theme = useTheme();

  const isMuiVariant = !variant || MUI_VARIANTS.includes(variant);
  const isMuiColor = !color || MUI_COLORS.includes(color);
  const component = passedComponent || (paragraph ? 'p' : theme.props.MuiTypography.variantMapping[variant]) || 'span';

  let dynamicStyle;
  if (!isMuiColor) {
    dynamicStyle = { color: theme.palette.common[color] || theme.palette?.[color]?.main || get(theme.palette, color, color) };
  }

  return (
    <MuiTypography
      className={clsx(isMuiVariant || classes[variant], multiline && classes.multiline, className)}
      style={dynamicStyle}
      variant={isMuiVariant ? variant : undefined}
      color={isMuiColor ? color : undefined}
      component={component}
      {...props}
    />
  );
}

Typography.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  multiline: PropTypes.bool,
  component: PropTypes.string,
  paragraph: PropTypes.bool,
};

Typography.defaultProps = {
  variant: 'body1',
  color: 'initial',
  className: '',
  multiline: false,
  component: undefined,
  paragraph: false,
};

export default Typography;
