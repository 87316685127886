import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '../ui/Typography';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  informationsContainer: {
    width: '250px',
  },
}));

function ScheduleRow({ day, dayValue }) {
  const classes = useStyles();

  return (
    <Box mt="20px" display="flex" className={classes.mainContainer}>
      <Box className={classes.informationsContainer}>
        <Typography>
          <FormattedMessage id="Constants.days" values={{ day }} />
        </Typography>
      </Box>
      <Box className={classes.informationsContainer}>{dayValue ? <Typography>{dayValue}</Typography> : <Typography>-</Typography>}</Box>
    </Box>
  );
}

ScheduleRow.propTypes = {
  dayValue: PropTypes.string,
  day: PropTypes.string.isRequired,
};

ScheduleRow.defaultProps = {
  dayValue: null,
};

export default ScheduleRow;
