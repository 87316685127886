import { ADHERENTS_ACTIONS } from './adherentsReducer';
import firebase, { firestore } from '../../../firebase';
import { userSelector } from '../auth/authSelectors';
import uploadFile from '../../../utils/uploadFile';
import snapshotToEntity from '../../../utils/snapshotToEntity';
import { AUTH_ACTIONS } from '../auth/authReducer';

// Actions creators
export const getAdherentsThunk = () => async (dispatch) => {
  const booksRef = await firestore.collection('adherents');
  booksRef.get().then((snapshot) => {
    const data = snapshot.docs.map((doc) => {
      const output = snapshotToEntity(doc);
      output.fullName = [output.lastName.toUpperCase(), output.firstName].join(' ');

      return output;
    });

    dispatch({
      type: ADHERENTS_ACTIONS.set,
      payload: { adherents: data },
    });
  });
};

export const updateAdherentsThunk = ({ id, data }) => async (dispatch, getState) => {
  let memberPhotoLink;
  let companyLogoLink;

  const adherentData = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    companyName: data?.companyName,
    email: data?.email,
    phone: data?.phone,
    secondaryPhone: data?.secondaryPhone,
    memberPhoto: null == data?.memberPhoto ? '' : data?.memberPhoto,
    companyLogo: null == data?.companyLogo ? '' : data?.companyLogo,
    address: data?.address,
    lat: data?.lat,
    lng: data?.lng,
    schedule: null == data?.schedule ? {} : data?.schedule,
    products: data?.products,
    discounts: data?.discounts,
    socialNetworks: null == data?.socialNetworks ? {} : data?.socialNetworks,
  };

  if ('string' !== typeof data.memberPhoto && !!data.memberPhoto) {
    const memberPhotoUpload = await uploadFile(data.memberPhoto, 'memberPhoto');
    memberPhotoLink = await memberPhotoUpload.snap.ref.getDownloadURL();
  }

  adherentData.memberPhoto = 'string' !== typeof data.memberPhoto ? memberPhotoLink : data.memberPhoto;
  if ('string' !== typeof data.companyLogo && !!data.companyLogo) {
    const companyLogoUpload = await uploadFile(data.companyLogo, 'companyLogo');
    companyLogoLink = await companyLogoUpload.snap.ref.getDownloadURL();
  }
  adherentData.companyLogo = 'string' !== typeof data.companyLogo ? companyLogoLink : data.companyLogo;
  if (id) {
    await firestore.collection('adherents').doc(id).update(adherentData);

    const user = userSelector(getState());
    if (id === user.id) {
      dispatch({
        type: AUTH_ACTIONS.setUser,
        payload: { user: { ...user, ...adherentData } },
      });
    }
  }

  await dispatch(getAdherentsThunk());
};

export const sendContactThunk = (id, message, email) => {
  return async (dispatch, getState) => {
    const user = userSelector(getState());

    const sendContact = firebase.functions().httpsCallable('httpsSendContact');
    await sendContact({
      id,
      message,
      email,
      sender: user,
    });
  };
};
