import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function MailPlaneIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 20.87 20">
      <path d="M8.75,17.612v5.041a.815.815,0,0,0,1.472.483l2.949-4.013Z" transform="translate(-1.529 -3.469)" fill={color} />
      <path
        d="M20.6.116a.674.674,0,0,0-.68-.045L.35,9.905a.625.625,0,0,0-.349.6.633.633,0,0,0,.439.547L5.88,12.846,17.463,3.313,8.5,13.707l9.116,3a.691.691,0,0,0,.21.033.674.674,0,0,0,.339-.091.63.63,0,0,0,.306-.444L20.863.721a.619.619,0,0,0-.267-.6Z"
        transform="translate(0 0)"
        fill={color}
      />
    </SvgIcon>
  );
}

MailPlaneIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

MailPlaneIcon.defaultProps = {
  size: '23px',
  color: '#B4B4B4',
  className: '',
};

export default MailPlaneIcon;
