import Header from './Header.json';
import UploadDropZone from './UploadDropZone.json';
import GoogleMaps from './GoogleMaps.json';
import DeleteDialog from './DeleteDialog.json';
import Footer from './Footer.json';
import UserCard from './UserCard.json';
import SendContactDialog from './SendContactDialog.json';
import EventForm from './EventForm.json';
import WaitingEvent from './WaitingEvent.json';
import EventDialog from './EventDialog.json';
import BusinessForm from './BusinessForm.json';
import GlobalAttendances from './GlobalAttendances.json';
import Meetings from './Meetings.json';
import MeetingForm from './MeetingForm.json';
import NewsPopover from './NewsPopover.json';
import Galleries from './Galleries.json';
import CreateGalleryDialog from './CreateGalleryDialog.json';
import GalleryPreview from './GalleryPreview.json';
import BusinessOverview from './BusinessOverview.json';

export default {
  Header,
  UploadDropZone,
  GoogleMaps,
  DeleteDialog,
  Footer,
  UserCard,
  SendContactDialog,
  EventForm,
  WaitingEvent,
  EventDialog,
  BusinessForm,
  GlobalAttendances,
  Meetings,
  MeetingForm,
  NewsPopover,
  Galleries,
  CreateGalleryDialog,
  GalleryPreview,
  BusinessOverview,
};
