import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';

const useStyles = makeStyles((theme) => ({
  lastName: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  mainContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
  informationsContainer: {
    width: '250px',
    marginTop: '25px',
  },
}));

function ContactInformations({ adherent }) {
  const classes = useStyles();

  return (
    <Grid item lg={6} xs={12}>
      <Box display="flex" className={classes.mainContainer}>
        <Box mt="25px" className={classes.informationsContainer}>
          <Typography variant="h2">
            <FormattedMessage id="Adherent.profil" />
          </Typography>
          <Box mt="20px">
            <Typography>
              <span className={classes.lastName}>{adherent?.lastName}</span> {adherent?.firstName}
            </Typography>
            <Typography>{adherent?.companyName}</Typography>
          </Box>
        </Box>
        <Box className={classes.informationsContainer} mt="25px">
          <Typography variant="h2">
            <FormattedMessage id="Adherent.address" />
          </Typography>
          <Box mt="20px">
            <Typography>{adherent?.address}</Typography>
            {!adherent?.address && <Typography>-</Typography>}
          </Box>
        </Box>
      </Box>
      <Box display="flex" className={classes.mainContainer}>
        <Box className={classes.informationsContainer} mt="25px">
          <Typography variant="h2">
            <FormattedMessage id="Adherent.phones" />
          </Typography>
          <Box mt="20px">
            <Typography>{adherent?.phone}</Typography>
            {!adherent?.phone && <Typography>-</Typography>}
          </Box>
          <Box mt="10px">
            <Typography>{adherent?.secondaryPhone}</Typography>
            {!adherent?.secondaryPhone && <Typography>-</Typography>}
          </Box>
        </Box>
        <Box className={classes.informationsContainer} mt="25px">
          <Typography variant="h2">
            <FormattedMessage id="Adherent.email" />
          </Typography>
          <Box mt="20px">
            <Typography>{adherent?.email}</Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

ContactInformations.propTypes = {
  adherent: PropTypes.shape({
    address: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    secondaryPhone: PropTypes.string,
    companyName: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
  }),
};

ContactInformations.defaultProps = {
  adherent: null,
};

export default ContactInformations;
