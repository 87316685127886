import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import Button from '../ui/Button';

function MeetingSelection({ participants, onChange }) {
  const dispatch = useDispatch();
  const adherents = useSelector(visibleAdherentsSelector);

  useEffect(() => dispatch(getAdherentsThunk()), [dispatch]);

  const selectAll = () => {
    if (!(participants.length >= adherents.length)) {
      const allParticipants = adherents.map((adherent) => adherent.id);
      onChange(allParticipants);
    } else {
      onChange([]);
    }
  };

  const handleChange = (event, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const list = [...participants, id];
      onChange(list);
    } else {
      const filteredList = participants.filter((participant) => participant !== id);
      onChange(filteredList);
    }
  };

  return (
    <Box mt="30px">
      <Button variant="contained" onClick={selectAll}>
        <FormattedMessage id="MeetingForm.all" />
      </Button>
      {adherents.map((adherent) => {
        const isChecked = participants.some((participant) => participant === adherent.id);

        return (
          <Box display="flex" alignItems="center" key={adherent.id}>
            <FormControlLabel control={<Checkbox color="primary" checked={isChecked} onClick={(e) => handleChange(e, adherent.id)} />} label={adherent.fullName} />
          </Box>
        );
      })}
    </Box>
  );
}

MeetingSelection.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MeetingSelection;
