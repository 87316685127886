import firebase from '../../firebase';
import uploadFile from '../../utils/uploadFile';

// eslint-disable-next-line import/prefer-default-export
export const sendEmailThunk = (data) => {
  return async () => {
    const { attachment, ...values } = data;
    let attachmentUrl;
    if (attachment) {
      const attachmentSnap = await uploadFile(attachment, 'email-attachments');
      attachmentUrl = await attachmentSnap.snap.ref.getDownloadURL();
    }

    const sendEmail = firebase.functions().httpsCallable('httpsSendEmail');
    await sendEmail({
      ...values,
      attachment: attachmentUrl,
    });
  };
};
