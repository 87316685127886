import { firestore } from '../../../firebase';
import uploadFile from '../../../utils/uploadFile';
import snapshotToEntity from '../../../utils/snapshotToEntity';
import { NEWS_ACTIONS } from './newsReducer';

// Documents
export const uploadDocumentThunk = ({ file, userId }) => {
  return async (dispatch) => {
    let fileUrl;
    let addNews;

    if (file) {
      const { snap, name } = await uploadFile(file, 'documents');

      fileUrl = await snap.ref.getDownloadURL();

      addNews = await firestore.collection('documents').add({ title: file.name, url: fileUrl, fileName: name, type: file.type, userId });
    }

    dispatch({
      type: NEWS_ACTIONS.setDocument,
      payload: { document: { id: addNews.id, title: file.name, url: fileUrl, type: file.type, userId } },
    });
    return fileUrl;
  };
};

export const getDocumentsThunk = () => {
  return async (dispatch) => {
    await firestore
      .collection('documents')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => snapshotToEntity(doc));

        dispatch({
          type: NEWS_ACTIONS.getDocuments,
          payload: { documents: data },
        });
      });
  };
};

export const deleteDocumentsThunk = (id) => {
  return async (dispatch) => {
    await firestore.collection('documents').doc(id).delete();

    dispatch({
      type: NEWS_ACTIONS.deleteDocument,
      payload: { id },
    });
  };
};

// Gallery
export const getGalleriesThunk = () => {
  return async (dispatch) => {
    await firestore
      .collection('galleries')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => snapshotToEntity(doc));

        dispatch({
          type: NEWS_ACTIONS.getGalleries,
          payload: { galleries: data },
        });
      });
  };
};

export const createGalleryThunk = ({ title, userId }) => {
  return async (dispatch) => {
    const createGallery = await firestore.collection('galleries').add({ title, userId, images: [] });

    dispatch({
      type: NEWS_ACTIONS.setGallery,
      payload: { gallery: { id: createGallery.id, title, userId, images: [] } },
    });
  };
};

export const uploadGalleryImageThunk = ({ file, gallery, userId }) => {
  return async (dispatch) => {
    let fileUrl;
    let fileName;
    const { id, title, images } = gallery;
    if (file) {
      const { snap, name } = await uploadFile(file, 'galleries');
      fileName = name;
      fileUrl = await snap.ref.getDownloadURL();

      await firestore
        .collection('galleries')
        .doc(id)
        .set({ id, title, images: [...images, { title: file.name, url: fileUrl, fileName, type: file.type, userId }], userId: gallery.userId });
    }

    dispatch({
      type: NEWS_ACTIONS.setGalleryImage,
      payload: { gallery: { id, title, images: [...images, { title: file.name, url: fileUrl, type: file.type, fileName, userId }], userId: gallery.userId } },
    });

    return fileUrl;
  };
};

export const deleteGalleryThunk = (id) => {
  return async (dispatch) => {
    await firestore.collection('galleries').doc(id).delete();

    dispatch({
      type: NEWS_ACTIONS.deleteGallery,
      payload: { id },
    });
  };
};

export const deleteGalleryImageThunk = ({ fileName, gallery }) => {
  return async (dispatch) => {
    await firestore
      .collection('galleries')
      .doc(gallery.id)
      .set({ ...gallery, images: [...gallery.images.filter((image) => image.fileName !== fileName)] });

    dispatch({
      type: NEWS_ACTIONS.deleteGalleryImage,
      payload: { fileName, galleryId: gallery.id },
    });
  };
};
