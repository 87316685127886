import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  centeredCircularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    position: 'absolute',
    zIndex: '99999',
    background: 'rgba(255,255,255, 0.7)',
  },
});

function CenteredCircularProgress() {
  const classes = useStyles();

  return (
    <Box className={classes.centeredCircularProgress}>
      <CircularProgress />
    </Box>
  );
}

export default CenteredCircularProgress;
