/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Select, Grid, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { addHours, isAfter, isBefore, subHours } from 'date-fns';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import useTranslate from '../../hooks/useTranslate';
import { date, object, string } from '../../utils/yup';
import TextField from '../v2/TextField';
import FormError from '../ui/FormError';
import EVENT_TYPE from '../../constants/EventType';
import DateTimePicker from '../v2/DateTimePicker';

function EventForm({ event, onSubmit, isLoading }) {
  const { t } = useTranslate();

  const form = useFormik({
    validateOnBlur: true,
    onSubmit,
    initialValues: {
      start: event?.start || new Date(),
      end: event?.end || addHours(new Date(), 1),
      title: event?.title || '',
      type: event?.type || '',
      description: event?.description || '',
    },
    validationSchema: object().shape({
      start: date().required(),
      end: date().required(),
      title: string().required(),
      type: string().required(),
      description: string().nullable(),
    }),
  });

  const handleChangeDate = (name, value) => {
    form.setFieldValue(name, value);
    if ('start' === name && isAfter(value, form.values.end)) {
      form.setFieldValue('end', addHours(value, 1));
    } else if ('end' === name && isBefore(value, form.values.end)) {
      form.setFieldValue('start', subHours(value, 1));
    }
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label={t('EventForm.title')}
            value={form.values.title}
            onChange={form.handleChange}
            id="title"
            name="title"
            error={form.touched.title && form.errors.title}
            onBlur={form.handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <DateTimePicker
            label={t('EventForm.start')}
            format="dd/MM/yyyy HH:mm"
            ampm={false}
            value={form.values.start}
            cancelLabel={t('EventForm.cancel')}
            error={form.touched.start && form.errors.start}
            onChange={(value) => handleChangeDate('start', value)}
            onBlur={form.handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <DateTimePicker
            label={t('EventForm.end')}
            format="dd/MM/yyyy HH:mm"
            ampm={false}
            value={form.values.end}
            cancelLabel={t('EventForm.cancel')}
            error={form.touched.end && form.errors.end}
            onChange={(value) => handleChangeDate('end', value)}
            onBlur={form.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth error={form.errors.type && form.touched.type}>
            <InputLabel id="eventTypeLabel">
              <FormattedMessage id="EventForm.type" />
            </InputLabel>
            <Select labelId="eventTypeLabel" label={t('EventForm.type')} id="eventType" name="type" value={form.values.type} onChange={form.handleChange} onBlur={form.handleBlur}>
              {Object.values(EVENT_TYPE).map((type) => (
                <MenuItem value={type}>
                  <FormattedMessage id="Constants.EventType" values={{ value: type }} />
                </MenuItem>
              ))}
            </Select>
            <FormError error={form.touched.type && form.errors.type} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('EventForm.description')}
            value={form.values.description}
            onChange={form.handleChange}
            id="description"
            name="description"
            multiline
            error={form.touched.description && form.errors.description}
            onBlur={form.handleBlur}
            fullWidth
            rows={8}
          />
        </Grid>
      </Grid>
      <Box maxWidth="280px" mx="auto">
        <Box mt="45px" mb="100px">
          <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
            <FormattedMessage id="EventForm.save" />
          </Button>
        </Box>
      </Box>
    </form>
  );
}

EventForm.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

EventForm.defaultProps = {
  event: null,
  isLoading: false,
};

export default EventForm;
