import React from 'react';
import { Box, makeStyles, useTheme, Grid, Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { adherentByIdSelector } from '../../store/ducks/adherents/adherentsSelectors';
import Typography from '../../components/ui/Typography';
import AppLayout from '../../components/AppLayout';
import SocialNetworks from '../../components/Adherent/SocialNetworks';
import ContactInformations from '../../components/Adherent/ContactInformations';
import PhotosAndNetworks from '../../components/Adherent/PhotosAndNetworks';
import Products from '../../components/Adherent/Products';
import Schedule from '../../components/Adherent/Schedule';
import Discounts from '../../components/Adherent/Discounts';
import Button from '../../components/ui/Button';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import GoogleMap from '../../components/GoogleMap';
import useTranslate from '../../hooks/useTranslate';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  customContainer: {
    maxWidth: '1450px',
    position: 'relative',
  },
  editButton: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: '20px',
    },
    position: 'absolute',
    right: 0,
    top: 0,
  },
  deleteButton: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: '20px',
      top: 0,
    },
    position: 'absolute',
    right: 0,
    top: 50,
  },
  triangle: {
    cursor: 'pointer',
    display: 'inline-block',
    height: 0,
    width: 0,
    borderTop: '10px solid transparent',
    borderRight: `20px solid ${theme.palette.primary.main}`,
    borderBottom: '10px solid transparent',
    position: 'absolute',
    top: '0px',
    left: '0',
  },
  socialNetworks: {
    display: 'flex',
    marginTop: '25px',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      left: 0,
      display: 'block',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

function Adherent() {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslate();

  const adherent = useSelector(adherentByIdSelector(params.id));
  const user = useSelector(userSelector);

  useAsyncEffect(async () => {
    try {
      await dispatch(getAdherentsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    }
  }, [dispatch]);

  return (
    <AppLayout>
      <Container className={classes.customContainer}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mb="100px" mt="30px">
          <Box width="100%" mb="30px">
            <Box position="relative" width="100%" textAlign="center">
              <Box className={classes.triangle} onClick={() => history.push('/')} />
              <Typography variant="h1" color={theme.palette.primary.main}>
                <FormattedMessage id="Adherent.title" />
              </Typography>
              {(user?.id === adherent?.id || user?.isAdmin) && (
                <Box className={classes.editButton}>
                  <Button component={Link} to={`/adherents/${adherent?.id}/editer`} variant="contained">
                    <FormattedMessage id="Adherent.editButton" />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Grid spacing={4} container direction="row" alignItems="center">
            <PhotosAndNetworks adherent={adherent} />
            <SocialNetworks adherent={adherent} className={classes.socialNetworks} />
            <ContactInformations adherent={adherent} />
          </Grid>
          <Grid spacing={4} container direction="row" alignItems="center">
            <Grid item xs={12} lg={6}>
              {adherent?.lat && adherent?.lng ? (
                <Box mt="60px">
                  <GoogleMap lat={adherent?.lat} lng={adherent?.lng} />
                </Box>
              ) : (
                <Box mt="60px" textAlign="center">
                  <Typography>
                    <FormattedMessage id="Adherent.noAddress" />
                  </Typography>
                </Box>
              )}
            </Grid>
            <Schedule adherent={adherent} />
          </Grid>
          <Grid spacing={4} container direction="row" alignItems="center">
            <Products adherent={adherent} />
            <Discounts adherent={adherent} />
          </Grid>
        </Box>
      </Container>
    </AppLayout>
  );
}

export default Adherent;
