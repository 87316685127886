import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function HeartIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 22.655 20">
      <path
        d="M16.637,30a5.364,5.364,0,0,0-3.35,1.158,7.631,7.631,0,0,0-1.96,2.357,7.63,7.63,0,0,0-1.96-2.357A5.364,5.364,0,0,0,6.018,30C2.587,30,0,32.806,0,36.527c0,4.02,3.228,6.771,8.114,10.934.83.707,1.77,1.509,2.748,2.363a.708.708,0,0,0,.932,0c.978-.855,1.918-1.656,2.748-2.364,4.886-4.163,8.113-6.914,8.113-10.934C22.655,32.806,20.068,30,16.637,30Z"
        transform="translate(0 -30)"
        fill={color}
      />
      <path
        d="M16.637,2.323a5.349,5.349,0,0,0-3.35,1.159,7.143,7.143,0,0,0-1.54,1.668.509.509,0,0,1-.841,0,7.143,7.143,0,0,0-1.54-1.668,5.349,5.349,0,0,0-3.35-1.159C2.588,2.323,0,5.128,0,8.85c0,4.022,3.226,6.77,8.115,10.934.832.708,1.77,1.509,2.748,2.363a.723.723,0,0,0,.465.177.7.7,0,0,0,.465-.177c.978-.854,1.916-1.655,2.748-2.363,4.889-4.164,8.115-6.912,8.115-10.934C22.655,5.128,20.066,2.323,16.637,2.323Z"
        transform="translate(0 -2.323)"
        fill={color}
      />
    </SvgIcon>
  );
}

HeartIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

HeartIcon.defaultProps = {
  size: '23px',
  color: '#B4B4B4',
  className: '',
};

export default HeartIcon;
