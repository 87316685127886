import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import { object, string } from '../../utils/yup';
import { changeEmailThunk, changePasswordThunk } from '../../store/ducks/auth/authThunks';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../../components/v2/TextField';
import Button from '../../components/ui/Button';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px',
    padding: '10px',
    paddingTop: '50px',
    paddingBottom: '50px',
  },
}));

function MyProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const [isLoading, setLoading] = useState(false);

  const user = useSelector(userSelector);

  const accountForm = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
    validationSchema: object().shape({
      firstName: string().required(),
      lastName: string().required(),
      email: string().email().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(changeEmailThunk(user.id, { ...user, ...values }));
        toast.success(t('MyProfile.emailSuccess'));
      } catch (err) {
        if ('auth/email-already-exists' === err.message) {
          toast.error(t('Errors.api.emailAlreadyUsed'));
          return;
        }

        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  const passwordForm = useFormik({
    initialValues: {
      previousPassword: '',
      password: '',
      confirmedPassword: '',
    },
    validationSchema: object().shape({
      previousPassword: string().required(),
      password: string().required(),
      confirmedPassword: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.password !== values.confirmedPassword) {
          toast.error(t('MyProfile.passwordNotMatching'));
        } else {
          await dispatch(changePasswordThunk({ previousPassword: values.previousPassword, password: values.password }));
          toast.success(t('MyProfile.passwordSuccess'));
          passwordForm.resetForm();
        }
      } catch (err) {
        toast.error(t(err.message));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <AppLayout>
      <Box textAlign="center">
        <Box textAlign="center" my="50px">
          <Typography variant="h1">
            <FormattedMessage id="MyProfile.title" />
          </Typography>
        </Box>
        <Box className={classes.formContainer}>
          <form onSubmit={accountForm.handleSubmit}>
            <Box mb="40px">
              <Typography variant="h2">
                <FormattedMessage id="MyProfile.changeMyEmail" />
              </Typography>
            </Box>
            <Box mt="55px" maxWidth="300px" mx="auto">
              <TextField
                label={t('MyProfile.firstName.label')}
                placeholder={t('MyProfile.firstName.placeholder')}
                value={accountForm.values.firstName}
                onChange={accountForm.handleChange}
                id="firstName"
                name="firstName"
                error={accountForm.touched.firstName && accountForm.errors.firstName}
                onBlur={accountForm.handleBlur}
                fullWidth
              />
              <Box my="25px">
                <TextField
                  label={t('MyProfile.lastName.label')}
                  placeholder={t('MyProfile.lastName.placeholder')}
                  value={accountForm.values.lastName}
                  onChange={accountForm.handleChange}
                  id="lastName"
                  name="lastName"
                  error={accountForm.touched.lastName && accountForm.errors.lastName}
                  onBlur={accountForm.handleBlur}
                  fullWidth
                />
              </Box>
              <TextField
                label={t('MyProfile.email.label')}
                placeholder={t('MyProfile.email.placeholder')}
                value={accountForm.values.email}
                onChange={accountForm.handleChange}
                id="email"
                name="email"
                error={accountForm.touched.email && accountForm.errors.email}
                onBlur={accountForm.handleBlur}
                type="email"
                fullWidth
              />
            </Box>
            <Box mt="20px">
              <Button loading={isLoading} variant="contained" type="submit" color="orange">
                <FormattedMessage id="Common.submit" />
              </Button>
            </Box>
          </form>
        </Box>
        <Box className={classes.formContainer} mt="40px">
          <form onSubmit={passwordForm.handleSubmit}>
            <Box mb="40px">
              <Typography variant="h2">
                <FormattedMessage id="MyProfile.changeMyPassword" />
              </Typography>
            </Box>
            <Box mt="55px" maxWidth="300px" mx="auto">
              <TextField
                label={t('MyProfile.previousPassword.label')}
                placeholder={t('MyProfile.previousPassword.placeholder')}
                type="password"
                value={passwordForm.values.previousPassword}
                onChange={passwordForm.handleChange}
                id="previousPassword"
                name="previousPassword"
                error={passwordForm.touched.previousPassword && passwordForm.errors.previousPassword}
                onBlur={passwordForm.handleBlur}
                fullWidth
              />
              <Box my="25px">
                <TextField
                  label={t('MyProfile.password.label')}
                  placeholder={t('MyProfile.password.placeholder')}
                  type="password"
                  value={passwordForm.values.password}
                  onChange={passwordForm.handleChange}
                  id="password"
                  name="password"
                  error={passwordForm.touched.password && passwordForm.errors.password}
                  onBlur={passwordForm.handleBlur}
                  fullWidth
                />
              </Box>
              <TextField
                label={t('MyProfile.confirmedPassword.label')}
                placeholder={t('MyProfile.confirmedPassword.placeholder')}
                type="password"
                value={passwordForm.values.confirmedPassword}
                onChange={passwordForm.handleChange}
                id="confirmedPassword"
                name="confirmedPassword"
                error={passwordForm.touched.confirmedPassword && passwordForm.errors.confirmedPassword}
                onBlur={passwordForm.handleBlur}
                fullWidth
              />
            </Box>
            <Box mt="20px">
              <Button loading={isLoading} variant="contained" type="submit">
                <FormattedMessage id="Common.submit" />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </AppLayout>
  );
}

export default MyProfile;
