// eslint-disable-next-line no-restricted-imports
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import withError from './withError';

// TODO Remove V2 Folder

const Autocomplete = withError(MuiAutocomplete);

Autocomplete.propTypes = MuiAutocomplete.propTypes;

export default Autocomplete;
