// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField } from '@material-ui/core';
import withError from './withError';

// TODO Remove V2 Folder

const TextField = withError(MuiTextField);

TextField.propTypes = MuiTextField.propTypes;

export default TextField;
