import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function CurvedArrow({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 23 20">
      <defs>
        <clipPath>
          <rect width="23" height="20" transform="translate(465.328 763)" fill={color} />
        </clipPath>
      </defs>
      <g transform="translate(-465.328 -763)" clipPath="url(#clip-path)">
        <path
          d="M22.819,12.678l-8.388-9.2a.522.522,0,0,0-.908.353v4.42A.525.525,0,0,1,13,8.778H12a12,12,0,0,0-12,12v1.464a.523.523,0,0,0,.908.353h0a16.288,16.288,0,0,1,12.035-5.31H13a.525.525,0,0,1,.524.524v4.42a.522.522,0,0,0,.908.353l8.388-9.2A.526.526,0,0,0,22.819,12.678Z"
          transform="translate(465.178 759.691)"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

CurvedArrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

CurvedArrow.defaultProps = {
  size: '23px',
  color: '#B4B4B4',
  className: '',
};

export default CurvedArrow;
