/* eslint-disable no-undef */
import React, { useCallback, useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Box, FormControl, makeStyles, useTheme } from '@material-ui/core';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import useTranslate from '../../hooks/useTranslate';
import FormError from '../ui/FormError';

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    height: '242px',
    borderRadius: '15px',
    margin: '0 auto',
    marginTop: '60px',
    zIndex: 9999,
    width: '100%',
    '&:first-child': {
      position: 'relative !important',
    },
  },
  label: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    marginBottom: '20px',
  },
});

const font = {
  fontSize: '1.6rem',
  fontWeight: '400',
  lineHeight: '2rem',
};

function EditableGoogleMaps({ address, lat, lng, onChange, error, onBlur }) {
  const classes = useStyles();
  const { t } = useTranslate();
  const theme = useTheme();

  const handleChange = useCallback(
    (value) => {
      geocodeByAddress(value.label)
        .then(async (results) => {
          const coordinates = await getLatLng(results[0]);
          onChange({ lat: coordinates.lat, lng: coordinates.lng, address: value.label });
        })
        .catch(() => {
          onChange({ lat: null, lng: null, address });
        });
    },
    [address, onChange]
  );

  useEffect(() => address && handleChange({ label: address }), [handleChange, address]);

  const handleFocus = useCallback(() => {
    onChange({ address: '' });
  }, [onChange]);

  return (
    <>
      <FormControl variant="outlined" error={!!error} fullWidth>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          apiOptions={{ language: 'fr', region: 'fr' }}
          onBlur={onBlur}
          selectProps={{
            value: address && { label: address, value: address },
            placeholder: t('GoogleMaps.placeholder'),
            onChange: handleChange,
            onFocus: handleFocus,
            id: 'address',
            name: 'address',
            loadingMessage: () => t('GoogleMaps.loading'),
            noOptionsMessage: () => t('GoogleMaps.noOption'),
            styles: {
              control: (provided) => ({
                ...provided,
                width: '100%',
                boxShadow: 'none',
                height: '57px',
              }),
              input: (provided) => ({
                ...provided,
                ...font,
              }),
              option: (provided) => ({
                ...provided,
                ...font,
              }),
              singleValue: (provided) => ({
                ...provided,
                ...font,
              }),
              placeholder: (provided) => ({
                ...provided,
                ...font,
                color: theme.palette.grey[400],
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            },
          }}
        />
        <FormError error={error} />
      </FormControl>
      {lat && lng && (
        <Box className={classes.container}>
          <Map
            containerStyle={{ position: 'relative' }}
            google={google}
            initialCenter={{ lat, lng }}
            center={{ lat, lng }}
            style={{
              borderRadius: '15px',
            }}
          >
            <Marker position={{ lat, lng }} />
          </Map>
        </Box>
      )}
    </>
  );
}

EditableGoogleMaps.propTypes = {
  address: PropTypes.string,
  lng: PropTypes.number,
  lat: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
};

EditableGoogleMaps.defaultProps = {
  address: null,
  lng: null,
  lat: null,
  error: false,
  onBlur: null,
};
export default React.memo(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })(EditableGoogleMaps)
);
