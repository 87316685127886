import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Redirect, useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import Typography from '../../components/ui/Typography';
import Button from '../../components/ui/Button';
import TextField from '../../components/v2/TextField';
import useTranslate from '../../hooks/useTranslate';
import { object, string, boolean } from '../../utils/yup';
import { createUserThunk } from '../../store/ducks/adherents/usersThunks';
import AppLayout from '../../components/AppLayout';
import theme from '../../theme';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles({
  label: {
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: theme.palette.text.primary,
  },
});

function CreateUser() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const history = useHistory();
  const classes = useStyles();
  const currentUser = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      email: '',
      isAdmin: false,
      firstName: '',
      lastName: '',
    },
    validationSchema: object().shape({
      email: string().email().required(),
      isAdmin: boolean(),
      firstName: string().required(),
      lastName: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await dispatch(createUserThunk(values));

        toast.success(t('CreateUser.created'));
        history.push('/utilisateurs');
      } catch (error) {
        if ('auth/email-already-exists' === error.message) {
          toast.error(t('Errors.api.emailAlreadyUsed'));
          return;
        }

        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  const handleChange = (e) => {
    form.setFieldValue('isAdmin', e.target.checked);
  };

  if (!currentUser.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <AppLayout>
      <Box textAlign="center" mt="80px">
        <form onSubmit={form.handleSubmit}>
          <Box maxWidth="282px" mx="auto">
            <Typography variant="h1">
              <FormattedMessage id="CreateUser.title" />
            </Typography>
            <Box mt="85px">
              <TextField
                label={t('CreateUser.firstName.label')}
                placeholder={t('CreateUser.firstName.placeholder')}
                value={form.values.firstName}
                onChange={form.handleChange}
                id="firstName"
                name="firstName"
                error={form.touched.firstName && form.errors.firstName}
                onBlur={form.handleBlur}
                fullWidth
              />
            </Box>
            <Box mt="55px">
              <TextField
                label={t('CreateUser.lastName.label')}
                placeholder={t('CreateUser.lastName.placeholder')}
                value={form.values.lastName}
                onChange={form.handleChange}
                id="lastName"
                name="lastName"
                error={form.touched.lastName && form.errors.lastName}
                onBlur={form.handleBlur}
                fullWidth
              />
            </Box>
            <Box maxWidth="300px" mt="55px">
              <TextField
                label={t('CreateUser.email.label')}
                placeholder={t('CreateUser.email.placeholder')}
                value={form.values.email}
                onChange={form.handleChange}
                id="email"
                name="email"
                error={form.touched.email && form.errors.email}
                onBlur={form.handleBlur}
                type="email"
                fullWidth
              />
            </Box>
          </Box>
          <Box mt="55px">
            <FormControlLabel
              control={<Checkbox checked={form.values.isAdmin} onChange={(e) => handleChange(e)} color="primary" />}
              label={
                <Typography className={classes.label}>
                  <FormattedMessage id="CreateUser.isAdmin" />{' '}
                </Typography>
              }
            />
          </Box>
          <Box maxWidth="280px" mx="auto">
            <Box mt="45px" mb="100px">
              <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
                <FormattedMessage id="CreateUser.register" />
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </AppLayout>
  );
}

export default CreateUser;
