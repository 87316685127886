export const BUSINESS_ACTIONS = {
  getAdherents: 'BUSINESS/GET_ADHERENTS',
  getBusinesses: 'BUSINESS/GET_BUSINESSES',
  deleteBusiness: 'BUSINESS/DELETE_BUSINESS',
};

const initialState = {
  adherents: [],
  businesses: [],
};

function businessReducer(state = initialState, action) {
  switch (action.type) {
    case BUSINESS_ACTIONS.getAdherents:
      return {
        ...state,
        adherents: action.payload.adherents,
      };
    case BUSINESS_ACTIONS.getBusinesses:
      return {
        ...state,
        businesses: action.payload.businesses,
      };
    case BUSINESS_ACTIONS.deleteBusiness:
      return {
        ...state,
        businesses: state.businesses.filter((business) => business.id !== action.payload.id),
      };
    default: {
      return state;
    }
  }
}

export default businessReducer;
