import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function ChainIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 26.995 26.995">
      <g transform="translate(9.077 0)">
        <path
          d="M188.224,1.853a6.327,6.327,0,0,0-8.948,0l-5.288,5.288a6.387,6.387,0,0,0,0,8.948,6.022,6.022,0,0,0,1.272.965l.965-.965a2.173,2.173,0,0,0,.4-1.907,3.053,3.053,0,0,1-.4-.33,3.2,3.2,0,0,1,0-4.474l5.288-5.288a3.163,3.163,0,1,1,4.474,4.474l-3.5,3.5a9.105,9.105,0,0,1,.4,4.069c.015-.015.033-.025.049-.04l5.288-5.288A6.327,6.327,0,0,0,188.224,1.853Z"
          transform="translate(-172.159 0)"
          fill={color}
        />
      </g>
      <g transform="translate(0 9.531)">
        <path
          d="M16.5,181.741a6.023,6.023,0,0,0-1.272-.965l-.965.965a2.173,2.173,0,0,0-.4,1.907,3.048,3.048,0,0,1,.4.33,3.2,3.2,0,0,1,0,4.474l-5.7,5.7a3.163,3.163,0,0,1-4.474-4.474L8,185.771a9.1,9.1,0,0,1-.4-4.069c-.015.015-.034.025-.049.04l-5.7,5.7a6.327,6.327,0,0,0,8.948,8.948l5.7-5.7A6.383,6.383,0,0,0,16.5,181.741Z"
          transform="translate(0 -180.776)"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}

ChainIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ChainIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default ChainIcon;
