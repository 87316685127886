import React from 'react';
import { FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormatErrorMessage from './FormatErrorMessage';

function FormError({ error, className }) {
  if (!error) {
    return null;
  }

  return (
    <FormHelperText error className={className}>
      <FormatErrorMessage error={error} />
    </FormHelperText>
  );
}

FormError.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ key: PropTypes.string, params: PropTypes.object }), PropTypes.bool]),
  className: PropTypes.string,
};

FormError.defaultProps = {
  error: null,
  className: '',
};

export default FormError;
