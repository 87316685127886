import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import MeetingForm from '../../components/Attendances/MeetingForm';
import { createMeetingThunk } from '../../store/ducks/meetings/meetingsThunk';
import useTranslate from '../../hooks/useTranslate';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function CreateMeeting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslate();

  const user = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(createMeetingThunk({ data: values, userId: user.id }));

      toast.success(t('MeetingForm.created'));
      history.push(`/presences/statistiques`);
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  if (!user.isAdmin) {
    return <Redirect to="/" />;
  }

  return <MeetingForm onSubmit={handleSubmit} isLoading={isLoading} />;
}

export default CreateMeeting;
