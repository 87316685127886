import { Box, makeStyles, useTheme, Popover } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '../ui/Typography';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles({
  popoverContainer: {
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    padding: '15px',
  },
  popoverOptions: {
    cursor: 'pointer',
  },
});

function NewsPopover({ anchorEl, onClickDownload, onClose, onDelete, deleteText, firstOptionText, elementId }) {
  const theme = useTheme();
  const classes = useStyles();

  const user = useSelector(userSelector);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box className={classes.popoverContainer}>
        {onClickDownload && (
          <Box className={classes.popoverOptions} onClick={onClickDownload}>
            <Typography>{firstOptionText}</Typography>
          </Box>
        )}
        {((elementId && elementId === user?.id) || user?.isAdmin) && (
          <Box mt={onClickDownload ? '10px' : undefined} className={classes.popoverOptions} onClick={onDelete}>
            <Typography color={theme.palette.warning.main}>{deleteText}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
}

NewsPopover.propTypes = {
  anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onClickDownload: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteText: PropTypes.string.isRequired,
  firstOptionText: PropTypes.string.isRequired,
  elementId: PropTypes.string,
};

NewsPopover.defaultProps = {
  onClickDownload: null,
  anchorEl: null,
  elementId: null,
};

export default NewsPopover;
