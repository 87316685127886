import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function LinkedInIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 35 35">
      <path
        d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0ZM12.415,26.455H8.153V13.633h4.262ZM10.284,11.882h-.028a2.221,2.221,0,1,1,.056-4.431,2.222,2.222,0,1,1-.028,4.431Zm17.5,14.573H23.521V19.6c0-1.724-.617-2.9-2.159-2.9a2.333,2.333,0,0,0-2.187,1.559,2.919,2.919,0,0,0-.14,1.04v7.161H14.773s.056-11.62,0-12.822h4.262v1.816a4.231,4.231,0,0,1,3.841-2.116c2.8,0,4.907,1.833,4.907,5.771Zm0,0"
        fill={color}
      />
    </SvgIcon>
  );
}

LinkedInIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

LinkedInIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default LinkedInIcon;
