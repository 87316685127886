/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Redirect, useHistory, useParams } from 'react-router';
import Typography from '../../components/ui/Typography';
import useTranslate from '../../hooks/useTranslate';
import AppLayout from '../../components/AppLayout';
import { fetchEventThunk, updateEventThunk } from '../../store/ducks/events/eventsThunks';
import { eventByIdSelector } from '../../store/ducks/events/eventsSelectors';
import EventForm from '../../components/Events/EventForm';
import CenteredCircularProgress from '../../components/ui/CenteredCircularProgress';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function EditEvent() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();
  const history = useHistory();

  const event = useSelector(eventByIdSelector(id));
  const user = useSelector(userSelector);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchEventThunk(id));
  }, [dispatch, id]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(updateEventThunk(id, values));

      toast.success(t('EditEvent.success'));
      history.push('/evenements');
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  if (event && user.id !== event.userId && !user.isAdmin) {
    return <Redirect to="/evenements" />;
  }

  return (
    <AppLayout>
      <Box mt="60px">
        <Typography variant="h1" align="center" color="primary.main">
          <FormattedMessage id="EditEvent.title" />
        </Typography>
        <Box mt="40px" mb="20px">
          <Typography variant="h2">
            <FormattedMessage id="EditEvent.subtitle" />
          </Typography>
        </Box>
        {event ? <EventForm event={event} onSubmit={handleSubmit} isLoading={isLoading} /> : <CenteredCircularProgress />}
      </Box>
    </AppLayout>
  );
}

export default EditEvent;
