import React from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';
import AppLayout from '../../components/AppLayout';
import AdherentsResult from './AdherentsResult';
import EventsResult from './EventsResult';
import DocumentsResult from './DocumentsResult';
import GalleryResult from './GalleryResult';
import Typography from '../../components/ui/Typography';

function Research() {
  const params = useParams();

  const { word } = params;

  return (
    <AppLayout>
      <Box mt="44px">
        <Typography variant="h1">
          <FormattedMessage id="Research.title" values={{ word }} />
        </Typography>
      </Box>
      <AdherentsResult word={word} />
      <EventsResult word={word} />
      <DocumentsResult word={word} />
      <GalleryResult word={word} />
    </AppLayout>
  );
}

export default Research;
