/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button as MuiButton, makeStyles, lighten, CircularProgress, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from './Typography';
import CustomPropTypes from '../../utils/CustomPropTypes';
import theme from '../../theme';

const colors = {
  primary: {
    background: theme.palette.primary.main,
    text: theme.palette.common.white,
  },
  white: {
    background: theme.palette.common.white,
    text: theme.palette.primary.main,
  },
  grey: {
    background: theme.palette.grey[300],
    text: theme.palette.common.black,
    link: theme.palette.grey[500],
  },
  orange: {
    background: theme.palette.common.orange,
    text: theme.palette.common.white,
  },
  green: {
    background: theme.palette.common.green,
    text: theme.palette.common.white,
  },
  error: {
    link: theme.palette.error.main,
    background: theme.palette.error.main,
    text: theme.palette.common.white,
  },
  success: {
    link: theme.palette.success.main,
    background: theme.palette.success.main,
    text: theme.palette.common.white,
  },
};

// eslint-disable-next-line no-shadow
const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    boxShadow: 'none',
    maxWidth: '100%',
    borderRadius: '25px',
  },
  contained: ({ color }) => ({
    backgroundColor: colors[color].background,
    color: colors[color].text,
    '&:hover': {
      backgroundColor: lighten(colors[color].background, 0.1),
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
    },
  }),
  outlined: ({ color }) => ({
    color: colors[color].background,
    borderColor: colors[color].background,
    '&:disabled': {
      color: theme.palette.grey[300],
      border: `1px solid currentColor`,
    },
    '&:hover': {
      border: `1px solid currentColor`,
    },
  }),
  text: ({ color }) => ({
    // Exception for primary : Uses primary.link (Maybe add colorVariant for link ?)
    color: colors[color].link || colors[color].background,
    '&:disabled': {
      color: theme.palette.grey[500],
    },
    '& path': {
      stroke: 'currentColor',
    },
  }),
  small: {
    paddingLeft: '19px',
    paddingRight: '19px',
    paddingTop: '1px',
    paddingBottom: '1px',
  },
  medium: {
    paddingLeft: '19px',
    paddingRight: '19px',
    paddingTop: '7px',
    paddingBottom: '7px',
  },
  large: {
    paddingLeft: '26px',
    paddingRight: '26px',
    paddingTop: '13px',
    paddingBottom: '13px',
  },
  extraLarge: {
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '17px',
    paddingBottom: '17px',
  },
  buttonText: {
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    color: ({ color }) => theme.palette[color]?.link || theme.palette[color]?.main,
    '&:hover': {
      color: ({ color }) => theme.palette[color]?.main,
    },
    '&:disabled, &:hover:disabled': {
      color: theme.palette.text.secondary,
    },
  },
}));

function Button({ fullWidth, children, className, onClick, startIcon, endIcon, textClassName, color, disabled, variant, size, loading, ...props }) {
  const classes = useStyle({ color });

  if ('link' === variant) {
    return (
      <button disabled={disabled} type="button" className={clsx(classes.linkButton, classes[size], className)} onClick={onClick}>
        {startIcon && (
          <Box display="inline-flex" mr="10px">
            {startIcon}
          </Box>
        )}
        <Typography variant="body1">{children}</Typography>
        {loading && (
          <Box display="inline-flex" ml="10px">
            <CircularProgress size={16} withoutBackground thickness={7} color="inherit" />
          </Box>
        )}
        {endIcon && (
          <Box display="inline-flex" ml="10px">
            {endIcon}
          </Box>
        )}
      </button>
    );
  }

  return (
    <MuiButton
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={loading || disabled}
      className={clsx(classes.root, classes[variant], classes[size], className)}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      <Typography className={clsx(classes.buttonText, textClassName)}>{children}</Typography>
      {loading && (
        <Box display="inline-flex" ml="10px">
          <CircularProgress size={16} withoutBackground thickness={7} color="inherit" />
        </Box>
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  color: PropTypes.oneOf(['primary', 'white', 'grey', 'orange', 'green']),
  variant: PropTypes.oneOf(['contained', 'text', 'outlined', 'link']),
  className: CustomPropTypes.classes,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  textClassName: CustomPropTypes.classes,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

Button.defaultProps = {
  color: 'primary',
  variant: 'text',
  className: {},
  fullWidth: false,
  disabled: false,
  size: 'medium',
  textClassName: {},
  loading: false,
  onClick: undefined,
  startIcon: undefined,
  endIcon: undefined,
};

export default Button;
