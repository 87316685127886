import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function FacebookIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 35 35">
      <path
        d="M35,17.5A17.5,17.5,0,1,0,17.5,35c.1,0,.205,0,.308-.007V21.376h-3.76V16.994h3.76V13.768c0-3.739,2.283-5.776,5.619-5.776a30.542,30.542,0,0,1,3.37.171v3.91H24.5c-1.812,0-2.167.861-2.167,2.126v2.789h4.341l-.567,4.382H22.333V34.323A17.505,17.505,0,0,0,35,17.5Z"
        fill={color}
      />
    </SvgIcon>
  );
}

FacebookIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

FacebookIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default FacebookIcon;
