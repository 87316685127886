import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { toast } from 'react-hot-toast';
import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useTranslate from '../../hooks/useTranslate';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { documentsSelector } from '../../store/ducks/news/newsSelector';
import { getDocumentsThunk } from '../../store/ducks/news/newsThunks';
import Typography from '../../components/ui/Typography';
import NewsDocument from '../../components/News/NewsDocument';

const useStyle = makeStyles(() => ({
  paper: {
    marginTop: '30px',
    padding: '24px',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
  },
}));

function DocumentsResult({ word }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const documents = useSelector(documentsSelector);
  const classes = useStyle();

  const [isLoading, setLoading] = useState(true);

  const options = {
    keys: ['title', 'description'],
  };

  const fuse = new Fuse(documents, options);
  const filteredDocuments = fuse.search(word);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await dispatch(getDocumentsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  }, [word]);

  if (isLoading) {
    return (
      <Box textAlign="center" mt="24px">
        <CircularProgress />
      </Box>
    );
  }
  if (0 < filteredDocuments.length) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h1">
          <FormattedMessage id="Research.documents.title" />
        </Typography>
        <Box display="flex" flexWrap="wrap">
          {filteredDocuments.map((document) => {
            return <NewsDocument document={document.item} />;
          })}
        </Box>
      </Paper>
    );
  }
  return null;
}

DocumentsResult.propTypes = {
  word: PropTypes.string.isRequired,
};

export default DocumentsResult;
