import { MEETINGS_ACTIONS } from './meetingsReducer';

// eslint-disable-next-line import/prefer-default-export
export const pushMeetingsAction = (meetings) => ({
  type: MEETINGS_ACTIONS.push,
  payload: { meetings },
});

export const deleteMeetingAction = (id) => ({
  type: MEETINGS_ACTIONS.delete,
  payload: { id },
});
