import React, { useCallback } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import Typography from '../ui/Typography';
import Button from '../ui/Button';
import AppLayout from '../AppLayout';
import ScheduleForm from './ScheduleForm';
import ContactInformationsForm from './ContactInformationsForm';
import PhotosForm from './PhotosForm';
import DiscountsForm from './DiscountsForm';
import SocialNetworksForm from './SocialNetworksForm';
import TextField from '../v2/TextField';
import useTranslate from '../../hooks/useTranslate';
import EditableGoogleMaps from './EditableGoogleMaps';
import { object, phone, string, number } from '../../utils/yup';

const adherentFormSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  companyName: string(),
  email: string().email().required(),
  phone: phone(),
  secondaryPhone: phone(),
  schedule: object().shape({
    monday: string(),
    tuesday: string(),
    wednesday: string(),
    thursday: string(),
    friday: string(),
    saturday: string(),
    sunday: string(),
  }),
  address: string().nullable(true),
  lng: number().nullable(true),
  lat: number().nullable(true),
  products: string(),
  discounts: string(),
  socialNetworks: object().shape({
    instagram: string(),
    facebook: string(),
    linkedIn: string(),
    website: string(),
  }),
});

function AdherentForm({ adherent, onSubmit, isLoading }) {
  const { t } = useTranslate();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: adherent?.firstName || '',
      lastName: adherent?.lastName || '',
      companyName: adherent?.companyName || '',
      email: adherent?.email || '',
      phone: adherent?.phone || '',
      secondaryPhone: adherent?.secondaryPhone || '',
      memberPhoto: adherent?.memberPhoto || '',
      companyLogo: adherent?.companyLogo || '',
      address: adherent?.address || null,
      lat: adherent?.lat || null,
      lng: adherent?.lng || null,
      schedule: {
        monday: adherent?.schedule?.monday || '',
        tuesday: adherent?.schedule?.tuesday || '',
        wednesday: adherent?.schedule?.wednesday || '',
        thursday: adherent?.schedule?.thursday || '',
        friday: adherent?.schedule?.friday || '',
        saturday: adherent?.schedule?.saturday || '',
        sunday: adherent?.schedule?.sunday || '',
      },
      products: adherent?.products || '',
      discounts: adherent?.discounts || '',
      socialNetworks: {
        instagram: adherent?.socialNetworks?.instagram || '',
        facebook: adherent?.socialNetworks?.facebook || '',
        linkedIn: adherent?.socialNetworks?.linkedIn || '',
        website: adherent?.socialNetworks?.website || '',
      },
    },
    validationSchema: adherentFormSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await onSubmit(values);
    },
  });

  const { setValues } = form;

  const handleAddressChange = useCallback(
    (values) => {
      setValues((prevState) => ({
        ...prevState,
        ...values,
      }));
    },
    [setValues]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = await form.validateForm();
    if (0 < Object.keys(errors).length) {
      toast.error(t('AdherentForm.checkErrors'));
    } else {
      form.handleSubmit(e);
    }
  };

  return (
    <AppLayout>
      <Container>
        <Box textAlign="center" my="50px" position="relative">
          <Typography variant="h1">
            <FormattedMessage id="AdherentForm.title" values={{ adherent: adherent ? 'edit' : 'create' }} />
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb="100px">
            <ContactInformationsForm form={form} />
            <PhotosForm form={form} />
            <Box mt="85px">
              <Grid item xs={12}>
                <Box mt="60px">
                  <EditableGoogleMaps
                    onChange={handleAddressChange}
                    address={form.values.address}
                    lat={form.values.lat}
                    lng={form.values.lng}
                    error={form.touched.products && form.errors.products}
                    onBlur={form.handleBlur}
                  />
                </Box>
              </Grid>
            </Box>
            <Box mt="85px">
              <TextField
                label={t('AdherentForm.products.label')}
                placeholder={t('AdherentForm.products.placeholder')}
                value={form.values.products}
                onChange={form.handleChange}
                id="products"
                name="products"
                error={form.touched.products && form.errors.products}
                onBlur={form.handleBlur}
                multiline
                fullWidth
              />
            </Box>
            <ScheduleForm form={form} />
            <Box mt="85px">
              <DiscountsForm form={form} />
            </Box>
            <Box mt="85px">
              <SocialNetworksForm form={form} />
            </Box>
            <Box display="flex" justifyContent="center" py="50px">
              <Button variant="contained" type="submit" loading={isLoading}>
                <FormattedMessage id="AdherentForm.button" values={{ adherent: adherent ? 'edit' : 'create' }} />
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
    </AppLayout>
  );
}

AdherentForm.propTypes = {
  isLoading: PropTypes.bool,
  adherent: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    companyLogo: PropTypes.string,
    companyName: PropTypes.string,
    phone: PropTypes.string,
    secondaryPhone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    memberPhoto: PropTypes.string,
    products: PropTypes.string,
    discounts: PropTypes.string,
    schedule: PropTypes.shape({
      monday: PropTypes.string,
      tuesday: PropTypes.string,
      wednesday: PropTypes.string,
      thursday: PropTypes.string,
      friday: PropTypes.string,
      saturday: PropTypes.string,
      sunday: PropTypes.string,
    }),
    socialNetworks: PropTypes.shape({
      instagram: PropTypes.string,
      facebook: PropTypes.string,
      linkedIn: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

AdherentForm.defaultProps = {
  adherent: null,
  isLoading: false,
};

export default AdherentForm;
