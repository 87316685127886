/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, fade, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../ui/Button';
import UploadIcon from '../icons/ChainIcon';
import Typography from '../ui/Typography';

const useStyle = makeStyles((theme) => ({
  dropzone: {
    borderRadius: '10px',
    border: `1px dashed ${theme.palette.grey[600]}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px',
    outline: 'none',
    transition: 'all 200ms ease',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      borderColor: theme.palette.primary.main,
    },
  },
  dropzoneAccept: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
    borderColor: theme.palette.success.main,
  },
  dropzoneReject: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
    borderColor: theme.palette.error.main,
  },
}));

function UploadDropzone({ onDrop }) {
  const theme = useTheme();
  const classes = useStyle();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  const dropzoneClasses = useMemo(() => [classes.dropzone, isDragAccept && classes.dropzoneAccept, isDragReject && classes.dropzoneReject], [classes, isDragReject, isDragAccept]);

  return (
    <Box {...getRootProps({ className: clsx(dropzoneClasses) })}>
      <input {...getInputProps()} />
      <Box mb="16px">
        <UploadIcon size="64px" color={theme.palette.grey[600]} />
      </Box>
      <Typography>
        <FormattedMessage id="UploadDropZone.dragDrop" />
      </Typography>
      <Box my="8px">
        <Typography>
          <FormattedMessage id="UploadDropZone.or" />
        </Typography>
      </Box>
      <Button variant="outlined" color="primary" size="small">
        <FormattedMessage id="UploadDropZone.browse" />
      </Button>
    </Box>
  );
}

UploadDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

export default UploadDropzone;
