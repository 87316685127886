import { createMuiTheme } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiStrictModeTheme } from '@material-ui/core';

const createTheme = 'dev' === process.env.REACT_APP_ENV ? createMuiStrictModeTheme : createMuiTheme;

const palette = {
  background: {
    default: '#fff',
  },
  common: {
    white: '#fff',
    black: '#000',
    violet: '#7C0E6F',
    orange: '#FCA01F',
    green: '#91B508',
    red: '#EF4444',
  },
  text: {
    primary: '#1F4467',
    secondary: '#7C7C7C',
  },
  warning: {
    main: '#FCA01F',
  },
  primary: {
    main: '#3478B9',
    dark: '#1F4467',
  },
  success: {
    main: '#10B981',
  },
  grey: {
    300: '#E6E6E6',
    400: '#B4B4B4',
  },
  error: {
    main: '#FCA01F',
  },
};

const typography = {
  htmlFontSize: 10,
  fontFamily: '"Poppins", sans-serif;',
  fontSize: 14,

  h1: {
    fontWeight: 700,
    fontSize: '2.6rem',
    lineHeight: '2.9rem',
  },
  h2: {
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.9rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1.6rem',
    lineHeight: '2rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '1.3rem',
    lineHeight: '1.95rem',
  },
  bodyBold: {
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: '2rem',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
};

const theme = createTheme({
  typography,
  palette,
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'p',
        body2: 'p',
        bodyBold: 'p',
        link: 'span',
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // MUI typography elements use REMs, so you can scale the global
        // font size by setting the font-size on the <html> element.
        html: {
          fontSize: 10,
          background: palette.common.white,
        },
        '.MuiPickersBasePicker-container .MuiPickersCalendar-transitionContainer': {
          minHeight: '224px',
        },
      },
    },
  },
});
export default theme;
