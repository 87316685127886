import React from 'react';
import { useSelector } from 'react-redux';
import useDialog from '../hooks/useDialog';
import { dialogComponentSelector, dialogPropsSelector, isDialogOpenSelector } from '../store/ducks/dialog/dialogSelectors';

function DialogProvider() {
  const isOpen = useSelector(isDialogOpenSelector);
  const props = useSelector(dialogPropsSelector);
  const Component = useSelector(dialogComponentSelector);

  const { close, clear } = useDialog();

  if (!Component) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component open={isOpen} onClose={close} onExited={clear} {...props} />;
}

export default DialogProvider;
