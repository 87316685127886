import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../ui/Button';
import DeleteDialog from '../DeleteDialog';
import useDialog from '../../hooks/useDialog';
import useTranslate from '../../hooks/useTranslate';
import { getBusinessesThunk, deleteBusinessThunk } from '../../store/ducks/business/businessThunks';
import { businessesSelector } from '../../store/ducks/business/businessSelectors';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';

const useStyles = makeStyles({});
function BusinessOverview() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { open, close } = useDialog();
  const { t } = useTranslate();

  const [isLoading, setLoading] = useState(false);

  const businesses = useSelector(businessesSelector);
  const adherents = useSelector(visibleAdherentsSelector);

  const handleEdit = (businessId) => {
    history.push(`/affaires/${businessId}/editer`);
  };

  useEffect(() => {
    dispatch(getBusinessesThunk());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await dispatch(deleteBusinessThunk(id));

      close();
      history.replace({ state: { refresh: true } });
      toast.success(t('BusinessOverview.dialog.deleted'));
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const openDelete = async (id) => {
    open(DeleteDialog, { onClose: close, isLoading, onDelete: () => handleDelete(id), title: t('BusinessOverview.dialog.title') });
  };

  const adherentFullName = (adherentId) => {
    return adherents.find((adherent) => adherent.id === adherentId)?.fullName;
  };

  const sorted = [...businesses].sort((a, b) => {
    const key1 = a.date;
    const key2 = b.date;

    if (key1 < key2) {
      return 1;
    }
    if (key1 === key2) {
      return 0;
    }
    return -1;
  });

  return (
    <Box textAlign="right" mt="50px">
      <Box mb="12px">
        <Button variant="contained" onClick={() => history.push('/affaires/ajouter')}>
          <FormattedMessage id="BusinessOverview.create" />
        </Button>
      </Box>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="BusinessOverview.prescriber" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="BusinessOverview.recipient" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="BusinessOverview.businessType" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="BusinessOverview.date" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="BusinessOverview.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((business) => (
              <TableRow key={business.id}>
                <TableCell>{adherentFullName(business.prescriber)}</TableCell>
                <TableCell>{adherentFullName(business.recipient)}</TableCell>
                <TableCell>
                  <FormattedMessage id="Constants.BusinessType" values={{ value: business.businessType }} />
                </TableCell>
                <TableCell align="center">{format(new Date(business.date), 'dd/MM/yyyy')}</TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="green" onClick={() => handleEdit(business.id)}>
                      <FormattedMessage id="BusinessOverview.edit" />
                    </Button>
                    <Box ml="15px">
                      <Button variant="contained" color="orange" onClick={() => openDelete(business.id)}>
                        <FormattedMessage id="BusinessOverview.delete" />
                      </Button>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BusinessOverview;
