/**
 * Memoize function by it's arguments
 * Beware, the arguments must all be primitive
 *
 * @param {Function} f
 * @returns {Function}
 */
function memoizeArguments(f) {
  // eslint-disable-next-line func-names
  return function () {
    // Use arguments to improve perfs
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments);

    // eslint-disable-next-line no-param-reassign
    f.memoize = f.memoize || {};

    // eslint-disable-next-line no-return-assign,no-param-reassign
    return args in f.memoize ? f.memoize[args] : (f.memoize[args] = f.apply(this, args));
  };
}

export default memoizeArguments;
