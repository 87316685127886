/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Box, Select, Grid, FormControl, MenuItem, InputLabel, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../ui/Button';
import useTranslate from '../../hooks/useTranslate';
import { date, object, string, number } from '../../utils/yup';
import TextField from '../v2/TextField';
import KeyboardDatePicker from '../v2/KeyboardDatePicker';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import FormError from '../ui/FormError';
import BUSINESS_TYPE from '../../constants/BusinessType';
import EuroIcon from '../icons/EuroIcon';
import useQuery from '../../hooks/useQuery';

function BusinessForm({ onSubmit, isLoading, business }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const query = useQuery();

  const adherents = useSelector(visibleAdherentsSelector);
  const user = useSelector(userSelector);
  const { isAdmin } = user;

  useEffect(() => {
    dispatch(getAdherentsThunk());
  }, [dispatch]);

  const form = useFormik({
    enableReinitialize: !!business,
    validateOnBlur: true,
    onSubmit,
    initialValues: {
      date: business?.date || new Date(),
      prescriber: business?.prescriber || user.id,
      recipient: business?.recipient || query.get('adherentId') || '',
      businessType: business?.businessType || '',
      translatedBusinessType: t('Constants.BusinessType', { value: business?.businessType }) || '',
      amount: business?.amount / 100 || 0,
      commentary: business?.commentary || '',
    },
    validationSchema: object().shape({
      date: date().required(),
      prescriber: string().required(),
      recipient: string().required(),
      businessType: string().required(),
      amount: number(),
      commentary: string(),
    }),
  });

  const handleChangeBusinessType = (e) => {
    form.setFieldValue('businessType', e.target.value);
    form.setFieldValue('translatedBusinessType', t('Constants.BusinessType', { value: e.target.value }));
  };

  const filteredRecipients = adherents.filter((adherent) => adherent.id !== form.values.prescriber && (user.isAdmin || adherent.id !== user.id));

  const filteredPrescriber = adherents.filter((adherent) => adherent.id !== form.values.recipient);

  return (
    <Box mt="60px">
      <form onSubmit={form.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <KeyboardDatePicker
              label={t('BusinessForm.date')}
              format="dd/MM/yyyy"
              ampm={false}
              value={form.values.date}
              cancelLabel={t('BusinessForm.cancel')}
              error={form.touched.end && form.errors.end}
              onChange={(value) => form.setFieldValue('date', value)}
              onBlur={form.handleChange}
              fullWidth
            />
          </Grid>
          {isAdmin && (
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth error={form.errors.type && form.touched.type}>
                <InputLabel id="prescriber">
                  <FormattedMessage id="BusinessForm.prescriber" />
                </InputLabel>
                <Select
                  fullWidth
                  labelId="prescriber"
                  label={t('BusinessForm.prescriber')}
                  id="prescriber"
                  name="prescriber"
                  value={form.values.prescriber}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={form.touched.prescriber && form.errors.prescriber}
                >
                  {filteredPrescriber.map((prescriber) => (
                    <MenuItem key={prescriber.id} value={prescriber.id}>
                      {prescriber.firstName} {prescriber.lastName}
                    </MenuItem>
                  ))}
                </Select>
                <FormError error={form.touched.type && form.errors.type} />
              </FormControl>
            </Grid>
          )}
          <Grid item md={4}>
            <FormControl variant="outlined" fullWidth error={form.errors.type && form.touched.type}>
              <InputLabel id="recipient">
                <FormattedMessage id="BusinessForm.recipient" />
              </InputLabel>
              <Select
                fullWidth
                labelId="recipient"
                label={t('BusinessForm.recipient')}
                id="recipient"
                name="recipient"
                value={form.values.recipient}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.recipient && form.errors.recipient}
              >
                {filteredRecipients.map((recipient) => (
                  <MenuItem key={recipient.id} value={recipient.id}>
                    {recipient.firstName} {recipient.lastName}
                  </MenuItem>
                ))}
              </Select>
              <FormError error={form.touched.type && form.errors.type} />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl variant="outlined" fullWidth error={form.errors.type && form.touched.type}>
              <InputLabel id="businessType">
                <FormattedMessage id="BusinessForm.businessType" />
              </InputLabel>
              <Select
                fullWidth
                labelId="businessType"
                label={t('BusinessForm.businessType')}
                id="businessType"
                name="businessType"
                value={form.values.businessType}
                onChange={handleChangeBusinessType}
                onBlur={form.handleBlur}
                error={form.touched.businessType && !!form.errors.businessType}
              >
                {Object.values(BUSINESS_TYPE).map((type) => (
                  <MenuItem value={type} key={type}>
                    <FormattedMessage id="Constants.BusinessType" values={{ value: type }} />
                  </MenuItem>
                ))}
              </Select>
              <FormError error={form.touched.businessType && form.errors.businessType} />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <TextField
              label={t('BusinessForm.amount')}
              value={form.values.amount}
              onChange={form.handleChange}
              id="amount"
              name="amount"
              error={form.touched.amount && form.errors.amount}
              onBlur={form.handleBlur}
              fullWidth
              InputProps={{
                endAdornment: <EuroIcon color={theme.palette.grey[400]} size="25px" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('BusinessForm.commentary')}
              value={form.values.commentary}
              onChange={form.handleChange}
              id="commentary"
              name="commentary"
              multiline
              error={form.touched.commentary && form.errors.commentary}
              onBlur={form.handleBlur}
              fullWidth
              rows={8}
            />
          </Grid>
        </Grid>
        <Box maxWidth="280px" mx="auto">
          <Box mt="45px" mb="100px">
            <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
              <FormattedMessage id="BusinessForm.send" />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

BusinessForm.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  business: PropTypes.shape({
    id: PropTypes.string,
    recipient: PropTypes.string,
    prescriber: PropTypes.string,
    businessType: PropTypes.string,
    amount: PropTypes.number,
    commentary: PropTypes.string,
    date: PropTypes.instanceOf(Date),
  }),
};

BusinessForm.defaultProps = {
  event: null,
  isLoading: false,
  business: null,
};

export default BusinessForm;
