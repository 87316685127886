import React, { useState } from 'react';
import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import Button from '../../components/ui/Button';
import { deleteMeetingThunk } from '../../store/ducks/meetings/meetingsThunk';
import DeleteDialog from '../../components/DeleteDialog';
import useDialog from '../../hooks/useDialog';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
  },
  table: {
    minWidth: 650,
  },
  delete: {
    color: theme.palette.common.violet,
  },
}));

function Meetings({ meetings }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { open, close } = useDialog();
  const { t } = useTranslate();

  const [isLoading, setLoading] = useState(false);

  const handleEdit = (meetingId) => {
    history.push(`/presences/${meetingId}/editer`);
  };

  const handleClose = () => {
    close();
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await dispatch(deleteMeetingThunk(id));

      handleClose();
      toast.success(t('Meetings.dialog.deleted'));
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const openDelete = async (meetingId) => {
    open(DeleteDialog, { onClose: close, isLoading, onDelete: () => handleDelete(meetingId), title: t('Meetings.dialog.title') });
  };

  const sorted = [...meetings].sort((a, b) => {
    const key1 = a.date;
    const key2 = b.date;

    if (key1 < key2) {
      return 1;
    }
    if (key1 === key2) {
      return 0;
    }
    return -1;
  });

  return (
    <Box textAlign="right">
      <Box mb="12px">
        <Button variant="contained" onClick={() => history.push('/presences/ajouter')}>
          <FormattedMessage id="Meetings.create" />
        </Button>
      </Box>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="Meetings.name" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="Meetings.date" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="Meetings.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((meeting) => (
              <TableRow key={meeting.id}>
                <TableCell>{meeting.name}</TableCell>
                <TableCell align="center">{format(new Date(meeting.date), 'dd/MM/yyyy')}</TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="green" onClick={() => handleEdit(meeting.id)}>
                      <FormattedMessage id="Meetings.edit" />
                    </Button>
                    <Box ml="15px">
                      <Button variant="contained" color="orange" onClick={() => openDelete(meeting.id)}>
                        <FormattedMessage id="Meetings.delete" />
                      </Button>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

Meetings.propTypes = {
  meetings: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, participants: PropTypes.arrayOf(PropTypes.string) })).isRequired,
};

export default Meetings;
