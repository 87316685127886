import React, { useState } from 'react';
import { Box, Grid, makeStyles, Container } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import AdherentCard from '../../components/AdherentCard';
import AppLayout from '../../components/AppLayout';
import Typography from '../../components/ui/Typography';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import Button from '../../components/ui/Button';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import CenteredCircularProgress from '../../components/ui/CenteredCircularProgress';
import useTranslate from '../../hooks/useTranslate';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  customContainer: {
    maxWidth: '1440px',
    position: 'relative',
  },
  createButton: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    position: 'absolute',
    marginRight: '20px',
    top: 0,
    right: '20px',
  },
  gridContainer: {
    maxWidth: '432px',
    margin: '15px',
  },
}));

function Adherents() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslate();

  const adherents = useSelector(visibleAdherentsSelector);
  const user = useSelector(userSelector);
  const [isLoading, setLoading] = useState(true);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await dispatch(getAdherentsThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return (
    <AppLayout>
      {isLoading ? (
        <CenteredCircularProgress />
      ) : (
        <Container className={classes.customContainer}>
          <Box textAlign="center" mb="50px" mt="50px">
            <Typography variant="h1">
              <FormattedMessage id="Adherents.title" />
            </Typography>
          </Box>
          {user.isAdmin && (
            <Box className={classes.createButton}>
              <Button component={Link} to="/utilisateurs" variant="contained">
                <FormattedMessage id="Adherents.users" />
              </Button>
            </Box>
          )}
          <Grid container justify="center">
            {adherents?.map((adherent) => (
              <Grid item xs={12} md={6} lg={4} className={classes.gridContainer} key={adherent.id}>
                <AdherentCard adherent={adherent} />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </AppLayout>
  );
}

export default Adherents;
