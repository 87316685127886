import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/ui/Typography';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { deleteGalleryImageThunk, getGalleriesThunk, uploadGalleryImageThunk } from '../../store/ducks/news/newsThunks';
import useTranslate from '../../hooks/useTranslate';
import { galleryByIdSelector } from '../../store/ducks/news/newsSelector';
import AppLayout from '../../components/AppLayout';
import Button from '../../components/ui/Button';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import DeleteDialog from '../../components/DeleteDialog';
import useDialog from '../../hooks/useDialog';
import TripleDotsIcon from '../../components/icons/TripleDotsIcon';
import NewsPopover from '../../components/News/NewsPopover';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  inputFile: {
    display: 'none',
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: theme.palette.grey[300],
    cursor: 'pointer',
    height: '100%',
    justifyContent: 'center',
  },
  toolsButton: {
    border: '1px solid',
    borderRadius: '5px',
    width: '25px',
    height: '25px',
    position: 'absolute',
    bottom: '-10px',
    right: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    cursor: 'pointer',
  },
}));

function Gallery() {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const classes = useStyles();
  const inputFile = useRef();
  const { open, close } = useDialog();
  const { id } = params;

  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const gallery = useSelector(galleryByIdSelector(id));
  const user = useSelector(userSelector);

  useEffect(() => {
    if (file) {
      if (!gallery?.images.find((image) => image?.title === file?.name)) {
        try {
          dispatch(uploadGalleryImageThunk({ file, gallery, userId: user.id }, 'galleries'));
          setFile(null);
          toast.success(t('Gallery.fileUploaded'));
        } catch (err) {
          toast.error(t('Errors.api.default'));
          setFile(null);
        }
      } else {
        toast.error(t('Gallery.fileAlreadyUploaded'));
        setFile(null);
      }
    }
  }, [dispatch, file, gallery, t, user?.id]);

  useAsyncEffect(async () => {
    try {
      await dispatch(getGalleriesThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    }
  }, [dispatch]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputFile.current.click();
  };

  const handleDelete = async (fileName) => {
    try {
      setLoading(true);
      await dispatch(deleteGalleryImageThunk({ fileName, gallery }));

      setAnchorEl(null);
      close();
      toast.success(t('Gallery.dialog.deleted'));
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  };

  const handleClickMenu = (e, image) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedImage(image);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelete = (fileName) => {
    open(DeleteDialog, { onDelete: () => handleDelete(fileName), isLoading, title: t('Gallery.dialog.title'), onClose: close });
  };

  const handleClickOpen = (e, url) => {
    e.stopPropagation();
    e.preventDefault();

    window.open(url);
  };

  return (
    <AppLayout>
      <Container>
        <input
          type="file"
          ref={inputFile}
          className={classes.inputFile}
          onChange={(e) => {
            setFile(e.target.files[0]);
            e.target.value = null;
          }}
          accept="image/png, image/jpeg"
        />
        <Box>
          <Box position="relative" textAlign="center" my="50px">
            <Typography variant="h1">
              <FormattedMessage id="Gallery.title" />
            </Typography>
            <Button variant="contained" className={classes.button} onClick={handleClick}>
              <FormattedMessage id="Gallery.add" />
            </Button>
          </Box>
          <Grid container spacing={2}>
            {gallery?.images?.map((image) => (
              <Grid item md={6} className={classes.imageContainer} key={image.fileName}>
                <Box className={classes.fileContainer} onClick={(e) => handleClickOpen(e, image.url)}>
                  <img src={image.url} alt="gallery" className={classes.image} />
                </Box>
                <Box>
                  <Box position="relative">
                    <Typography onClick={(e) => handleClickOpen(e, image.url)} className={classes.title}>
                      {image.title}
                    </Typography>
                    <Box className={classes.toolsButton} onClick={(e) => handleClickMenu(e, image)}>
                      <TripleDotsIcon size="15px" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <NewsPopover
          onClose={handleClose}
          onDelete={() => openDelete(selectedImage.fileName)}
          onClickDownload={(e) => handleClickOpen(e, selectedImage.url)}
          anchorEl={anchorEl}
          firstOptionText={t('Gallery.openImage')}
          deleteText={t('Gallery.delete')}
          elementId={selectedImage?.userId}
        />
      </Container>
    </AppLayout>
  );
}

export default Gallery;
