import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import UserCard from '../../components/User/UserCard';
import Typography from '../../components/ui/Typography';
import AppLayout from '../../components/AppLayout';
import Button from '../../components/ui/Button';
import { visibleAdherentsSelector } from '../../store/ducks/adherents/adherentsSelectors';
import { getAdherentsThunk } from '../../store/ducks/adherents/adherentsThunk';
import { userSelector } from '../../store/ducks/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginBottom: '60px',
    marginTop: '50px',
  },
  addButton: {
    position: 'absolute',
    top: 0,
    right: '15px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      display: 'flex',
      margin: 'auto',
    },
  },
}));

function Users() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const users = useSelector(visibleAdherentsSelector);
  const currentUser = useSelector(userSelector);

  useEffect(() => dispatch(getAdherentsThunk()), [dispatch]);

  if (!currentUser.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <AppLayout>
      <Box>
        <Box position="relative">
          <Typography variant="h1" className={classes.title}>
            <FormattedMessage id="Users.title" />
          </Typography>
          <Button component={Link} to="/utilisateurs/ajouter" type="button" className={classes.addButton} variant="contained">
            <FormattedMessage id="Users.add" />
          </Button>
        </Box>
        <Box mt="30px">
          {users.map((user) => (
            <UserCard user={user} key={user.id} />
          ))}
        </Box>
      </Box>
    </AppLayout>
  );
}

export default Users;
