/* eslint-disable no-undef */
import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    height: '242px',
    borderRadius: '15px',
    margin: '0 auto',
    marginTop: '60px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '660px',
    },
    zIndex: 9999,
  },
}));

function GoogleMap({ lat, lng }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Map
        google={google}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        containerStyle={{ position: 'relative' }}
        style={{ position: undefined, width: '100%', height: '242px', borderRadius: '15px' }}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    </Box>
  );
}

GoogleMap.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
};

GoogleMap.defaultProps = {
  lng: null,
  lat: null,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMap);
