/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../hooks/useTranslate';

function withError(Component) {
  function ComponentWithError({ error, ...props }) {
    const { t } = useTranslate();
    let formattedError = error;
    if ('object' === typeof formattedError) {
      formattedError = t(formattedError.key, formattedError.values);
    }

    return <Component error={!!error} helperText={formattedError} {...props} />;
  }

  ComponentWithError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  ComponentWithError.defaultProps = {
    error: undefined,
  };

  return ComponentWithError;
}

export default withError;
