/* eslint-disable react/forbid-prop-types */
import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import TextField from '../v2/TextField';
import { userSelector } from '../../store/ducks/auth/authSelectors';

function ContactInformationsForm({ form }) {
  const { t } = useTranslate();
  const user = useSelector(userSelector);

  return (
    <>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.firstName.label')}
          placeholder={t('AdherentForm.firstName.placeholder')}
          value={form.values.firstName}
          onChange={form.handleChange}
          id="firstName"
          name="firstName"
          error={form.touched.firstName && form.errors.firstName}
          onBlur={form.handleBlur}
          fullWidth
        />
      </Box>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.lastName.label')}
          placeholder={t('AdherentForm.lastName.placeholder')}
          value={form.values.lastName}
          onChange={form.handleChange}
          id="lastName"
          name="lastName"
          error={form.touched.lastName && form.errors.lastName}
          onBlur={form.handleBlur}
          fullWidth
        />
      </Box>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.companyName.label')}
          placeholder={t('AdherentForm.companyName.placeholder')}
          value={form.values.companyName}
          onChange={form.handleChange}
          id="companyName"
          name="companyName"
          error={form.touched.companyName && form.errors.companyName}
          onBlur={form.handleBlur}
          fullWidth
        />
      </Box>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.email.label')}
          helperText={
            user.isAdmin ? "Vous pouvez changer l'adresse e-mail dans la gestion des utilisateurs" : 'Vous pouvez changer votre adresse e-mail dans l\'onglet "mon compte"'
          }
          placeholder={t('AdherentForm.email.placeholder')}
          value={form.values.email}
          id="email"
          name="email"
          fullWidth
          disabled
        />
      </Box>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.phone.label')}
          placeholder={t('AdherentForm.phone.placeholder')}
          value={form.values.phone}
          onChange={form.handleChange}
          id="phone"
          name="phone"
          error={form.touched.phone && form.errors.phone}
          onBlur={form.handleBlur}
          fullWidth
        />
      </Box>
      <Box mt="85px">
        <TextField
          label={t('AdherentForm.secondaryPhone.label')}
          placeholder={t('AdherentForm.secondaryPhone.placeholder')}
          value={form.values.secondaryPhone}
          onChange={form.handleChange}
          id="secondaryPhone"
          name="secondaryPhone"
          error={form.touched.secondaryPhone && form.errors.secondaryPhone}
          onBlur={form.handleBlur}
          fullWidth
        />
      </Box>
    </>
  );
}

ContactInformationsForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
      secondaryPhone: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      companyName: PropTypes.string,
    }),
    setFieldValue: PropTypes.func,
    touched: PropTypes.shape({
      email: PropTypes.bool,
      phone: PropTypes.bool,
      secondaryPhone: PropTypes.bool,
      firstName: PropTypes.bool,
      lastName: PropTypes.bool,
      companyName: PropTypes.bool,
    }),
    errors: PropTypes.shape({
      email: PropTypes.object,
      phone: PropTypes.object,
      secondaryPhone: PropTypes.object,
      firstName: PropTypes.object,
      lastName: PropTypes.object,
      companyName: PropTypes.object,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
  }).isRequired,
};

export default ContactInformationsForm;
