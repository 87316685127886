import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function PhoneIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 30 30">
      <g transform="translate(-1508 -3039)">
        <path d="M15,0H15A14.991,14.991,0,0,0,2.856,23.792L.986,29.364l5.766-1.843A15,15,0,1,0,15,0Z" transform="translate(1508 3039)" fill={color} />
        <path
          d="M125.8,132.463a4.5,4.5,0,0,1-3.127,2.273c-.833.179-1.92.322-5.581-1.212-4.683-1.961-7.7-6.77-7.933-7.082a9.248,9.248,0,0,1-1.892-4.857,5.164,5.164,0,0,1,1.629-3.919,2.3,2.3,0,0,1,1.629-.578c.2,0,.374.01.534.018.468.02.7.048,1.012.8.384.936,1.321,3.247,1.432,3.484a.967.967,0,0,1,.068.872,2.783,2.783,0,0,1-.516.739c-.235.274-.458.483-.693.777-.215.256-.458.529-.187,1a14.273,14.273,0,0,0,2.585,3.247,11.689,11.689,0,0,0,3.736,2.329,1,1,0,0,0,1.123-.179,19.463,19.463,0,0,0,1.245-1.667.884.884,0,0,1,1.143-.35c.43.151,2.707,1.288,3.175,1.524s.777.35.89.55A4.05,4.05,0,0,1,125.8,132.463Z"
          transform="translate(1406.453 2928.152)"
          fill="#fafafa"
        />
      </g>
    </SvgIcon>
  );
}

PhoneIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

PhoneIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default PhoneIcon;
