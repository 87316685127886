import { createSelector } from 'reselect';
import memoizeArguments from '../../../utils/memoizeArguments';

export const eventsByIdSelector = (state) => state.events.byId;

export const eventsSelector = createSelector(eventsByIdSelector, (byId) => Object.values(byId));

export const eventByIdSelector = memoizeArguments((id) => {
  return createSelector(eventsByIdSelector, (byId) => {
    return byId[id];
  });
});
