// eslint-disable-next-line no-restricted-imports
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import withError from './withError';

// TODO Remove V2 Folder

const KeyboardDatePicker = withError(MuiKeyboardDatePicker);

KeyboardDatePicker.propTypes = MuiKeyboardDatePicker.propTypes;

export default KeyboardDatePicker;
