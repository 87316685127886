import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function CloseIcon({ color, size }) {
  return (
    <SvgIcon size={size} fill={color}>
      <path
        d="M23.5,3.1c0.7-0.7,0.7-1.9,0-2.6s-1.9-0.7-2.6,0L12,9.4L3.1,0.5c-0.7-0.7-1.9-0.7-2.6,0s-0.7,1.9,0,2.6L9.4,12l-8.9,8.9
	c-0.7,0.7-0.7,1.9,0,2.6s1.9,0.7,2.6,0l8.9-8.9l8.9,8.9c0.7,0.7,1.9,0.7,2.6,0s0.7-1.9,0-2.6L14.6,12L23.5,3.1z"
      />
    </SvgIcon>
  );
}

CloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};
CloseIcon.defaultProps = {
  size: 'default',
  color: 'black',
};

export default CloseIcon;
