import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../ui/SvgIcon';

function EuroIcon({ color, size, className }) {
  return (
    <SvgIcon className={className} size={size} viewBox="0 0 1280.000000 1207.000000" fill="none">
      <g transform="translate(0.000000,1207.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path
          d="M6980 10494 c-19 -2 -91 -9 -160 -15 -508 -43 -1022 -209 -1448 -467
-851 -517 -1449 -1362 -1706 -2414 -25 -101 -48 -200 -52 -220 l-6 -38 -383 0
-383 0 -30 -25 c-52 -43 -53 -54 -50 -409 l3 -328 37 -34 38 -34 326 0 327 0
-6 -122 c-4 -66 -7 -188 -7 -270 l0 -148 -302 0 c-339 0 -351 -2 -396 -70
l-23 -34 3 -324 3 -323 42 -40 42 -39 351 0 c272 0 351 -3 354 -12 2 -7 16
-85 31 -173 111 -651 358 -1254 701 -1711 391 -522 884 -900 1469 -1127 815
-317 1774 -325 2620 -22 147 52 471 209 587 284 128 83 129 35 -2 519 -62 229
-116 425 -121 434 -12 22 -80 58 -109 58 -12 0 -94 -36 -183 -79 -335 -164
-663 -263 -1014 -307 -167 -20 -469 -20 -638 1 -501 61 -941 264 -1286 592
-326 311 -578 809 -693 1373 -14 69 -28 135 -31 148 l-5 22 1715 0 c1873 0
1747 -4 1794 56 20 26 21 37 21 358 l0 332 -40 39 -41 40 -1776 3 -1776 2 6
261 c4 143 9 264 12 269 4 6 661 10 1774 10 l1769 0 36 40 36 40 0 336 0 336
-39 39 -39 39 -1696 0 c-1073 0 -1696 4 -1696 10 0 5 15 70 34 143 115 449
304 845 531 1117 83 99 281 292 377 368 235 184 553 329 851 387 190 37 348
48 572 42 423 -13 809 -104 1152 -274 143 -71 164 -74 228 -35 28 18 37 42
163 458 73 241 132 448 132 460 0 35 -22 51 -155 117 -482 238 -1085 371
-1655 365 -85 0 -171 -3 -190 -4z"
        />
      </g>
    </SvgIcon>
  );
}

EuroIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

EuroIcon.defaultProps = {
  size: '35px',
  color: 'black',
  className: '',
};

export default EuroIcon;
