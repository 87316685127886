import pages from './pages';
import components from './components';
import Errors from './Errors.json';
import Constants from './Constants';
import Common from './Common.json';

export default {
  ...pages,
  ...components,
  Errors,
  Constants,
  Common,
};
