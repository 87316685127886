import { combineReducers } from 'redux';
import adherentsReducer from './adherents/adherentsReducer';
import authReducer from './auth/authReducer';
import dialogReducer from './dialog/dialogReducer';
import eventsReducer from './events/eventsReducer';
import businessReducer from './business/businessReducer';
import newsReducer from './news/newsReducer';
import meetingsReducer from './meetings/meetingsReducer';

const createRootReducer = combineReducers({
  adherents: adherentsReducer,
  auth: authReducer,
  dialog: dialogReducer,
  events: eventsReducer,
  business: businessReducer,
  news: newsReducer,
  meetings: meetingsReducer,
});

export default createRootReducer;
