export const MEETINGS_ACTIONS = {
  push: 'MEETINGS/PUSH',
  delete: 'MEETINGS/DELETE',
};

const initialState = {
  meetings: [],
};

function meetingsReducer(state = initialState, action) {
  switch (action.type) {
    case MEETINGS_ACTIONS.push: {
      return {
        ...state,
        meetings: action.payload.meetings,
      };
    }
    case MEETINGS_ACTIONS.delete: {
      return {
        ...state,
        meetings: state.meetings.filter((meeting) => meeting.id !== action.payload.id),
      };
    }
    default: {
      return state;
    }
  }
}

export default meetingsReducer;
