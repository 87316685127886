// eslint-disable-next-line consistent-return
import firebase, { auth, firestore } from '../../../firebase';
import { ADHERENTS_ACTIONS } from './adherentsReducer';
import { userSelector } from '../auth/authSelectors';
import { logoutThunk } from '../auth/authThunks';

export const createUserThunk = ({ email, firstName, lastName, isAdmin }) => {
  return async () => {
    const createUserAuth = firebase.functions().httpsCallable('httpsCreateUser');
    await createUserAuth({ email, isAdmin, firstName, lastName });
    await auth.sendSignInLinkToEmail(email, {
      url: `${process.env.REACT_APP_LOGIN_PAGE_URL}?email=${email}`,
      handleCodeInApp: true,
    });
  };
};

export const deleteUserThunk = (id) => {
  return async (dispatch) => {
    await firestore.collection('adherents').doc(id).delete();

    return dispatch({
      type: ADHERENTS_ACTIONS.delete,
      payload: { id },
    });
  };
};

export const resetPasswordThunk = (email) => {
  return async () => {
    await auth.sendSignInLinkToEmail(email, {
      url: `${process.env.REACT_APP_LOGIN_PAGE_URL}?email=${email}`,
      handleCodeInApp: true,
    });
  };
};

export const updateUserThunk = ({ firstName, lastName, email, isAdmin, id }) => {
  return async (dispatch, getState) => {
    const userData = {
      id,
      firstName,
      lastName,
      isAdmin,
      email,
    };

    const updateUser = firebase.functions().httpsCallable('httpsUpdateUser');
    await updateUser({ userId: id, email, isAdmin, firstName, lastName });

    const user = userSelector(getState());
    if (id === user.id) {
      await dispatch(logoutThunk());
    }

    return dispatch({
      type: ADHERENTS_ACTIONS.update,
      payload: { user: { ...userData, id } },
    });
  };
};
