import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { toast } from 'react-hot-toast';
import { Box, CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useTranslate from '../../hooks/useTranslate';
import { galleriesSelector } from '../../store/ducks/news/newsSelector';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { getGalleriesThunk } from '../../store/ducks/news/newsThunks';
import Typography from '../../components/ui/Typography';
import GalleryPreview from '../../components/News/GalleryPreview';

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: '30px',
    padding: '24px',
    boxShadow: '1px 1px 4px 2px rgba(0,0,0, 0.1)',
    borderRadius: '15px',
  },
  gallery: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

function GalleryResult({ word }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const galleries = useSelector(galleriesSelector);
  const classes = useStyle();

  const [isLoading, setLoading] = useState(true);

  const options = {
    keys: ['title'],
  };

  const fuse = new Fuse(galleries, options);
  const filteredGalleries = fuse.search(word);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await dispatch(getGalleriesThunk());
    } catch (err) {
      toast.error(t('Errors.api.default'));
    } finally {
      setLoading(false);
    }
  }, [word]);

  if (isLoading) {
    return (
      <Box textAlign="center" mt="24px">
        <CircularProgress />
      </Box>
    );
  }
  if (0 < filteredGalleries.length) {
    return (
      <Paper className={classes.paper}>
        <Box mb="24px">
          <Typography variant="h1">
            <FormattedMessage id="Research.gallery.title" />
          </Typography>
        </Box>
        <Grid container spacing={2} xs={12}>
          {filteredGalleries?.map((gallery) => (
            <GalleryPreview gallery={gallery.item} key={gallery.item.id} />
          ))}
        </Grid>
      </Paper>
    );
  }
  return null;
}

GalleryResult.propTypes = {
  word: PropTypes.string.isRequired,
};

export default GalleryResult;
