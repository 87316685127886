import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Typography from '../ui/Typography';
import useTranslate from '../../hooks/useTranslate';
import NewsPopover from './NewsPopover';
import { deleteGalleryThunk } from '../../store/ducks/news/newsThunks';
import DeleteDialog from '../DeleteDialog';
import useDialog from '../../hooks/useDialog';
import { userSelector } from '../../store/ducks/auth/authSelectors';
import TripleDotsIcon from '../icons/TripleDotsIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '216px',
  },
  imageContainer: {
    minHeight: '170px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px',
  },
  clickableContent: {
    cursor: 'pointer',
  },
  noImage: {
    width: '100%',
    height: '170px',
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px',
  },
  image: {
    width: '100%',
    objectFit: 'contain',
  },
  toolsButton: {
    border: '1px solid',
    borderRadius: '5px',
    width: '25px',
    height: '25px',
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolsButtonText: {
    textAlign: 'center',
    lineHeight: '13px',
  },
}));

function GalleryPreview({ gallery }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { open, close } = useDialog();

  const [anchorEl, setAnchorEl] = useState(null);

  const user = useSelector(userSelector);

  const handleDelete = async () => {
    try {
      await dispatch(deleteGalleryThunk(gallery.id));

      close();
      toast.success(t('GalleryPreview.dialog.deleted'));
    } catch (err) {
      toast.error(toast.error(t('Errors.api.default')));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelete = () => {
    open(DeleteDialog, { onDelete: () => handleDelete(), title: t('GalleryPreview.dialog.title'), onClose: close });
  };

  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <Box onClick={() => history.push(`/galerie/${gallery.id}`)} className={clsx(classes.clickableContent)}>
        {0 === gallery?.images?.length ? (
          <Box className={classes.noImage} />
        ) : (
          <Box className={classes.imageContainer}>
            <img src={gallery?.images[0].url} alt="gallery" className={classes.image} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" mt="5px" position="relative">
        <Typography onClick={() => history.push(`/galerie/${gallery.id}`)} className={classes.clickableContent}>
          {gallery.title}
        </Typography>
        {(gallery?.userId === user?.id || user?.isAdmin) && (
          <Box className={classes.toolsButton} onClick={handleClick}>
            <TripleDotsIcon size="15px" />
          </Box>
        )}
      </Box>
      <NewsPopover
        onClose={handleClose}
        onDelete={openDelete}
        elementId={gallery?.userId}
        anchorEl={anchorEl}
        deleteText={t('Galleries.delete')}
        firstOptionText={t('NewsPopover.download')}
      />
    </Grid>
  );
}

GalleryPreview.propTypes = {
  gallery: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    id: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
};

export default GalleryPreview;
