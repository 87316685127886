import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../ui/Typography';

const useStyles = makeStyles((theme) => ({
  textContainers: {
    minWidth: '250px',
    marginTop: '60px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '660px',
    },
  },
  text: {
    whiteSpace: 'pre-line',
  },
}));

function Products({ adherent }) {
  const classes = useStyles();

  return (
    <Grid item lg={6} xs={12}>
      <Box className={classes.textContainers}>
        <Typography variant="h2">
          <FormattedMessage id="Adherent.products" />
        </Typography>
        <Box mt="20px">
          <Typography className={classes.text}>{adherent?.products}</Typography>
          {!adherent?.products && <Typography>-</Typography>}
        </Box>
      </Box>
    </Grid>
  );
}

Products.propTypes = {
  adherent: PropTypes.shape({
    products: PropTypes.string,
  }),
};

Products.defaultProps = {
  adherent: null,
};

export default Products;
