import { firestore } from '../../../firebase';
import snapshotToEntity from '../../../utils/snapshotToEntity';
import { pushEventsAction } from './eventsActions';
import { userSelector } from '../auth/authSelectors';
import { eventByIdSelector } from './eventsSelectors';

export function fetchEventsThunk() {
  return async (dispatch) => {
    const snapshot = await firestore.collection('events').get();
    const events = snapshot.docs.map((doc) => snapshotToEntity(doc));
    dispatch(pushEventsAction(events));
  };
}

export function fetchEventThunk(id) {
  return async (dispatch) => {
    const snapshot = await firestore.collection('events').doc(id).get();
    dispatch(pushEventsAction([snapshotToEntity(snapshot)]));
  };
}

export function createEventThunk(data) {
  return async (dispatch, getState) => {
    const user = userSelector(getState());

    const { id } = await firestore.collection('events').add({ ...data, participations: [], userId: user.id });
    dispatch(fetchEventThunk(id));
  };
}

export function updateEventThunk(id, data) {
  return async (dispatch) => {
    await firestore.collection('events').doc(id).update(data);
    dispatch(fetchEventThunk(id));
  };
}

export function updateParticipationThunk(id, userId, participate) {
  return async (dispatch, getState) => {
    const event = eventByIdSelector(id)(getState());
    const existingParticipations = event?.participations?.filter((participation) => participation.userId !== userId) ?? [];

    await firestore
      .collection('events')
      .doc(id)
      .update({
        participations: [
          ...existingParticipations,
          {
            userId,
            participate,
          },
        ],
      });

    dispatch(fetchEventThunk(id));
  };
}
