import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, Paper, makeStyles, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { setHours, setMinutes } from 'date-fns';
import { useHistory, useLocation } from 'react-router';
import Typography from '../ui/Typography';
import { getBusinessAdherentsByDateThunk, getBusinessAdherentsThunk } from '../../store/ducks/business/businessThunks';
import { sortedBusinessAdherentsSelector } from '../../store/ducks/business/businessSelectors';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import Button from '../ui/Button';
import useTranslate from '../../hooks/useTranslate';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  sticky: {
    backgroundColor: theme.palette.common.white,
    left: 0,
    position: 'sticky',
    zIndex: 200,
  },
}));

function BusinessStatistics() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { state } = useLocation();
  const history = useHistory();

  const businessAdherents = useSelector(sortedBusinessAdherentsSelector);

  const [isLoading, setLoading] = useState(false);
  const [isAsyncLoading, setAsyncLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);

  useAsyncEffect(async () => {
    if (state?.refresh) {
      try {
        setAsyncLoading(true);
        await new Promise((resolve) => {
          setTimeout(() => resolve(), 2000);
        });
        await dispatch(getBusinessAdherentsThunk());
        history.replace({ state: {} });
      } finally {
        setAsyncLoading(false);
      }
    }
  }, [state]);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      if (from || to) {
        const fromFormatted = from ? setHours(setMinutes(from, 0), 0) : undefined;
        const toFormatted = to ? setHours(setMinutes(to, 59), 23) : undefined;
        await dispatch(getBusinessAdherentsByDateThunk(fromFormatted, toFormatted));
      } else {
        await dispatch(getBusinessAdherentsThunk());
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, from, to]);

  return (
    <>
      <Box mt="52px" textAlign="center">
        <Typography variant="h1" color={theme.palette.primary.main}>
          <FormattedMessage id="BusinessStatistics.title" />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt="30px">
        {filter ? (
          <Box display="flex" alignItems="center">
            <KeyboardDatePicker
              label={t('BusinessStatistics.from')}
              format="dd/MM/yyyy"
              ampm={false}
              value={from || null}
              cancelLabel={t('Common.cancel')}
              onChange={(value) => setFrom(value)}
            />
            <Box mx="15px">
              <Typography> - </Typography>
            </Box>
            <KeyboardDatePicker
              label={t('BusinessStatistics.to')}
              format="dd/MM/yyyy"
              ampm={false}
              value={to || null}
              cancelLabel={t('Common.cancel')}
              onChange={(value) => setTo(value)}
            />
          </Box>
        ) : (
          <Button variant="contained" color="orange" onClick={() => setFilter(true)}>
            <FormattedMessage id="BusinessStatistics.filter" />
          </Button>
        )}
      </Box>
      <Box mt="50px">
        {isLoading || isAsyncLoading ? (
          <Box width="100%" mt="100px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.member" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.numberHeadToHead" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.numberRecommendationGiven" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.numberRecommendationReceived" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.numberBusinessGiven" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.numberBusinessReceived" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.amountGiven" />
                  </TableCell>
                  <TableCell className={classes.sticky}>
                    <FormattedMessage id="BusinessStatistics.amountReceived" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessAdherents.map((adherent) => {
                  return (
                    <TableRow key={adherent.id}>
                      <TableCell component="th" scope="row" className={classes.sticky}>
                        {adherent.firstName} {adherent.lastName}
                      </TableCell>
                      <TableCell align="center">{adherent.headToHead}</TableCell>
                      <TableCell align="center">{adherent.recommendationGiven}</TableCell>
                      <TableCell align="center">{adherent.recommendationReceived}</TableCell>
                      <TableCell align="center">{adherent.businessGiven}</TableCell>
                      <TableCell align="center">{adherent.businessReceived}</TableCell>
                      <TableCell align="center">{adherent.amountGiven / 100}</TableCell>
                      <TableCell align="center">{adherent.amountReceived / 100}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
}

export default BusinessStatistics;
