export default function snapshotToEntity(snapshot) {
  const output = {
    id: snapshot.id,
    ...snapshot.data(),
  };
  Object.keys(output).forEach((key) => {
    if (output[key]?.seconds !== undefined && output[key].nanoseconds !== undefined) {
      output[key] = output[key].toDate();
    }
  });

  return output;
}
