import React from 'react';
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

const availableSizes = {
  small: '16px',
  medium: '24px',
  default: '24px',
  large: '40px',
};

const useStyles = makeStyles(
  (theme) => ({
    color: {
      fill: ({ fill }) => _.get(theme.palette, `${fill}.main`, fill),
      stroke: ({ stroke }) => _.get(theme.palette, `${stroke}.main`, stroke),
    },
    size: {
      width: ({ size }) => availableSizes[size] || size,
      height: ({ size }) => availableSizes[size] || size,
    },
  }),
  { name: 'svg' }
);

function SvgIcon({ viewBox, size, fill, stroke, children, className }) {
  const classes = useStyles({ fill, stroke, size });

  return (
    <MuiSvgIcon viewBox={viewBox} className={`${classes.color} ${classes.size} ${className}`}>
      {children}
    </MuiSvgIcon>
  );
}

SvgIcon.propTypes = {
  viewBox: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  stroke: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  fill: 'none',
  stroke: 'none',
  children: '',
  size: 'medium',
  viewBox: '0 0 24 24',
  className: '',
};

export default SvgIcon;
