import { differenceBy, intersectionBy } from 'lodash';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { adherentsSelector } from '../adherents/adherentsSelectors';
import { getAdherentsThunk } from '../adherents/adherentsThunk';
import firebase, { firestore } from '../../../firebase';
import { BUSINESS_ACTIONS } from './businessReducer';
import snapshotToEntity from '../../../utils/snapshotToEntity';

export function getBusinessStatisticsThunk() {
  return async () => {
    const snapshot = await firestore.collection('businessStatistics').get();
    return snapshot.docs.map((doc) => snapshotToEntity(doc));
  };
}

export function getBusinessAdherentsThunk() {
  return async (dispatch, getState) => {
    await dispatch(getAdherentsThunk());

    const businessStatisticsData = await dispatch(getBusinessStatisticsThunk());

    const adherents = adherentsSelector(getState());

    const intersection = intersectionBy(businessStatisticsData, adherents, 'id');
    const difference = differenceBy(adherents, businessStatisticsData, 'id');

    const cleanedDiff = difference.map((adherent) => {
      return {
        id: adherent.id,
        firstName: adherent.firstName,
        lastName: adherent.lastName,
        headToHead: 0,
        recommendationGiven: 0,
        recommendationReceived: 0,
        businessGiven: 0,
        businessReceived: 0,
        amountGiven: 0,
        amountReceived: 0,
      };
    });

    const dataToDisplay = intersection.concat(cleanedDiff);

    dispatch({
      type: BUSINESS_ACTIONS.getAdherents,
      payload: { adherents: dataToDisplay },
    });

    return dataToDisplay;
  };
}

export function getBusinessAdherentsByDateThunk(from, to) {
  return async (dispatch) => {
    const sendBusinessEmail = firebase.functions().httpsCallable('httpsDynamicStatistics');
    const { data } = await sendBusinessEmail({ from, to });

    dispatch({
      type: BUSINESS_ACTIONS.getAdherents,
      payload: { adherents: data },
    });

    return data;
  };
}

export function getBusinessesThunk() {
  return async (dispatch) => {
    const businessSnap = await firestore.collection('business').get();
    const businesses = businessSnap.docs.map((doc) => snapshotToEntity(doc));

    dispatch({
      type: BUSINESS_ACTIONS.getBusinesses,
      payload: { businesses },
    });
    return businesses;
  };
}

export function deleteBusinessThunk(id) {
  return async (dispatch) => {
    await firestore.collection('business').doc(id).delete();
    await dispatch({
      type: BUSINESS_ACTIONS.deleteBusiness,
      payload: { id },
    });

    await dispatch(getBusinessAdherentsThunk());
  };
}

export const sendBusinessEmailThunk = (data) => {
  return async () => {
    const sendBusinessEmail = firebase.functions().httpsCallable('httpsSendBusinessEmail');
    await sendBusinessEmail(data);
  };
};

export const createBusinessThunk = ({ date, prescriber, recipient, businessType, amount, commentary, translatedBusinessType }) => {
  return async (dispatch, getState) => {
    await firestore.collection('business').add({ date, prescriber, recipient, businessType, amount: amount * 100, commentary });

    const adherents = adherentsSelector(getState());

    const adherentPrescriber = adherents.find((adherent) => adherent.id === prescriber);
    const adherentRecipient = adherents.find((adherent) => adherent.id === recipient);

    const formattedDate = format(date, 'dd MMMM yyyy', { locale: frLocale });

    await dispatch(
      sendBusinessEmailThunk({
        date: formattedDate,
        businessType: translatedBusinessType,
        amount,
        commentary,
        sender: {
          name: [adherentPrescriber.firstName, adherentPrescriber.lastName].join(' '),
          email: adherentPrescriber.email,
        },
        recipient: {
          name: [adherentRecipient.firstName, adherentRecipient.lastName].join(' '),
          email: adherentRecipient.email,
        },
      })
    );
  };
};

export const editBusinessThunk = (id, { date, prescriber, recipient, businessType, amount, commentary, translatedBusinessType }) => {
  return async (dispatch, getState) => {
    await firestore
      .collection('business')
      .doc(id)
      .set({ id, date, prescriber, recipient, businessType, amount: amount * 100, commentary });

    const adherents = adherentsSelector(getState());

    const adherentPrescriber = adherents.find((adherent) => adherent.id === prescriber);
    const adherentRecipient = adherents.find((adherent) => adherent.id === recipient);

    const formattedDate = format(date, 'dd MMMM yyyy', { locale: frLocale });

    await dispatch(
      sendBusinessEmailThunk({
        date: formattedDate,
        businessType: translatedBusinessType,
        amount,
        commentary,
        sender: {
          name: [adherentPrescriber.firstName, adherentPrescriber.lastName].join(' '),
          email: adherentPrescriber.email,
        },
        recipient: {
          name: [adherentRecipient.firstName, adherentRecipient.lastName].join(' '),
          email: adherentRecipient.email,
        },
      })
    );

    await dispatch(getBusinessAdherentsThunk());
  };
};
