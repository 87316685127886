/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isLoggedSelector, isAuthInitializedSelector, userSelector } from '../store/ducks/auth/authSelectors';
import CenteredCircularProgress from './ui/CenteredCircularProgress';

function PrivateRoute({ children, ...props }) {
  const isLogged = useSelector(isLoggedSelector);
  const isAuthInitialized = useSelector(isAuthInitializedSelector);
  const user = useSelector(userSelector);

  if ((!!isLogged && !user) || !isAuthInitialized) {
    return <CenteredCircularProgress />;
  }

  return (
    <Route
      {...props}
      render={() => {
        return isLogged ? children : <Redirect to="/connexion" />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PrivateRoute;
