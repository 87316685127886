import firebase, { auth, firestore } from '../../../firebase';
import { AUTH_ACTIONS } from './authReducer';
import { setAuthLoadingAction } from './authActions';
import snapshotToEntity from '../../../utils/snapshotToEntity';

export function logoutThunk() {
  return async (dispatch) => {
    dispatch(setAuthLoadingAction(true));

    try {
      await auth.signOut();
    } finally {
      dispatch({ type: AUTH_ACTIONS.clear });
    }
  };
}

export function fetchUserThunk() {
  return async (dispatch) => {
    const { uid } = auth.currentUser;

    try {
      const userSnapshot = await firestore.collection('adherents').doc(uid).get();

      if (!userSnapshot?.exists) {
        dispatch(logoutThunk());
        return;
      }

      const user = snapshotToEntity(userSnapshot);
      user.fullName = [user.lastName.toUpperCase(), user.firstName].join(' ');

      dispatch({
        type: AUTH_ACTIONS.setUser,
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch(logoutThunk());
    }
  };
}

export function loginThunk(email, password) {
  return async (dispatch) => {
    dispatch(setAuthLoadingAction(true));

    try {
      await auth.signInWithEmailAndPassword(email, password);
      dispatch({ type: AUTH_ACTIONS.logged });
      dispatch(fetchUserThunk());
    } catch (err) {
      dispatch(setAuthLoadingAction(false));
      throw err;
    }
  };
}

export function initializeAuthThunk(user) {
  return async (dispatch) => {
    if (user) {
      dispatch(setAuthLoadingAction(true));
      dispatch({ type: AUTH_ACTIONS.logged });
      dispatch(fetchUserThunk());
    } else {
      dispatch({ type: AUTH_ACTIONS.clear });
    }
  };
}

export const setPasswordThunk = (email, password, confirmedPassword, url) => {
  return async () => {
    await auth.signInWithEmailLink(email, url);

    const user = auth.currentUser;

    await user.updatePassword(password);
  };
};

export const changePasswordThunk = ({ previousPassword, password }) => {
  return async () => {
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      const credential = await firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, previousPassword);
      await auth.currentUser.reauthenticateWithCredential(credential);
    } catch (err) {
      throw new Error('Common.badPassword');
    }

    const user = auth.currentUser;

    await user.updatePassword(password);
  };
};

export const changeEmailThunk = (userId, data) => {
  return async (dispatch) => {
    const updateUser = firebase.functions().httpsCallable('httpsUpdateUser');
    await updateUser({ ...data, userId });
    dispatch(logoutThunk());
  };
};
