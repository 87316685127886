/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { Box, makeStyles, FormControlLabel, Checkbox, Grid, useTheme, IconButton, FormControl } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';

import Typography from '../components/ui/Typography';
import Button from '../components/ui/Button';
import useTranslate from '../hooks/useTranslate';
import { array, bool, mixed, object, string } from '../utils/yup';
import AppLayout from '../components/AppLayout';
import { visibleAdherentsSelector } from '../store/ducks/adherents/adherentsSelectors';
import { getAdherentsThunk } from '../store/ducks/adherents/adherentsThunk';
import { sendEmailThunk } from '../store/ducks/mailingThunks';
import { userSelector } from '../store/ducks/auth/authSelectors';
import useQuery from '../hooks/useQuery';
import ChainIcon from '../components/icons/ChainIcon';
import CloseIcon from '../components/icons/CloseIcon';
import Autocomplete from '../components/v2/Autocomplete';
import TextField from '../components/v2/TextField';
import FormError from '../components/ui/FormError';

const useStyle = makeStyles({
  inputFile: {
    '& input': {
      cursor: 'pointer',
    },
  },
});

// Value in byte
const FILE_SIZE = 20 * 1024 * 1024;

function SendEmail() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslate();
  const query = useQuery();
  const classes = useStyle();

  const inputFileRef = useRef();

  const user = useSelector(userSelector);
  const adherents = useSelector(visibleAdherentsSelector);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAdherentsThunk());
  }, [dispatch]);

  const form = useFormik({
    initialValues: {
      broadcast: false,
      recipients: [query.get('adherentId')].filter((v) => v),
      attachment: null,
      subject: '',
      content: '',
    },
    validationSchema: object().shape({
      broadcast: bool().required(),
      attachment: mixed()
        .nullable()
        .test('fileSize', { key: 'Errors.validation.file.tooLarge', values: { allowedSize: '20MO' } }, (value) => {
          if (!value) {
            return true;
          }

          return value.size <= FILE_SIZE;
        }),
      recipients: mixed().when('broadcast', {
        is: false,
        then: array().min(1).required(),
        otherwise: array(),
      }),
      subject: string().required(),
      content: string().required(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let recipients;
        if (values.broadcast) {
          recipients = adherents.map((adherent) => ({
            name: adherent.fullName,
            email: adherent.email,
          }));
        } else {
          recipients = values.recipients
            .map((receiverId) => {
              const recipient = adherents.find(({ id }) => receiverId === id);

              if (!recipient) {
                return null;
              }

              return {
                name: recipient.fullName,
                email: recipient.email,
              };
            })
            .filter((v) => v);
        }

        await dispatch(
          sendEmailThunk({
            ...values,
            // TODO Pass adherent when user is linked to an adherent
            sender: {
              name: user.fullName,
              email: user.email,
            },
            recipients,
          })
        );

        toast.success(t('SendEmail.success'));
        form.resetForm();
      } catch (err) {
        toast.error(t('Errors.api.default'));
      } finally {
        setLoading(false);
      }
    },
  });

  const recipients = form.values.recipients.map((recipient) => adherents.find(({ id }) => id === recipient)).filter((v) => v);
  const isBroadcast = form.values.broadcast;

  const handleChangeRecipients = (event, newValue) => {
    form.setFieldValue(
      'recipients',
      newValue.map(({ id }) => id)
    );
  };

  const handleChangeAttachment = (e) => {
    const [file] = e.target.files;
    if (file) {
      form.setFieldTouched('attachment', true);
      form.setFieldValue('attachment', file, true);
    }
  };

  const handleClearAttachment = (e) => {
    e.preventDefault();
    e.stopPropagation();

    form.setFieldValue('attachment', null);

    // Reset input state
    form.setFieldError('attachment', undefined);
    form.setFieldTouched('attachment', false);
  };

  return (
    <AppLayout>
      <Box mt="60px">
        <form onSubmit={form.handleSubmit}>
          <Typography variant="h1" align="center" color="primary.main">
            <FormattedMessage id="SendEmail.title" />
          </Typography>
          <Box mt="40px" mb="20px">
            <Typography variant="h2">
              <FormattedMessage id="SendEmail.subtitle" />
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error={form.touched.recipients} fullWidth>
                <Autocomplete
                  multiple
                  options={adherents}
                  value={isBroadcast ? [] : recipients}
                  onChange={handleChangeRecipients}
                  getOptionLabel={(option) => option.fullName}
                  disabled={form.values.broadcast}
                  error={form.touched.recipients && form.errors.recipients}
                  renderInput={(params) => <TextField {...params} variant="outlined" label={t('SendEmail.recipients')} />}
                />
                <FormError error={form.touched.recipients && form.errors.recipients} />
              </FormControl>
              <FormControlLabel control={<Checkbox checked={form.values.broadcast} onChange={form.handleChange} name="broadcast" />} label={t('SendEmail.broadcast')} />
            </Grid>
            <Grid item md={6}>
              <TextField
                label={t('SendEmail.subject')}
                value={form.values.subject}
                onChange={form.handleChange}
                id="subject"
                name="subject"
                error={form.touched.subject && form.errors.subject}
                onBlur={form.handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <Box display="none">
                <input ref={inputFileRef} type="file" onChange={handleChangeAttachment} />
              </Box>
              <TextField
                label={t('SendEmail.attachment')}
                placeholder={t('SendEmail.selectAttachment')}
                onClick={() => inputFileRef.current.click()}
                value={form.values.attachment?.name || ''}
                onBlur={form.handleBlur}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={form.touched.attachment && form.errors.attachment}
                className={classes.inputFile}
                InputProps={{
                  endAdornment: form.values.attachment ? (
                    <IconButton onClick={handleClearAttachment}>
                      <CloseIcon size={14} color={theme.palette.grey[600]} />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <ChainIcon size={16} color={theme.palette.grey[600]} />
                    </IconButton>
                  ),
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('SendEmail.content')}
                value={form.values.content}
                onChange={form.handleChange}
                id="content"
                name="content"
                multiline
                error={form.touched.content && form.errors.content}
                onBlur={form.handleBlur}
                fullWidth
                rows={8}
              />
            </Grid>
          </Grid>
          <Box maxWidth="280px" mx="auto">
            <Box mt="45px" mb="100px">
              <Button variant="contained" fullWidth size="large" type="submit" loading={isLoading}>
                <FormattedMessage id="SendEmail.send" />
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </AppLayout>
  );
}

export default SendEmail;
