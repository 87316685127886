import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import EVENT_COLOR from '../../constants/EventColor';

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  content: {
    padding: '12px',
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    marginBottom: '6px',
    cursor: 'pointer',
    background: ({ type }) => EVENT_COLOR[type],
  },
}));

function WaitingEvent({ event, onClick }) {
  const classes = useStyle({ type: event.type });

  return (
    <Box className={classes.root}>
      <Box className={classes.content} onClick={onClick}>
        {event.title}
      </Box>
      <FormattedMessage id="WaitingEvent.date" values={{ start: event.start }} />
    </Box>
  );
}

WaitingEvent.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    type: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WaitingEvent;
