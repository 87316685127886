/* eslint-disable no-console */

/**
 * Here we create an object that isn't in the redux store to save not serializable data
 *
 * Why do that ?
 * Because the redux store should only contains plain serializable objects, arrays, and primitives
 *
 * @see https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
 *
 * @type {object}
 */
const dialogRefStore = { Component: null, props: {} };

export function setDialogComponent(Component, props) {
  if ('dev' === process.env.REACT_APP_ENV) {
    console.group('[DialogRefStore] Update dialog component');
    console.log('%c Previous', 'color: blue;', dialogRefStore);
    console.log('%c Next', 'color: green;', { Component, props });
    console.groupEnd();
  }

  dialogRefStore.Component = Component;
  dialogRefStore.props = props;
}

export function clearDialogComponent() {
  if ('dev' === process.env.REACT_APP_ENV) {
    console.group('[DialogRefStore] Clear dialog component');
    console.log('%c Previous', 'color: blue;font-weight:bold;', dialogRefStore.Component);
    console.log('%c Next', 'color: green;font-weight:bold;', null);
    console.groupEnd();
  }

  dialogRefStore.Component = null;
}

export default dialogRefStore;
